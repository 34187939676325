import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
// import Card from "../../components/Card";
import { RouteEndpoints } from "../../services/route.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  AppState,
  CheckContentProviderIdExist,
  getContentProviderId,
  getLanguageByDefault,
  InfoLogger,
  ResourceTypes,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { RouteComponentProps, useParams } from "react-router";
import Loader from "../../components/Loader";
import NoData from "./NoData";
import {
  ClassContentDiscussionResponse,
  ClassResponse,
  ContentResponse,
  CourseResponse,
  DiscussionCommentResponse,
  ResourceResponse,
  SubscriptionResponse,
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import { baseURL } from "../../services/api.service";
import { Link } from "react-router-dom";
import DiscussionOffCanvas from "../../components/DiscussionOffCanvas";
import TestsCardList from "../../components/TestsCardList";
import TestOffCanvas from "../../components/TestOffCanvas";
import {
  CalculateUserMediaPoints,
  CalculateUserReadPoints,
} from "../../utils/extraFunctions";
import Languages from "../../components/Languages";
import AudioPlayer from "../../components/AudioPlayer";
import { put } from "redux-saga/effects";
import Actions from "../../redux/actions";

function CourseContent({ location }: RouteComponentProps) {
  // const id = location.pathname.replace(RouteEndpoints.COURSE_CONTENT(""), "");
  // const tab = location.hash.replace('#','')
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const dataPersist = state[StateKeys.DATA_PERSIST];
  const details: BaseState<ContentResponse> = state[StateKeys.COURSE_CONTENT];
  const courses: BaseState<Array<CourseResponse>, ClassResponse> = state[
    StateKeys.CLASS_COURSES
  ];
  const discussions: BaseState<Array<ClassContentDiscussionResponse>> =
    state[StateKeys.CLASS_CONTENT_DISCUSSION];

  const comments: BaseState<Array<DiscussionCommentResponse>> =
    state[StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS];
  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];

  const video = state[StateKeys.VIDEO];
  const videoRef = useRef<HTMLVideoElement>(null);
  const audio = state[StateKeys.AUDIO];

  const [courseId, setCourseId] = useState(id);
  const [courseTab, setCourseTab] = useState(
    StateKeys.COURSE_CONTENT.concat("-", id)
  );

  const [showTests, setShowTests] = useState<TestResponse[]>([]);
  const [showTestDetails, setShowTestDetails] = useState<TestResponse>();

  const [discussion, setDiscussion] =
    useState<ClassContentDiscussionResponse>();

  const [openDiscussCanvas, setOpenDiscussCanvas] = useState<string | null>(
    null
  );

  const [hasSubscribed, setHasSubscribed] = useState<boolean>(true);

  const [giveUserPoint, setGiveUserPoint] = useState<boolean>(false);
  const [getUserPointType, setGetUserPointType] = useState<string>();
  const [isUserMouseOnCard, setIsUserMouseOnCard] = useState<boolean>(false);
  const [isUserScrollingCard, setIsUserScrollingCard] =
    useState<boolean>(false);
  const getLanguage = getLanguageByDefault();

  const subscription =
    subscriptions.data.length > 0
      ? subscriptions.data.find((e) => String(e.subscriber_class.id) === id)
      : undefined;

  const loadCourseContent = (id: string) => {
    if (
      details.pageState !== AppState.LOADED ||
      (details.pageState === AppState.LOADED && String(details.data.id) !== id)
    ) {
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
      ) {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .pageState,
          })
        );
        dispatch(
          Main.setPageMessage({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .message,
          })
        );
      } else {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.COURSE_CONTENT,
            data: {},
            clear: true,
          })
        );
        dispatch(
          Main.getPageObjectData({
            stateKey: StateKeys.COURSE_CONTENT,
            apiEndpoint: ApiEndpoint.COURSE_CONTENT(Number(id)),
            storeKey: StateKeys.COURSE_CONTENT.concat("-", id),
          })
        );
      }
    }
  };

  useEffect(() => {
    loadCourseContent(id);
  }, [id]);

  const getTopics = () => {
    courses.data.forEach((e) => {
      if (e.total_topics > 0) {
        if (
          !(
            dataPersist.data &&
            dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(e.id))
            ]
          )
        ) {
          dispatch(
            Main.getPaginatedPageData({
              stateKey: StateKeys.CLASS_COURSE_TOPICS,
              apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(e.id)),
              previousPage: 0,
              obj: true,
              storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(e.id)),
              clear: true,
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    if (discussions.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
          apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(id)),
          clear: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      // console.log(details.data.content_class.id)
      let id = String(details.data.content_class.id);
      if (
        courses.pageState !== AppState.LOADED ||
        (courses.pageState === AppState.LOADED &&
          String((courses.obj as ClassResponse).id) !== id)
      ) {
        // console.log(id, courses);
        if (
          courses.data.length > 0 &&
          String((courses.obj as ClassResponse).id) !== id
        ) {
          id = String((courses.obj as ClassResponse).id);
        }
        if (
          dataPersist.data &&
          dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
        ) {
          // console.log(id,'dataPersist',dataPersist.data[StateKeys.CLASS_COURSES.concat('-',id)])
          dispatch(
            Main.postPageObjectDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .obj,
              clear: true,
            })
          );
          dispatch(
            Main.postPaginatedPageDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .data,
              clear: true,
            })
          );
          dispatch(
            Main.togglePageState({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .pageState,
            })
          );
          // getTopics()
        } else {
          // console.log(id, "load");
          dispatch(
            Main.postPaginatedPageObjectSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: {},
              clear: true,
            })
          );
          dispatch(
            Main.postPaginatedPageDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: [],
              clear: true,
            })
          );
          dispatch(
            Main.getPaginatedPageData({
              stateKey: StateKeys.CLASS_COURSES,
              apiEndpoint: ApiEndpoint.CLASS_COURSES(Number(id)),
              previousPage: 0,
              obj: true,
              storeKey: StateKeys.CLASS_COURSES.concat("-", id),
            })
          );
        }
      }
    }

    setShowTests(details.data.tests);

    setGiveUserPoint(false);
  }, [details]);

  useEffect(() => {
    if (
      courses.hasMore &&
      courses.pageState !== AppState.LOADING_MORE &&
      courses.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_COURSES,
          apiEndpoint: ApiEndpoint.CLASS_COURSES(courses.obj!.id),
          previousPage: courses.currentPage!,
          storeKey: StateKeys.CLASS_COURSES.concat(
            "-",
            String(courses.obj!.id)
          ),
        })
      );
    }
    if (!courses.hasMore && courses.pageState === AppState.LOADED) {
      setHasSubscribed(!!courses?.obj?.is_subscribed);
      getTopics();
    }
  }, [courses]);

  useEffect(() => {
    CalculateUserReadPoints({
      setGiveUserPoint,
      setIsUserMouseOnCard,
      setIsUserScrollingCard,
      isUserMouseOnCard,
      isUserScrollingCard,
      giveUserPoint,
    });
  });

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      const req = video.data?.content_id
        ? {
            where: "watch",
            content_id: video.data.content_id,
            resource_id: video.data.id,
            points: 1,
          }
        : { where: "read", content_id: details.data.id, points: 1 };

      dispatch(
        Main.postPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.POINTS,
          req,
        })
      );
    }
  }, [isUserMouseOnCard, giveUserPoint, isUserScrollingCard, getUserPointType]);

  const handleVideoPlaying = () => {
    dispatch(
      Main.togglePageState({
        stateKey: StateKeys.AUDIO,
        data: AppState.PAUSING,
      })
    );
    !!video.data.id && videoRef.current && videoRef.current.play();
    handleVideoState(AppState.PLAY);
  };

  const handleVideoPlay = (value: ResourceResponse) => {
    if (!video.data || (video.data as ResourceResponse).id !== value.id) {
      dispatch(
        Main.postPageObjectDataSuccess({
          stateKey: StateKeys.VIDEO,
          data: value,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.VIDEO,
          data: AppState.LOADING,
        })
      );
    } else {
      handleVideoState(AppState.PLAYING);
    }
  };

  const handleVideoPause = () => {
    // (videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.pause()
    !!video.data.id && videoRef.current && videoRef.current.pause();
    handleVideoState(AppState.PAUSED);
  };

  const handleVideoState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.VIDEO, data: value }));

  useEffect(() => {
    if (video.pageState === AppState.PLAYING) {
      handleVideoPlaying();
    }

    if (
      video.pageState === AppState.PAUSING ||
      audio.pageState === AppState.PLAYING
    ) {
      handleVideoPause();
    }
  }, [audio, video]);

  const handleAudioPlay = (value: ResourceResponse) => {
    if (!audio.data || (audio.data as ResourceResponse).id !== value.id) {
      dispatch(
        Main.postPageObjectDataSuccess({
          stateKey: StateKeys.AUDIO,
          data: value,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.AUDIO,
          data: AppState.LOADING,
        })
      );
    } else {
      handleAudioState(AppState.PLAYING);
    }
  };

  const handleAudioState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.AUDIO, data: value }));

  const handleDefaultActiveKey = () =>
    details.data.has_read
      ? "read"
      : details.data.has_practice
      ? "practice"
      : details.data.content_resources.filter(
          (item) => item.type === ResourceTypes.VIDEO
        ).length
      ? "watch"
      : details.data.content_resources.filter(
          (item) => item.type === ResourceTypes.AUDIO
        ).length
      ? "listen"
      : details.data.has_test
      ? "test"
      : "discuss";

  const handleSetCourse = (id: string) => {
    handleVideoPause();

    dispatch(
      Main.getPageArrayData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(id)),
        clear: true,
      })
    );
    dispatch(
      Main.postPageObjectDataSuccess({
        stateKey: StateKeys.VIDEO,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.togglePageState({ stateKey: StateKeys.VIDEO, data: AppState.IDLE })
    );
    setCourseId(id);
    setCourseTab(StateKeys.COURSE_CONTENT.concat("-", id));
    loadCourseContent(id);
  };

  const getDiscussionComments = (discuss: ClassContentDiscussionResponse) => {
    dispatch(
      Main.getPaginatedPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT(discuss.id),
        previousPage: 0,
        clear: true,
      })
    );
    setDiscussion(discuss);
    setOpenDiscussCanvas("comments");
  };

  /*useEffect(() => {
    subscriptions.data.length > 0 &&
      setHasSubscribed(!!subscriptions.data.find(e=> e.subscriber_class === details.obj?.id));
  }, [subscriptions]);*/

  const subscribe = () => {
    const subscribed = subscription
      ? subscription.is_subscribed
      : details.data.content_class?.is_subscribed ?? courses.obj?.is_subscribed;

    subscribed
      ? dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_CLASS(Number(id)),
            req: "",
            removeAt: subscription
              ? subscriptions.data.indexOf(subscription)
              : undefined,
          })
        )
      : dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_CLASS(Number(id)),
            req: "",
            append: "start",
          })
        );
  };

  const getCourseTopic: (data: BaseState) => CourseResponse[] = (
    data: BaseState
  ) => (data ? (data.data as Array<CourseResponse>) : []);

  const getTestResults = (test: TestResponse) => {
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: [],
        clear: true,
      })
    );
    if (
      dataPersist.data &&
      dataPersist.data[
        StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
      ]
    ) {
      dispatch(
        Main.postPageArrayDataSuccess({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].data,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].pageState,
        })
      );
    } else {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(test.id)),
        })
      );
    }
  };

  return (
    <div className="content-page scroller-hide">
      <Row>
        {(details.pageState === AppState.LOADED ||
          details.pageState === AppState.LOADING_MORE ||
          courses.pageState === AppState.LOADED ||
          courses.pageState === AppState.LOADING_MORE) && (
          <>
            <Col sm={12} className="mb-3">
              <h3>
                {details.pageState === AppState.LOADED ||
                details.pageState === AppState.LOADING_MORE
                  ? details.data.title
                  : courses.data.find((e) => e.id === Number(courseId))
                      ?.title ?? "..."}
              </h3>
              <Link
                to={RouteEndpoints.CLASS_COURSES(
                  String(
                    details.pageState === AppState.LOADED ||
                      details.pageState === AppState.LOADING_MORE
                      ? details.data.content_class.id
                      : courses.obj!.id
                  )
                )}
              >
                {details.pageState === AppState.LOADED ||
                details.pageState === AppState.LOADING_MORE
                  ? details.data.content_class.name
                  : courses.obj!.name}
              </Link>
            </Col>
            {details.pageState === AppState.LOADING ? (
              <Loader noCard imageLoader />
            ) : courses?.obj?.is_subscribed !== undefined ||
              details.data.content_class.is_subscribed ? (
              courses?.obj?.is_subscribed ||
              details.data.content_class.is_subscribed ? (
                courses?.obj?.has_paid ||
                details.data.content_class.has_paid ? (
                  <>
                    {CheckContentProviderIdExist() &&
                    Number(getContentProviderId()) !==
                      details.data.content_provider_id ? (
                      <div className="text-center pt-5 mt-5">
                        <h5 className="pb-3">{getLanguage.sorryYouCannot}</h5>
                        <Link
                          to={{
                            pathname: `https://web.ecampus.camp/content/${id}`,
                          }}
                          className="text-decoration-none btn btn-primary"
                          target="_blank"
                        >
                          {getLanguage.clickHereToAccessContent}
                        </Link>
                      </div>
                    ) : (
                      <Col sm={12}>
                        <Tab.Container defaultActiveKey={courseTab}>
                          <Row>
                            <Col md={3}>
                              <Card>
                                <Card.Header>
                                  <Languages langKey="courseOutline" />
                                </Card.Header>
                                <Card.Body className="p-0">
                                  <Row>
                                    <Col md={12}>
                                      {(courses.pageState === AppState.LOADED ||
                                        courses.pageState ===
                                          AppState.LOADING_MORE) && (
                                        <>
                                          {courses.data.length > 0 ? (
                                            <Nav
                                              variant="pills"
                                              className="content-page basic-info-items list-inline d-block p-0 m-0"
                                            >
                                              <Accordion
                                                defaultActiveKey={courseTab}
                                              >
                                                {courses.data.map(
                                                  (
                                                    {
                                                      id,
                                                      title,
                                                      total_topics,
                                                      has_read,
                                                      has_practice,
                                                    },
                                                    i
                                                  ) =>
                                                    total_topics > 0 ? (
                                                      <Accordion.Item
                                                        eventKey={StateKeys.COURSE_CONTENT.concat(
                                                          "-",
                                                          String(id)
                                                        )}
                                                        key={i}
                                                      >
                                                        <Accordion.Header className="fs-7">
                                                          {title}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          {(has_read ||
                                                            has_practice) && (
                                                            <Nav.Item
                                                              key={i}
                                                              onClick={() =>
                                                                handleSetCourse(
                                                                  String(id)
                                                                )
                                                              }
                                                            >
                                                              <Nav.Link
                                                                eventKey={StateKeys.COURSE_CONTENT.concat(
                                                                  "-",
                                                                  String(id)
                                                                )}
                                                              >
                                                                <small className="fs-8">
                                                                  {title}
                                                                </small>
                                                              </Nav.Link>
                                                            </Nav.Item>
                                                          )}
                                                          {getCourseTopic(
                                                            dataPersist.data[
                                                              StateKeys.CLASS_COURSE_TOPICS.concat(
                                                                "-",
                                                                String(id)
                                                              )
                                                            ]
                                                          )
                                                            .filter(
                                                              (e) =>
                                                                e.has_practice ||
                                                                e.has_read
                                                            )
                                                            .map(
                                                              (
                                                                {
                                                                  id,
                                                                  title,
                                                                  total_topics,
                                                                },
                                                                i
                                                              ) => (
                                                                <Nav.Item
                                                                  key={i}
                                                                  onClick={() =>
                                                                    handleSetCourse(
                                                                      String(id)
                                                                    )
                                                                  }
                                                                >
                                                                  <Nav.Link
                                                                    eventKey={StateKeys.COURSE_CONTENT.concat(
                                                                      "-",
                                                                      String(id)
                                                                    )}
                                                                  >
                                                                    <small className="fs-7">
                                                                      {title}
                                                                    </small>
                                                                  </Nav.Link>
                                                                </Nav.Item>
                                                              )
                                                            )}
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    ) : (
                                                      <Nav.Item
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        key={i}
                                                        onClick={() =>
                                                          handleSetCourse(
                                                            String(id)
                                                          )
                                                        }
                                                      >
                                                        <Nav.Link
                                                          eventKey={StateKeys.COURSE_CONTENT.concat(
                                                            "-",
                                                            String(id)
                                                          )}
                                                        >
                                                          <small>{title}</small>
                                                        </Nav.Link>
                                                      </Nav.Item>
                                                    )
                                                )}
                                              </Accordion>
                                            </Nav>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </Col>
                                    <Col md={12}>
                                      {courses.pageState ===
                                        AppState.LOADING && (
                                        <Loader noCard imageLoader />
                                      )}
                                    </Col>
                                    {courses.pageState ===
                                      AppState.ERROR_404 && (
                                      <NoData
                                        title="classNotFound"
                                        link={RouteEndpoints.HOME}
                                        btnText="backHome"
                                      />
                                    )}
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={9} className="ps-4">
                              {(details.pageState === AppState.LOADED ||
                                details.pageState ===
                                  AppState.LOADING_MORE) && (
                                <Tab.Content>
                                  <Tab.Pane eventKey={courseTab}>
                                    <Tab.Container
                                      defaultActiveKey={handleDefaultActiveKey()}
                                    >
                                      <Card className="p-0">
                                        <Card.Body className="p-0">
                                          <div className="user-tabing">
                                            <Nav
                                              as="ul"
                                              variant="pills"
                                              className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                                            >
                                              {details.data.has_read && (
                                                <Nav.Item
                                                  as="li"
                                                  className=" col-12 col-sm-2 p-0 "
                                                >
                                                  <Nav.Link
                                                    eventKey="read"
                                                    role="button"
                                                    className="text-center p-3"
                                                    onClick={handleVideoPause}
                                                  >
                                                    <Languages langKey="read" />
                                                  </Nav.Link>
                                                </Nav.Item>
                                              )}
                                              {!!details.data.content_resources.filter(
                                                (item) =>
                                                  item.type ===
                                                  ResourceTypes.AUDIO
                                              ).length && (
                                                <Nav.Item
                                                  as="li"
                                                  className="col-12 col-sm-2 p-0"
                                                >
                                                  <Nav.Link
                                                    eventKey="listen"
                                                    role="button"
                                                    className="text-center p-3"
                                                    onClick={handleVideoPause}
                                                  >
                                                    <Languages langKey="listen" />
                                                  </Nav.Link>
                                                </Nav.Item>
                                              )}
                                              {!!details.data.content_resources.filter(
                                                (item) =>
                                                  item.type ===
                                                  ResourceTypes.VIDEO
                                              ).length && (
                                                <Nav.Item
                                                  as="li"
                                                  className=" col-12 col-sm-2 p-0 "
                                                >
                                                  <Nav.Link
                                                    eventKey="watch"
                                                    role="button"
                                                    className="text-center p-3"
                                                    onClick={() =>
                                                      handleVideoPlay(
                                                        video.data
                                                      )
                                                    }
                                                  >
                                                    <Languages langKey="watch" />
                                                  </Nav.Link>
                                                </Nav.Item>
                                              )}
                                              {details.data.has_practice && (
                                                <Nav.Item
                                                  as="li"
                                                  className="col-12 col-sm-2 p-0"
                                                >
                                                  <Nav.Link
                                                    eventKey="practice"
                                                    role="button"
                                                    className="text-center p-3"
                                                    onClick={handleVideoPause}
                                                  >
                                                    <Languages langKey="practice" />
                                                  </Nav.Link>
                                                </Nav.Item>
                                              )}
                                              <Nav.Item
                                                as="li"
                                                className="col-12 col-sm-2 p-0"
                                              >
                                                <Nav.Link
                                                  eventKey="discuss"
                                                  role="button"
                                                  className="text-center p-3"
                                                  onClick={handleVideoPause}
                                                >
                                                  <Languages langKey="discuss" />
                                                </Nav.Link>
                                              </Nav.Item>
                                              {details.data.has_test && (
                                                <Nav.Item
                                                  as="li"
                                                  className="col-12 col-sm-2 p-0"
                                                >
                                                  <Nav.Link
                                                    eventKey="test"
                                                    role="button"
                                                    className="text-center p-3"
                                                    onClick={handleVideoPause}
                                                  >
                                                    Test
                                                  </Nav.Link>
                                                </Nav.Item>
                                              )}
                                            </Nav>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                      <Col sm={12}>
                                        <Tab.Content>
                                          {details.data.has_read && (
                                            <Tab.Pane eventKey="read">
                                              <Card
                                                className="py-1 px-0 content-page"
                                                id="read"
                                              >
                                                <Card.Body className="p-4">
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        details.data
                                                          .text_content,
                                                    }}
                                                  />
                                                </Card.Body>
                                              </Card>
                                            </Tab.Pane>
                                          )}
                                          {details.data.has_practice && (
                                            <Tab.Pane
                                              eventKey="practice"
                                              className="content-page"
                                            >
                                              <Card className="h-100 w-100">
                                                <Card.Body className="p-3">
                                                  <iframe
                                                    src={details.data.practice_url.replace(
                                                      "/api/v4",
                                                      String(baseURL)
                                                    )}
                                                    frameBorder="0"
                                                    title={details.data.title}
                                                    className="w-100 vh-60"
                                                  />
                                                </Card.Body>
                                              </Card>
                                            </Tab.Pane>
                                          )}
                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.VIDEO
                                          ).length && (
                                            <Tab.Pane
                                              eventKey="watch"
                                              className="content-page"
                                            >
                                              {!!video.data.id && (
                                                <Card className="p-0">
                                                  <Card.Body className="p-3">
                                                    <video
                                                      id="video"
                                                      ref={videoRef}
                                                      className="w-100"
                                                      autoPlay
                                                      // loop
                                                      controls
                                                      controlsList="nodownload"
                                                      key={
                                                        (
                                                          video.data as ResourceResponse
                                                        ).id
                                                      }
                                                      onPause={() =>
                                                        handleVideoState(
                                                          AppState.PAUSING
                                                        )
                                                      }
                                                      onPlay={() => {
                                                        handleVideoState(
                                                          AppState.PLAYING
                                                        );
                                                        CalculateUserMediaPoints(
                                                          setGiveUserPoint,
                                                          "#video",
                                                          setGetUserPointType
                                                        );
                                                      }}
                                                      // onEnded={() => props.addPoint({ where: 'watch', contentId: course.content_id })}
                                                      onEnded={() =>
                                                        setGiveUserPoint(true)
                                                      }
                                                    >
                                                      <source
                                                        src={
                                                          (
                                                            video.data as ResourceResponse
                                                          ).url
                                                        }
                                                        type="video/mp4"
                                                        title={
                                                          (
                                                            video.data as ResourceResponse
                                                          ).description
                                                        }
                                                      />
                                                    </video>
                                                  </Card.Body>
                                                </Card>
                                              )}
                                              <Row>
                                                {details.data.content_resources
                                                  .filter(
                                                    (item) =>
                                                      item.type ===
                                                      ResourceTypes.VIDEO
                                                  )
                                                  .map((item, i) => (
                                                    <Col
                                                      key={i}
                                                      sm={6}
                                                      className="mt-3"
                                                    >
                                                      <Card>
                                                        <Card.Body>
                                                          <h6>
                                                            {item.description}
                                                          </h6>
                                                          <div className="mt-2 d-flex justify-content-between">
                                                            <span className="text-primary d-block line-height mt-0">
                                                              {(!video.data
                                                                .id ||
                                                                (
                                                                  video.data as ResourceResponse
                                                                ).id !==
                                                                  item.id ||
                                                                video.pageState ===
                                                                  AppState.PAUSED) && (
                                                                <i
                                                                  className={`las la-play fs-4 ${
                                                                    video.pageState ===
                                                                      AppState.LOADING &&
                                                                    "text-secondary"
                                                                  }`}
                                                                  onClick={() =>
                                                                    handleVideoPlay(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              )}
                                                              {!!video.data
                                                                .id &&
                                                                (
                                                                  video.data as ResourceResponse
                                                                ).id ===
                                                                  item.id &&
                                                                video.pageState ===
                                                                  AppState.PLAY && (
                                                                  <i
                                                                    className="las la-pause fs-4"
                                                                    onClick={() =>
                                                                      handleVideoPause()
                                                                    }
                                                                  />
                                                                )}
                                                            </span>
                                                            {!!video &&
                                                              (
                                                                video.data as ResourceResponse
                                                              ).id ===
                                                                item.id && (
                                                                <>
                                                                  {video.pageState ===
                                                                    AppState.LOADING && (
                                                                    <small className="text-secondary ms-auto">
                                                                      <Languages langKey="loading" />
                                                                    </small>
                                                                  )}
                                                                  {video.pageState ===
                                                                    AppState.PAUSED && (
                                                                    <small className="text-warning ms-auto">
                                                                      <Languages langKey="paused" />
                                                                    </small>
                                                                  )}
                                                                  {video.pageState ===
                                                                    AppState.PLAY && (
                                                                    <small className="text-success ms-auto">
                                                                      <Languages langKey="playing" />
                                                                    </small>
                                                                  )}
                                                                </>
                                                              )}

                                                            {/*{(!!video && !(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.paused) && <small className="text-success ms-auto">{(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.played}</small>}*/}
                                                          </div>
                                                        </Card.Body>
                                                      </Card>
                                                    </Col>
                                                  ))}
                                              </Row>
                                            </Tab.Pane>
                                          )}
                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.AUDIO
                                          ).length && (
                                            <Tab.Pane
                                              eventKey="listen"
                                              className="content-page"
                                            >
                                              <Row>
                                                {details.data.content_resources
                                                  .filter(
                                                    (item) =>
                                                      item.type ===
                                                      ResourceTypes.AUDIO
                                                  )
                                                  .map((item, i) => (
                                                    <Col
                                                      key={i}
                                                      sm={6}
                                                      className="mt-3"
                                                    >
                                                      <Card>
                                                        <Card.Body>
                                                          <h6>
                                                            {item.description}
                                                          </h6>
                                                          <div className="mt-2 d-flex justify-content-between">
                                                            <span className="text-primary d-block line-height mt-0">
                                                              {(!audio.data ||
                                                                (
                                                                  audio.data as ResourceResponse
                                                                ).id !==
                                                                  item.id ||
                                                                audio.pageState ===
                                                                  AppState.PAUSED) && (
                                                                <i
                                                                  className={`las la-play fs-4 ${
                                                                    video.pageState ===
                                                                      AppState.LOADING &&
                                                                    "text-secondary"
                                                                  }`}
                                                                  onClick={() =>
                                                                    handleAudioPlay(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              )}
                                                              {!!audio &&
                                                                (
                                                                  audio.data as ResourceResponse
                                                                ).id ===
                                                                  item.id &&
                                                                audio.pageState ===
                                                                  AppState.PLAY && (
                                                                  <i
                                                                    className="las la-pause fs-4"
                                                                    onClick={() =>
                                                                      handleAudioState(
                                                                        AppState.PAUSING
                                                                      )
                                                                    }
                                                                  />
                                                                )}
                                                            </span>
                                                            {!!audio &&
                                                              (
                                                                audio.data as ResourceResponse
                                                              ).id ===
                                                                item.id && (
                                                                <>
                                                                  {audio.pageState ===
                                                                    AppState.LOADING && (
                                                                    <small className="text-secondary ms-auto">
                                                                      <Languages langKey="loading" />
                                                                    </small>
                                                                  )}
                                                                  {audio.pageState ===
                                                                    AppState.PAUSED && (
                                                                    <small className="text-warning ms-auto">
                                                                      <Languages langKey="paused" />
                                                                    </small>
                                                                  )}
                                                                  {audio.pageState ===
                                                                    AppState.PLAY && (
                                                                    <small className="text-success ms-auto">
                                                                      <Languages langKey="playing" />
                                                                    </small>
                                                                  )}
                                                                </>
                                                              )}

                                                            {/*{(!!video && !(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.paused) && <small className="text-success ms-auto">{(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.played}</small>}*/}
                                                          </div>
                                                        </Card.Body>
                                                      </Card>
                                                    </Col>
                                                  ))}
                                              </Row>
                                              <AudioPlayer />
                                            </Tab.Pane>
                                          )}
                                          {details.data.has_test && (
                                            <Tab.Pane
                                              eventKey="test"
                                              className="content-page"
                                            >
                                              <Row>
                                                <>
                                                  {details.data.tests.length >
                                                  0 ? (
                                                    showTests?.length > 0 ? (
                                                      <TestsCardList
                                                        tests={
                                                          details.data.tests
                                                        }
                                                        setShowTestDetails={
                                                          setShowTestDetails
                                                        }
                                                        getTestResults={
                                                          getTestResults
                                                        }
                                                      />
                                                    ) : (
                                                      <NoData title="noTest" />
                                                    )
                                                  ) : (
                                                    <NoData title="noTest" />
                                                  )}
                                                </>
                                              </Row>
                                            </Tab.Pane>
                                          )}

                                          <Tab.Pane
                                            eventKey="discuss"
                                            className="content-page"
                                          >
                                            <Card
                                              className="discussCard p-3 overflow-scroll position-relative"
                                              id="discuss"
                                            >
                                              {discussions.data.length > 0 ? (
                                                <>
                                                  {" "}
                                                  {discussions.data.map(
                                                    (
                                                      discuss: ClassContentDiscussionResponse
                                                    ) => (
                                                      <Card
                                                        key={discuss.id}
                                                        className="p-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          getDiscussionComments(
                                                            discuss
                                                          )
                                                        }
                                                      >
                                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                                          <img
                                                            className="discuss-profile"
                                                            style={{
                                                              width: "40px",
                                                              height: "40px",
                                                              display: "flex",
                                                              overflow:
                                                                "hidden",
                                                              borderRadius:
                                                                "50%",
                                                            }}
                                                            src={
                                                              discuss.user
                                                                .image_url
                                                            }
                                                            alt={
                                                              discuss.user.name
                                                            }
                                                          />
                                                          <div className="d-flex flex-column gap-1">
                                                            <h6>
                                                              {
                                                                discuss.user
                                                                  .name
                                                              }
                                                            </h6>
                                                            <p className="p-0 m-0">
                                                              {new Date(
                                                                discuss.created_at
                                                              ).toLocaleDateString(
                                                                getLanguage.dateTimeLanguage,
                                                                {
                                                                  weekday:
                                                                    "long",
                                                                  year: "numeric",
                                                                  month: "long",
                                                                  day: "numeric",
                                                                }
                                                              )}
                                                              ,
                                                              {new Date(
                                                                discuss.created_at
                                                              ).toLocaleTimeString(
                                                                getLanguage.dateTimeLanguage
                                                              )}
                                                            </p>
                                                            <p className="p-0 m-0">
                                                              {discuss?.post}
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="d-flex justify-content-end align-items-center gap-1">
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              getDiscussionComments(
                                                                discuss
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              style={{
                                                                fontSize:
                                                                  "25px",
                                                              }}
                                                              className="lar la-comments text-dark"
                                                            />
                                                          </span>
                                                          <span
                                                            className="badge rounded-pill px-2"
                                                            style={{
                                                              color: "#fff",
                                                              backgroundColor:
                                                                "#ac2a0a",
                                                            }}
                                                          >
                                                            {
                                                              discuss.total_comments
                                                            }
                                                          </span>
                                                        </div>
                                                      </Card>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <NoData title="DiscussionNotFound" />
                                              )}

                                              <div
                                                className="position-sticky bottom-0 text-end me-5"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  setOpenDiscussCanvas(
                                                    "discussion"
                                                  )
                                                }
                                              >
                                                <svg
                                                  className="badge bg-primary rounded-pill p-2"
                                                  focusable="false"
                                                  viewBox="0 0 24 24"
                                                  aria-hidden="true"
                                                  width={50}
                                                  height={50}
                                                  fill="#fff"
                                                >
                                                  <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z"></path>
                                                  <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z"></path>
                                                </svg>
                                              </div>
                                            </Card>
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </Col>
                                    </Tab.Container>
                                  </Tab.Pane>
                                </Tab.Content>
                              )}
                              {/*{details.pageState === AppState.LOADING && (
                                <Loader noCard imageLoader />
                              )}*/}
                              {details.pageState === AppState.ERROR_404 && (
                                <NoData
                                  title="classNotFound"
                                  link={RouteEndpoints.HOME}
                                  btnText="backHome"
                                />
                              )}
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="text-center pt-5 mt-5">
                    <h5 className="pb-3">{getLanguage.payToAccessContent}</h5>
                    <a
                      href={
                        details.data.content_class.payment_url ??
                        courses?.obj?.payment_url
                      }
                      target="_blank"
                      className="text-decoration-none btn btn-primary"
                      rel="noreferrer"
                    >
                      {getLanguage.payNow}
                    </a>
                  </div>
                )
              ) : (
                <div className="text-center pt-5 mt-5">
                  <h5 className="pb-3">
                    <Languages langKey="subscribeToView" />
                  </h5>
                  <Button
                    type="button"
                    className="btn btn-primary rounded px-5"
                    onClick={() =>
                      CheckContentProviderIdExist() ? null : subscribe()
                    }
                    disabled={
                      subscriptions.pageState === AppState.LOADING ||
                      CheckContentProviderIdExist()
                    }
                  >
                    {subscriptions.pageState === AppState.LOADING && (
                      <Spinner
                        animation={"border"}
                        size={"sm"}
                        className="me-1"
                      />
                    )}

                    {CheckContentProviderIdExist() ? (
                      <Languages langKey="contentAdminCourse" />
                    ) : (
                      <Languages langKey="subscribe" />
                    )}
                  </Button>
                </div>
              )
            ) : (
              <Loader noCard imageLoader />
            )}
          </>
        )}
        <Col md={12}>
          {details.pageState === AppState.LOADING &&
            courses.pageState !== AppState.LOADED && (
              <Loader noCard imageLoader />
            )}
        </Col>
      </Row>

      <DiscussionOffCanvas
        setOpenDiscussCanvas={setOpenDiscussCanvas}
        openDiscussCanvas={openDiscussCanvas}
        comments={comments}
        discussion={discussion!}
        discussions={discussions}
        contentID={details?.data?.id}
      />

      <TestOffCanvas
        results={testResults}
        hasSubscribed={true}
        showTestDetails={showTestDetails!}
        setShowTestDetails={setShowTestDetails}
      />
    </div>
  );
}

export default CourseContent;
