import React, { useEffect, useState } from "react";
import { Button, Col, Offcanvas, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NoData from "../pages/main/NoData";
import Main from "../redux/actions/main.action";
import { BaseState, StateKeys } from "../redux/states/main.state";
import ApiEndpoint from "../services/api.endpoint.service";
import { InterestResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Languages from "./Languages";
import Loader from "./Loader";

interface props {
  interests: BaseState<Array<InterestResponse>>;
  userInterest: BaseState<Array<InterestResponse>>;
  show: Boolean;
  setInterestCanvas: (show: boolean) => void;
}

const InterestOffCanvas = ({
  interests,
  userInterest,
  show,
  setInterestCanvas,
}: props) => {
  const dispatch = useDispatch();

  const [currentInterest, setCurrentInterest] = useState<InterestResponse[]>(
    []
  );
  const [updatedInterest, setUpdatedInterest] = useState<number[]>([]);
  const [allInterest, setAllInterest] = useState<InterestResponse[]>([]);

  useEffect(() => {
    // set user and all interest into state
    setCurrentInterest(userInterest.data);

    //  variable to accept interests ids
    let currentInterest: number[] = [];
    let allInterests: InterestResponse[] = [];

    if (userInterest?.data) {
      userInterest?.data.map((interest: InterestResponse) =>
        //push id into current interest
        currentInterest.push(interest.id)
      );
    }

    //Remove interests that user has already selected
    interests.data.map((interest) =>
      !currentInterest.includes(interest.id)
        ? allInterests.push(interest)
        : null
    );

    setAllInterest(allInterests);
    setUpdatedInterest(currentInterest);
  }, [userInterest.data, interests.data]);

  const submitUpdateInterest = () => {
    dispatch(
      Main.postPaginatedPageData({
        stateKey: StateKeys.USER_INTERESTS,
        apiEndpoint: ApiEndpoint.USER_INTERESTS,
        previousPage: 0,
        req: { interests: updatedInterest },
        clear: true,
      })
    );
  };

  const removeInterest = (interest: InterestResponse) => {
    if (updatedInterest.includes(interest.id)) {
      setCurrentInterest(currentInterest.filter((item) => item !== interest));
      setUpdatedInterest(
        updatedInterest.filter((item) => item !== interest.id)
      );
      setAllInterest([...allInterest, interest]);
    }
  };

  const addInterest = (interest: InterestResponse) => {
    if (!updatedInterest.includes(interest.id)) {
      setCurrentInterest([...currentInterest, interest]);
      setAllInterest(allInterest.filter((item) => item !== interest));
      setUpdatedInterest([...updatedInterest, interest.id]);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => setInterestCanvas(false)}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Languages langKey="updateInterest" />{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {interests.pageState === AppState.LOADED ? (
              <>
                {currentInterest.length > 0 ? (
                  <ul>
                    {currentInterest.map(
                      (interest: InterestResponse, i: number) => (
                        <li
                          className="badge bg-primary rounded-3 me-1 mb-1 "
                          key={i}
                        >
                          <span className="d-flex gap-1">
                            {interest.name}
                            <i
                              onClick={() => removeInterest(interest)}
                              className="ri-indeterminate-circle-fill"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <NoData title="noInterest" />
                )}
              </>
            ) : (
              <Col md={12}>
                {userInterest.pageState === AppState.LOADING && (
                  <Loader noCard imageLoader />
                )}
              </Col>
            )}
          </div>

          <div>
            <p className="h5 pt-5 mt-5">
              <Languages langKey="allInterest" />{" "}
            </p>

            {interests.pageState === AppState.LOADED ? (
              <>
                {allInterest.length > 0 ? (
                  <ul>
                    {allInterest.map(
                      (interest: InterestResponse, i: number) => (
                        <li
                          className="badge bg-primary rounded-3 me-1 mb-1"
                          key={i}
                        >
                          <span className="d-flex gap-1">
                            {interest.name}
                            <i
                              onClick={() => addInterest(interest)}
                              className="ri-add-circle-fill"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <NoData
                    title={
                      interests.data.length > 0
                        ? "noInterest"
                        : "selectedAllInterest"
                    }
                  />
                )}{" "}
              </>
            ) : (
              <Col md={12}>
                {(interests.pageState === AppState.LOADING_MORE ||
                  interests.pageState === AppState.LOADING) && (
                  <Loader noCard imageLoader />
                )}
              </Col>
            )}
          </div>

          <div className="pt-5">
            <Button
              variant="outline-primary float-end"
              title="Submit"
              onClick={() => submitUpdateInterest()}
              disabled={userInterest.pageState === AppState.LOADING}
            >
              {userInterest.pageState === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              <Languages langKey="submit" />{" "}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default InterestOffCanvas;
