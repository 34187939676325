import React from "react";
import {Offcanvas, Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import { CourseResponse } from "../services/api.response.service";
import { RouteEndpoints } from "../services/route.service";
import Languages from "./Languages";
import {AppState, CheckContentProviderIdExist} from "../services/app.service";

interface props {
  topic: CourseResponse;
  hasSubscribed?: boolean;
  setShowTopicDetails: (topic: undefined | CourseResponse) => void;
  subscribeFunction?: () => void;
  subscriptionState?: number;
  hasPaid?: boolean;
  paymentUrl?: string;
}

const TopicsOffCanvas = ({ topic, setShowTopicDetails,hasSubscribed,subscribeFunction,subscriptionState,hasPaid,paymentUrl }: props) => {
  return (
    <>
      <Offcanvas
        show={topic}
        onHide={() => setShowTopicDetails(undefined)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{topic?.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mt-2 text-body">
            <p className="mb-0">{topic?.description}</p>
          </div>

          <div className="mt-2 d-flex justify-content-between mb-3"/>
          {hasSubscribed !== undefined ? (
            <>
              {hasSubscribed ?
                hasPaid || CheckContentProviderIdExist() ?
                  (
                    <Link
                      to={RouteEndpoints.COURSE_CONTENT(String(topic?.id))}
                      className="text-decoration-none btn btn-primary"
                    >
                      <Languages langKey="startLearning" />
                    </Link>
                  ):
                  (
                    <a
                      href={paymentUrl!}
                      target="_blank"
                      className="text-decoration-none btn btn-primary"
                      rel="noreferrer"
                    >
                      <Languages langKey="payToAccessContent" />{" "}
                    </a>
                  )
                : (
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() =>
                    CheckContentProviderIdExist()
                      ? null
                      : subscribeFunction!()
                  }
                  disabled={
                    subscriptionState === AppState.LOADING ||
                    CheckContentProviderIdExist()
                  }
                >
                  {subscriptionState === AppState.LOADING && (
                    <Spinner
                      animation={"border"}
                      size={"sm"}
                      className="me-1"
                    />
                  )}
                  {CheckContentProviderIdExist() ? (
                    <Languages langKey="contentAdminCourse" />
                  ) : (
                    <Languages langKey="subToStartLearn" />
                  )}
                </button>
              )}
            </>
          ) : (
            <>
              {" "}
              <Link
                to={RouteEndpoints.COURSE_CONTENT(String(topic?.id))}
                className="text-decoration-none btn btn-primary"
              >
                <Languages langKey="subToStartLearn" />
              </Link>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TopicsOffCanvas;
