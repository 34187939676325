import {AppState} from "../../services/app.service";

export interface SignInState {
    state: AppState
    message: string
}

export interface SignUpState{
    state: AppState
    message: string
}


export interface OAuthState {
    state: AppState
    message: string
}



export interface RegisterState{
    loader: boolean
    error: boolean
    errorInput: boolean
    message: string
    state: AppState
}

export interface ForgotPasswordState {
    state: AppState
    message: string
}

export interface TelecelSignInState {
    state: AppState
    message: string
    showCode: boolean
    newUser: boolean
}

const signIn: SignInState = {
    state: AppState.IDLE,
    message: '',
}

const signUp: SignUpState = {
    state: AppState.IDLE,
    message: '',
}


const oAuth: OAuthState = {
    state: AppState.IDLE,
    message: '',
}

const telecel: TelecelSignInState = {
    state: AppState.IDLE,
    message: '',
    showCode: false,
    newUser: false,
}

const register: RegisterState = {
    loader: false,
    error: false,
    errorInput: false,
    message: '',
    state: AppState.IDLE,
}

const forgotPassword: ForgotPasswordState = {
    state: AppState.IDLE,
    message: '',
}

export interface AuthState {
    signIn: SignInState
    signUp: SignUpState
    register: RegisterState
    forgotPassword: ForgotPasswordState
    oAuth: OAuthState
    telecel: TelecelSignInState
    
}

const auth: AuthState = {
    signIn,signUp,register,forgotPassword,oAuth,telecel
}

export default auth

