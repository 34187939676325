import React from "react";
import { Col } from "react-bootstrap";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import ClassCard1 from "./ClassCard1";
import NoData from "../pages/main/NoData";
import Languages from "./Languages";

interface Props {
  title?: string;
  data: Array<ClassResponse>;
  state: AppState;
}

function ClassesCardVList({ title, data, state }: Props) {
  return (
    <Col lg={12} className="row m-0 p-0">
      {title && (
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title text-capitalize">
              <Languages
                langKey={
                  title?.includes("-")
                    ? title
                        .split("-")[0]
                        .concat(
                          title.split("-")[1][0].toUpperCase() +
                            title.split("-")[1].slice(1)
                        )
                    : title
                }
              />
            </h4>
          </div>
        </Col>
      )}
      <Col sm={12} className="row m-0 p-0">
        {(state === AppState.LOADED || state === AppState.LOADING_MORE) && (
          <>
            {data.length > 0 ? (
              data.map((item, i) => (
                <Col key={i} sm={6} md={3} lg={3} xl={2} className="mt-1">
                  <ClassCard1 data={item} />
                </Col>
              ))
            ) : (
              <NoData title="noClassAvailable" />
            )}
          </>
        )}
      </Col>
      <Col sm={12} className="row m-0 p-0">
        {(state === AppState.LOADING || state === AppState.LOADING_MORE) && (
          <Loader noCard imageLoader />
        )}
      </Col>
    </Col>
  );
}

export default ClassesCardVList;
