import React from "react";
import Card from "./Card";
import { ClassResponse } from "../services/api.response.service";
import { RouteEndpoints } from "../services/route.service";
import { Link } from "react-router-dom";
import { CheckContentProviderIdExist } from "../services/app.service";

interface Props {
  data: ClassResponse;
}

function ClassCard1({ data }: Props) {
  return (
    <Card className="card-block card-stretch card-height product">
      <Card.Body className="p-1 d-flex justify-content-between flex-column">
        <Link
          to={RouteEndpoints.CLASS_COURSES(String(data.id))}
          className="d-flex justify-content-between flex-column text-decoration-none"
        >
          <div className="image-block position-relative">
            <img
              src={data.logo_url}
              className="img-fluid w-100 rounded"
              alt="product-img"
            />
            {/*<div className="offer">
                        <div className="offer-title">20%</div>
                    </div>*/}
            {!CheckContentProviderIdExist() && (
              <h6 className="price">
                {/*<span className="regular-price text-dark pr-2">$87.00</span>*/}
                {data.subscription_amount_month > 0
                  ? data.subscription_currency.concat(
                      " ",
                      String(data.subscription_amount_month)
                    )
                  : "Free"}
              </h6>
            )}
          </div>
          <div className="p-1 product-description mt-3 line-height-16">
            <h6 className="mb-1 fs-7">
              {data.name.length > 21
                ? data.name.slice(0, 21).concat("..")
                : data.name}
            </h6>
            {/*<span className="categry text-primary ps-3 mb-2 position-relative">Logo and badges</span>*/}
            {/*<br/>*/}
            <small className="mb-0 text-body fs-7">
              {data.description.length > 38
                ? data.description.slice(0, 38).concat("...")
                : data.description}
            </small>
          </div>
        </Link>
        {!CheckContentProviderIdExist() && (
          <div className="d-flex justify-content-between flex-column">
            <hr className="hr-horizontal" />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <small>
                  <i className="text-warning fa fa-star" />
                  <small className="ms-1">4.3</small>
                </small>
              </div>
              <small className="d-flex align-items-center gap-1">
                <i className="fa fa-language"></i>{" "}
                <span style={{ fontSize: 10 }}>{data.language}</span>
              </small>
              <small>
                {/*<svg xmlns="http://www.w3.org/2000/svg" width="12px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                        <small className="ms-1">674</small>*/}
                {data.category && (
                  <Link
                    to={RouteEndpoints.CATEGORY_CLASSES(
                      String(data.category.id)
                    )}
                    className="text-decoration-none"
                  >
                    {data.category.name}
                  </Link>
                )}
              </small>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default ClassCard1;
