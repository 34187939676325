import React, { useState, useEffect } from "react";
import { Col, Offcanvas, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoData from "../pages/main/NoData";
import { CourseResponse, SubscriptionResponse } from "../services/api.response.service";
import { AppState, CheckContentProviderIdExist, InfoLogger } from "../services/app.service";
import { RouteEndpoints } from "../services/route.service";
import CourseCardItem from "./CourseCardItem";
import Languages from "./Languages";
import Loader from "./Loader";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../redux/states";
import Main from "../redux/actions/main.action";
import ApiEndpoint from "../services/api.endpoint.service";
import { BaseState, StateKeys } from "../redux/states/main.state";

interface props {
  hasSubscribed?: boolean;
  subscribeFunction?: () => void;
  showCourseDetails: CourseResponse;
  setShowCourseDetails?: (course: undefined | CourseResponse) => void;
  hasPaid?: boolean;
  paymentUrl?: string;
}

const CoursesOffCanvas = ({
  hasSubscribed,
  hasPaid,
  paymentUrl,
  subscribeFunction,
  showCourseDetails,
  setShowCourseDetails,
}: props) => {

  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const dataPersist = state[StateKeys.DATA_PERSIST];
  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const topics = state[StateKeys.COURSE_TOPICS];

  const [showTopicDetails, setShowTopicDetails] = useState<CourseResponse>();

  const getCourseTopic = (course: CourseResponse) => {
    dispatch(
      Main.postPaginatedPageObjectSuccess({
        stateKey: StateKeys.COURSE_TOPICS,
        data: [],
        clear: true,
      })
    );
    if (course.total_topics > 0) {
      InfoLogger(dataPersist.data[
        StateKeys.COURSE_TOPICS.concat("-", String(course.id))
      ], dataPersist.data, StateKeys.COURSE_TOPICS.concat("-", String(course.id)))
      if (
        dataPersist.data &&
        dataPersist.data[
        StateKeys.COURSE_TOPICS.concat("-", String(course.id))
        ]
      ) {
        InfoLogger(dataPersist.data[
          StateKeys.COURSE_TOPICS.concat("-", String(course.id))
        ].data)
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.COURSE_TOPICS.concat("-", String(course.id))
            ].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.COURSE_TOPICS.concat("-", String(course.id))
            ].pageState,
          })
        );
      } else {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.COURSE_TOPICS,
            apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(course.id)),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.COURSE_TOPICS.concat(
              "-",
              String(course.id)
            ),
            clear: true,
          })
        );
      }
    }
  };

  useEffect(() => {
    getCourseTopic(showCourseDetails)
  }, [])

  const cacheData = () => dataPersist.data[
    StateKeys.COURSE_TOPICS.concat("-", String(showCourseDetails.id))
  ] ?? topics

  return (
    <>
      {" "}
      <section id="CoursesOffCanvas">
        <Offcanvas
          show={showCourseDetails}
          onHide={() => setShowCourseDetails!(undefined)}
          placement="end"
          style={{ width: showTopicDetails ? "45vw" : "40vw" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{showCourseDetails?.title}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mt-2 text-body">
              <p className="mb-0">{showCourseDetails?.description}</p>
            </div>
            <div className="mt-2 d-flex justify-content-between mb-3">
              <span className="text-primary d-block line-height mt-0">
                {showCourseDetails?.has_read && (
                  <i className="las la-book-reader fs-4" />
                )}
                {showCourseDetails?.has_practice && (
                  <i
                    className="las la-dumbbell fs-4"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              {showCourseDetails?.total_topics > 0 && (
                <small className="text-secondary ms-auto">
                  <Languages langKey="totalTopics" />{" "}
                  <span className="text-primary">
                    {showCourseDetails?.total_topics}
                  </span>
                </small>
              )}
            </div>
            {hasSubscribed !== undefined ? (
              <>
                {hasSubscribed ? (
                  hasPaid || CheckContentProviderIdExist() ? (
                    <>
                      {(showCourseDetails?.has_read ||
                        showCourseDetails?.has_practice) && (
                          <Link
                            to={RouteEndpoints.COURSE_CONTENT(
                              String(showCourseDetails?.id)
                            )}
                            className="text-decoration-none btn btn-primary"
                          >
                            <Languages langKey="startLearning" />{" "}
                          </Link>
                        )}
                    </>
                  ) : (
                    <a
                      href={paymentUrl!}
                      target="_blank"
                      className="text-decoration-none btn btn-primary"
                      rel="noreferrer"
                    >
                      <Languages langKey="payToAccessContent" />{" "}
                    </a>
                  )
                ) : (
                  <button
                    className="text-decoration-none btn btn-primary"
                    onClick={() =>
                      CheckContentProviderIdExist() || typeof (subscribeFunction) == undefined
                        ? null
                        : subscribeFunction!()
                    }
                    disabled={
                      subscriptions.pageState === AppState.LOADING ||
                      CheckContentProviderIdExist()
                    }
                  >
                    {subscriptions.pageState === AppState.LOADING && (
                      <Spinner
                        animation={"border"}
                        size={"sm"}
                        className="me-1"
                      />
                    )}
                    {CheckContentProviderIdExist() ? (
                      <Languages langKey="contentAdminCourse" />
                    ) : (
                      <Languages langKey="subToStartLearn" />
                    )}
                  </button>
                )}
              </>
            ) : (
              <>
                {" "}
                <Link
                  to={RouteEndpoints.COURSE_CONTENT(
                    String(showCourseDetails?.id)
                  )}
                  className="text-decoration-none btn btn-primary"
                >
                  <Languages langKey="startLearning" />{" "}
                </Link>
              </>
            )}
            {showCourseDetails.total_topics > 0 && (
              <Row>
                {(cacheData().pageState === AppState.LOADED ||
                  cacheData().pageState === AppState.LOADING_MORE) && (
                    <>
                      {cacheData().data ? (
                        <>
                          <Col md={12}>
                            <Row className="mt-3">
                              <h6>Topics</h6>
                              {(cacheData().data as Array<CourseResponse>).filter((e) => e.has_read || e.has_practice || e.total_topics > 0).length > 0 ? (
                                (cacheData().data as Array<CourseResponse>)
                                  .filter((e) => e.has_read || e.has_practice || e.total_topics > 0)
                                  .map((item, i) => (
                                    <Col
                                      key={i}
                                      sm={12}
                                      className="mt-3"

                                    >
                                      {item.total_topics > 0 ?
                                        <div onClick={() =>
                                          setShowTopicDetails(item)
                                        }>
                                          <CourseCardItem item={item} />
                                        </div>
                                        :

                                        <Link
                                          to={RouteEndpoints.COURSE_CONTENT(
                                            String(item?.id)
                                          )}
                                        >
                                          <CourseCardItem item={item} />{" "}
                                        </Link>}
                                    </Col>
                                  ))
                              ) : (
                                <NoData body="noTopicRP" />
                              )}
                              <Col md={12}>
                                {topics.pageState === AppState.LOADING_MORE && (
                                  <Loader noCard imageLoader />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <NoData title="noTopic" />
                      )}
                    </>
                  )}
                <Col md={12}>
                  {cacheData().pageState === AppState.LOADING && (
                    <Loader noCard imageLoader />
                  )}
                </Col>
              </Row>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </section>{" "}

      {showTopicDetails &&
      <CoursesOffCanvas
        hasSubscribed={hasSubscribed!}
        subscribeFunction={() => subscribeFunction!()}
        hasPaid={hasPaid}
        paymentUrl={paymentUrl}

        showCourseDetails={showTopicDetails as CourseResponse}
        setShowCourseDetails={setShowTopicDetails}
      />}
    </>
  );
};

export default CoursesOffCanvas;