import React from "react";
import Card from "./Card";
import {AnnouncementResponse} from "../services/api.response.service";
import {ellipsisText} from "../services/app.service";
import {Col, Row} from "react-bootstrap";

interface Props{
    data: AnnouncementResponse
}

function AnnouncementCard({data}:Props) {
    return <Card className="card-block card-stretch card-height blog-grid">
        <Card.Body className="p-0">
            <Row className="align-items-center">
                {/*{data.resource_url &&
                  <Col lg="6">
                      <div className="image-block mt-3">
                          <img src={data.resource_url} className="img-fluid rounded w-100" alt="blog-img"/>
                      </div>
                  </Col>}*/}
                <Col sm={12}>
                    <div className="blog-description shadow-none p-3 text-center">
                        <div className="date mb-1">
                            <span className="text-primary">{ellipsisText(data.title,40)}</span>
                        </div>
                        <h5 className="mb-2">{ellipsisText(data.details,50)}</h5>
                    </div>
                </Col>
            </Row>
        </Card.Body>
    </Card>
}

export default AnnouncementCard