import React from "react";
import { Col } from "react-bootstrap";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import ClassCard1 from "./ClassCard1";
import Languages from "./Languages";

interface Props {
  title?: string;
  fullTitle?: string;
  data: Array<ClassResponse>;
  state: AppState;
}

function ClassesCardVList({ title, fullTitle, data, state }: Props) {
  return (
    <Col lg={12} className="row m-0 p-0">
      {(title || fullTitle) && (
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title text-capitalize">
              {fullTitle && fullTitle!}
              {title && <Languages langKey={title} />}
            </h4>
          </div>
        </Col>
      )}
      <Col sm={12} className="row m-0 p-0">
        {data.map((item, i) => (
          <Col key={i} sm={6} md={3} lg={3} xl={2} className="mt-1">
            <ClassCard1 data={item} />
          </Col>
        ))}
      </Col>
    </Col>
  );
}

export default ClassesCardVList;
