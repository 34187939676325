import { AppState, GetUserData } from "../../services/app.service";
import { UserResponse } from "../../services/api.response.service";

export const StateKeys = {
  RECENT: "recent",
  TRENDING: "trending",
  ACTIVE: "active",
  FELLOWS: "fellows",
  PRIME: "prime",
  BUSINESS: "business",
  BOOK: "book",
  LIFESTYLE: "lifestyle",
  "TEST-PREP": "test-prep",
  CONTENT_PROVIDERS: "content_providers",
  CONTENT_PROVIDER_DETAILS: "content_provider_details",
  CONTENT_PROVIDER_CLASSES: "content_provider_classes",
  CATEGORIES: "categories",
  CATEGORY_CLASSES: "category_classes",
  INTERESTS: "interests",
  INTEREST_CLASSES: "interest_classes",
  CLASS_COURSES: "class_courses",
  CLASS_COURSE_TOPICS: "class_courses_topics",
  COURSE_TOPICS: "courses_topics",
  CLASS_TESTS: "class_tests",
  ACTIVE_CLASS: "active_class",
  ACTIVE_CLASSES: "active_classes",
  DATA_PERSIST: "data_persist",
  COURSE_CONTENT: "course_content",
  VIDEO: "video",
  AUDIO: "audio",
  PROFILE: "profile",
  USER_INTERESTS: "user_interests",
  USER_POINTS: "user_points",
  USER_POINTS_CLASS: "user_points_class",
  USER_POINTS_CLASS_DETAILS: "user_points_class_details",
  USER_PAYMENTS: "user_payments",
  GENERAL_SEARCH: "general_search",
  USER_SUBSCRIPTION: "user_subscription",
  CLASS_CONTENT_DISCUSSION: "course_discussion",
  CLASS_CONTENT_DISCUSSION_COMMENTS: "course_discussion_comments",
  ANNOUNCEMENTS: "announcements",
  EVENTS: "events",
  CLASS_TEST_RESULTS: "class_test_results",
  "INTEREST BASED": "interest-based",
};

export interface BaseState<T = any, S = any> {
  data: T;
  pageState: AppState;
  message: string;
  currentPage?: number;
  hasMore?: boolean;
  obj?: S;
}

export interface MainState {
  [key: string]: BaseState;
}

const main: MainState = {
  [StateKeys.RECENT]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.TRENDING]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.ACTIVE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.FELLOWS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.PRIME]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.BUSINESS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.LIFESTYLE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.BOOK]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys["TEST-PREP"]]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys["INTEREST BASED"]]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.CONTENT_PROVIDERS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },

  [StateKeys.CONTENT_PROVIDER_DETAILS]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.CONTENT_PROVIDER_CLASSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CATEGORIES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CATEGORY_CLASSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.INTERESTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.INTEREST_CLASSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CLASS_COURSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
    obj: {},
  },
  [StateKeys.CLASS_TESTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
    obj: {},
  },
  [StateKeys.CLASS_COURSE_TOPICS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
    obj: {},
  },
  [StateKeys.COURSE_TOPICS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
    obj: {},
  },
  [StateKeys.DATA_PERSIST]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.COURSE_CONTENT]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.VIDEO]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.AUDIO]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.PROFILE]: {
    data: { ...(GetUserData() as UserResponse) },
    pageState: AppState.IDLE,
    message: "",
  },
  [StateKeys.USER_INTERESTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.USER_POINTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.USER_POINTS_CLASS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.USER_POINTS_CLASS_DETAILS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.USER_PAYMENTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.GENERAL_SEARCH]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.USER_SUBSCRIPTION]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.ACTIVE_CLASS]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.ACTIVE_CLASSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CLASS_CONTENT_DISCUSSION]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.ANNOUNCEMENTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.EVENTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CLASS_TEST_RESULTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
  },
};

export default main;
