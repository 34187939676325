import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../redux/actions";
import {IRootState} from "../redux/states";
import {getLanguageByDefault, LANGUAGES} from "../services/app.service";

const AuthLanguageSwitch = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state);

  const language = state.setting.language;

  const handleLang = (lang: LANGUAGES) => {
    dispatch(Actions.setting.setLanguage(lang));
  };
  const getLanguage = getLanguageByDefault()
  return (
    <>
      <div
        className="d-flex justify-content-end align-items-center gap-3 pt-2 me-5"
        style={{ top: 0, position: "absolute", right: 0 }}
      >
        <h6
          onClick={() => handleLang(LANGUAGES.EN)}
          className={language === LANGUAGES.EN ? "border-bottom border-dark" : ""}
          style={{ cursor: "pointer" }}
        >
          {getLanguage.english}
        </h6>
        <h6
          onClick={() => handleLang(LANGUAGES.FR)}
          className={language === LANGUAGES.FR ? "border-bottom border-dark" : ""}
          style={{ cursor: "pointer" }}
        >
          {getLanguage.french}
        </h6>
      </div>
    </>
  );
};

export default AuthLanguageSwitch;
