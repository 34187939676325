import React, { useState } from "react";
import { Button, Card, Offcanvas, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NoData from "../pages/main/NoData";
import Main from "../redux/actions/main.action";
import { BaseState, StateKeys } from "../redux/states/main.state";
import ApiEndpoint from "../services/api.endpoint.service";
import {
  DiscussionCommentResponse,
  ClassContentDiscussionResponse,
} from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Languages from "./Languages";
import Loader from "./Loader";

interface props {
  openDiscussCanvas: string | null;
  setOpenDiscussCanvas: (close: null) => void;
  comments: BaseState<Array<DiscussionCommentResponse>>;
  discussion: ClassContentDiscussionResponse;
  discussions: BaseState<Array<ClassContentDiscussionResponse>>;
  contentID: string | number;
}

const DiscussionOffCanvas = ({
  openDiscussCanvas,
  setOpenDiscussCanvas,
  comments,
  discussion,
  discussions,
  contentID,
}: props) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState<string>();
  const [post, setPost] = useState<string>();

  const submit = (e: any) => {
    e.preventDefault();
    openDiscussCanvas === "comments" &&
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
          apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT(
            Number(discussion?.id)
          ),
          req: { comment },
          append: "start",
        })
      );

    openDiscussCanvas === "discussion" &&
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
          apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(contentID)),
          req: { post },
          append: "start",
        })
      );

    discussions.pageState === AppState.LOADED && setOpenDiscussCanvas(null);
  };

  return (
    <>
      <Offcanvas
        show={!!openDiscussCanvas}
        onHide={() => setOpenDiscussCanvas(null)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {openDiscussCanvas === "discussion" && (
              <Languages langKey="addDiscuss" />
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {openDiscussCanvas === "comments" && (
            <div>
              <>
                <h4>Discussion </h4>
                <div className="pt-2">
                  <Card key={discussion.id} className="p-2">
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <img
                        className="discuss-profile"
                        style={{
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          overflow: "hidden",
                          borderRadius: "50%",
                        }}
                        src={discussion.user.image_url}
                        alt={discussion.user.name}
                      />
                      <div>
                        <h6>{discussion.user.name}</h6>
                        <p>
                          {new Date(discussion.created_at).toDateString()},{" "}
                          {new Date(discussion.created_at).toLocaleTimeString(
                            "en-US"
                          )}
                        </p>
                      </div>
                    </div>

                    <p>{discussion.post}</p>
                  </Card>
                </div>
                <h6 className="mb-2 pt-4">
                  <Languages langKey="comments" />{" "}
                </h6>
                {comments.pageState === AppState.LOADED ? (
                  <div
                    className="overflow-scroll border"
                    style={{ height: "22em" }}
                  >
                    <div className="p-2">
                      {comments.data.length > 0 ? (
                        <>
                          {comments.data.map(
                            (comment: DiscussionCommentResponse) => (
                              <Card key={comment.id} className="p-2">
                                <div className="d-flex justify-content-start align-items-center gap-3">
                                  <img
                                    className="discuss-profile"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      display: "flex",
                                      overflow: "hidden",
                                      borderRadius: "50%",
                                    }}
                                    src={comment.user.image_url}
                                    alt={comment.user.name}
                                  />
                                  <div>
                                    <h6>{comment.user.name}</h6>
                                    <p>
                                      {new Date(
                                        comment.created_at
                                      ).toDateString()}
                                      ,
                                      {new Date(
                                        comment.created_at
                                      ).toLocaleTimeString("en-US")}
                                    </p>
                                  </div>
                                </div>

                                <p>{comment.comment}</p>
                              </Card>
                            )
                          )}{" "}
                        </>
                      ) : (
                        <NoData title="noComments" />
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {comments.pageState === AppState.LOADING && (
                      <Loader noCard imageLoader />
                    )}
                  </>
                )}{" "}
                <form className="pt-3" onSubmit={submit}>
                  <textarea
                    className="form-control"
                    name="comment"
                    id="comment"
                    cols={38}
                    rows={5}
                    placeholder="Enter Comment"
                    onChange={(e) => setComment(e.target.value)}
                    required
                  />

                  <div className="d-flex justify-content-end align-items-center gap-3 pt-2">
                    <Button variant="outline-danger">
                      <Languages langKey="cancel" />
                    </Button>
                    <Button variant="primary" type="submit">
                      <Languages langKey="post" />{" "}
                    </Button>
                  </div>
                </form>
              </>
            </div>
          )}

          {openDiscussCanvas === "discussion" && (
            <form className="pt-3" onSubmit={submit}>
              <textarea
                className="form-control"
                name="post"
                id="post"
                cols={38}
                rows={5}
                placeholder="Enter Discussion"
                onChange={(e) => setPost(e.target.value)}
                required
                disabled={discussions.pageState === AppState.LOADING}
              />

              <div className="d-flex justify-content-end align-items-center gap-3 pt-2">
                <Button variant="outline-danger">
                  {" "}
                  <Languages langKey="cancel" />
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={discussions.pageState === AppState.LOADING}
                >
                  {discussions.pageState === AppState.LOADING && (
                    <Spinner
                      animation={"border"}
                      size={"sm"}
                      className="me-1"
                    />
                  )}
                  <Languages langKey="post" />{" "}
                </Button>
              </div>
            </form>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DiscussionOffCanvas;
