import React, { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState,
  CheckContentProviderIdExist,
  getContentProviderId,
  getLanguageByDefault,
  InfoLogger,
  SearchLocal,
} from "../../services/app.service";
import {
  ClassResponse,
  CourseResponse,
  SubscriptionResponse,
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { RouteComponentProps, useParams } from "react-router";
import NoData from "./NoData";
import { RouteEndpoints } from "../../services/route.service";
import Loader from "../../components/Loader";
import CoursesOffCanvas from "../../components/CourseOffCanvas";
import TopicsOffCanvas from "../../components/TopicsOffCanvas";
import TestOffCanvas from "../../components/TestOffCanvas";
import CourseCardItems from "../../components/CourseCardItems";
import TestsCardList from "../../components/TestsCardList";
// import Languages from "../../components/Languages";

function ClassCourses({ location }: RouteComponentProps) {
  let { id } = useParams<{ id: string }>();
  // const id = location.pathname.replace(RouteEndpoints.CLASS_COURSES(""), "");
  const tab = location.hash.replace("#", "");
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const getLanguage = getLanguageByDefault();

  const dataPersist = state[StateKeys.DATA_PERSIST];
  const details: BaseState<Array<CourseResponse>, ClassResponse> = state[
    StateKeys.CLASS_COURSES
  ];
  const tests: BaseState<Array<TestResponse>> = state[StateKeys.CLASS_TESTS];
  const topics = state[StateKeys.CLASS_COURSE_TOPICS];
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];
  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const subscription =
    subscriptions.data.length > 0
      ? subscriptions.data.find((e) => String(e.subscriber_class.id) === id)
      : undefined;

  const [search, setSearch] = useState("");

  const [showTestDetails, setShowTestDetails] = useState<TestResponse>();
  const [showCourseDetails, setShowCourseDetails] = useState<CourseResponse>();
  const [showTopicDetails, setShowTopicDetails] = useState<CourseResponse>();

  const [showTests, setShowTests] = useState<TestResponse[]>([]);
  const [showCourses, setShowCourses] = useState<CourseResponse[]>([]);
  const [hasSubscribed, setHasSubscribed] = useState<boolean>(true);

  useEffect(() => {
    if (
      details.pageState !== AppState.LOADED ||
      (details.pageState === AppState.LOADED &&
        String((details.obj as ClassResponse).id) !== id)
    ) {
      // console.log(dataPersist.data[StateKeys.CLASS_COURSES.concat('-',id)],(details.pageState === AppState.LOADED && String((details.obj as ClassResponse).id) !== id))
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
      ) {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)].obj,
            clear: true,
          })
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
              .data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageObjectSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: {},
            clear: true,
          })
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSES,
            apiEndpoint: ApiEndpoint.CLASS_COURSES(Number(id)),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.CLASS_COURSES.concat("-", id),
          })
        );
      }
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)]
      ) {
        InfoLogger(
          "test",
          id,
          dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)]
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS(Number(id)),
            previousPage: 0,
            storeKey: StateKeys.CLASS_TESTS.concat("-", id),
          })
        );
      }
    } else if (tests.pageState !== AppState.LOADED) {
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)]
      ) {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", id)]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS(Number(id)),
            previousPage: 0,
            storeKey: StateKeys.CLASS_TESTS.concat("-", id),
          })
        );
      }
    }
  }, [id]);

  useEffect(() => {
    if (
      details.hasMore &&
      details.pageState !== AppState.LOADING_MORE &&
      details.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_COURSES,
          apiEndpoint: ApiEndpoint.CLASS_COURSES(Number(id)),
          previousPage: details.currentPage!,
          storeKey: StateKeys.CLASS_COURSES.concat("-", id),
        })
      );
    }

    setShowCourses(details.data);
    if (details.obj !== null) {
      setHasSubscribed(details.obj!.is_subscribed);
    }
  }, [details]);

  useEffect(() => {
    if (
      tests.hasMore &&
      tests.pageState !== AppState.LOADING_MORE &&
      tests.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_TESTS,
          apiEndpoint: ApiEndpoint.CLASS_TESTS(Number(id)),
          previousPage: tests.currentPage!,
          storeKey: StateKeys.CLASS_TESTS.concat("-", id),
        })
      );
    }

    setShowTests(tests?.data);
  }, [tests]);

  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      // @ts-ignore
      document.scrollingElement.scrollHeight
    ) {
      // @ts-ignore
      if (details.hasMore && details.pageState !== AppState.LOADING_MORE) {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSES,
            apiEndpoint: ApiEndpoint.CLASS_COURSES(Number(id)),
            previousPage: details.currentPage!,
            storeKey: StateKeys.CLASS_COURSES.concat("-", id),
          })
        );
      }
      // @ts-ignore
      if (tests.hasMore && tests.pageState !== AppState.LOADING_MORE) {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS(Number(id)),
            previousPage: tests.currentPage!,
            storeKey: StateKeys.CLASS_TESTS.concat("-", id),
          })
        );
      }
    }
  };

  useEffect(() => {
    getCourseSearchData(details.data);
    getTestSearchData(tests.data);
  }, [search]);

  const getCourseSearchData = (data: Array<CourseResponse>) =>
    setShowCourses(
      data.filter((item) =>
        SearchLocal(search.toLowerCase(), [
          item.title.toLowerCase(),
          item.description.toLowerCase(),
        ])
      )
    );

  const getTestSearchData = (data: Array<TestResponse>) =>
    setShowTests(
      data.filter((item) =>
        SearchLocal(search.toLowerCase(), [
          item.name.toLowerCase(),
          item.description.toLowerCase(),
          item.instructions.toLowerCase(),
          item.duration.toString(),
        ])
      )
    );

  const getCourseTopic = (course: CourseResponse) => {
    dispatch(
      Main.postPaginatedPageObjectSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPaginatedPageDataSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: [],
        clear: true,
      })
    );
    if (course.total_topics > 0) {
      if (
        dataPersist.data &&
        dataPersist.data[
          StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
        ]
      ) {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].pageState,
          })
        );
      } else {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(course.id)),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.CLASS_COURSE_TOPICS.concat(
              "-",
              String(course.id)
            ),
          })
        );
      }
    }
  };

  const getTestResults = (test: TestResponse) => {
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: [],
        clear: true,
      })
    );
    if (
      dataPersist.data &&
      dataPersist.data[
        StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
      ]
    ) {
      dispatch(
        Main.postPageArrayDataSuccess({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].data,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].pageState,
        })
      );
    } else {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(test.id)),
        })
      );
    }
  };

  useEffect(() => {
    if (subscription) {
      setHasSubscribed(true);
    }
  }, [subscriptions]);

  const subscribe = () => {
    const subscribed = subscription
      ? subscription.is_subscribed
      : details.obj?.is_subscribed;

    subscribed
      ? dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_CLASS(Number(id)),
            req: "",
            removeAt: subscription
              ? subscriptions.data.indexOf(subscription)
              : undefined,
          })
        )
      : dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_CLASS(Number(id)),
            req: "",
            append: "start",
          })
        );
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        {(details.pageState === AppState.LOADED ||
          details.pageState === AppState.LOADING_MORE) && (
          <>
            {details.data ? (
              CheckContentProviderIdExist() &&
              Number(getContentProviderId()) !==
                details.obj?.content_provider_id ? (
                <div className="text-center pt-5 mt-5">
                  <h5 className="pb-3">{getLanguage.sorryYouCannot}</h5>
                  <a
                    href={`web.ecampus.camp/classes/${id}/courses`}
                    target="_blank"
                    className="text-decoration-none btn btn-primary"
                    rel="noreferrer"
                  >
                    {getLanguage.clickHereToAccessContent}
                  </a>
                </div>
              ) : (
                <>
                  <Col sm={12}>
                    <Card>
                      <Card.Body>
                        <ul className="notification-list m-0 p-0">
                          <li className="d-flex align-items-center justify-content-between">
                            <div className="user-img img-fluid">
                              <img
                                src={details.obj!.logo_url}
                                alt="story-img"
                                className="rounded avatar-40"
                              />
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between">
                                <div className=" ms-3">
                                  <h5>{details.obj!.name}</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                  {!CheckContentProviderIdExist() && (
                                    <>
                                      <Button
                                        type="button"
                                        className="btn btn-primary rounded"
                                        onClick={() => subscribe()}
                                        disabled={
                                          subscriptions.pageState ===
                                          AppState.LOADING
                                        }
                                      >
                                        {subscriptions.pageState ===
                                          AppState.LOADING && (
                                          <Spinner
                                            animation={"border"}
                                            size={"sm"}
                                            className="me-1"
                                          />
                                        )}
                                        {subscription ? (
                                          <>
                                            {subscription.is_subscribed
                                              ? getLanguage.unSubscribe
                                              : getLanguage.subscribe}
                                          </>
                                        ) : (
                                          <>
                                            {details.obj?.is_subscribed
                                              ? getLanguage.unSubscribe
                                              : getLanguage.subscribe}
                                          </>
                                        )}
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <hr />
                        <Row>
                          <Col
                            sm={6}
                            md={4}
                            className="d-flex align-items-center gap-2"
                          >
                            <p className="text-dark">{getLanguage.category}:</p>
                            <p className="text-body">
                              {details.obj!.category?.name}
                            </p>
                          </Col>
                          <Col
                            sm={6}
                            md={4}
                            className="d-flex align-items-center gap-2"
                          >
                            <p className="text-dark">
                              {getLanguage.contentProvider}:
                            </p>{" "}
                            <p className="text-body">
                              {details.obj!.content_provider?.name}
                            </p>
                          </Col>
                          <Col
                            sm={6}
                            md={4}
                            className="d-flex align-items-center gap-2"
                          >
                            <p className="text-dark">
                              {getLanguage.totalCourses}:
                            </p>{" "}
                            <p className="text-body">
                              {details.obj!.total_courses}
                            </p>
                          </Col>
                          <Col
                            md={12}
                            className="d-flex align-items-center gap-3"
                          >
                            <p className="text-dark">
                              {getLanguage.description}:
                            </p>{" "}
                            <p className="text-body">
                              {details.obj!.description}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={12}>
                    <div className="d-flex justify-content-end mb-3">
                      <div className="iq-search-bar device-search">
                        <div className="searchbox">
                          <span className="search-link">
                            <i className="ri-search-line text-primary" />
                          </span>
                          <input
                            type="text"
                            className="text search-input bg-white"
                            placeholder={getLanguage.searchHere}
                            defaultValue={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <Tab.Container
                      defaultActiveKey={tab.length ? tab : "courses"}
                    >
                      <Card className="p-0">
                        <Card.Body className="p-0">
                          <div className="user-tabing">
                            <Nav
                              as="ul"
                              variant="pills"
                              className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                            >
                              <Nav.Item
                                as="li"
                                className=" col-12 col-sm-3 p-0 "
                              >
                                <Nav.Link
                                  eventKey="courses"
                                  role="button"
                                  className="text-center p-3"
                                >
                                  {getLanguage.courses}{" "}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                                <Nav.Link
                                  eventKey="tests"
                                  role="button"
                                  className="text-center p-3"
                                >
                                  {getLanguage.test}{" "}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </Card.Body>
                      </Card>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="courses">
                            <Row>
                              {details.data.length > 0 ? (
                                showCourses.length > 0 ? (
                                  <CourseCardItems
                                    courses={showCourses}
                                    setShowCourseDetails={setShowCourseDetails}
                                    getCourseTopic={getCourseTopic}
                                  />
                                ) : (
                                  <NoData title="noCourses" />
                                )
                              ) : (
                                <NoData title="noCourses" />
                              )}

                              <Col md={12}>
                                {details.pageState ===
                                  AppState.LOADING_MORE && (
                                  <Loader noCard imageLoader />
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tests">
                            <Row>
                              {(tests.pageState === AppState.LOADED ||
                                tests.pageState === AppState.LOADING_MORE) && (
                                <>
                                  {tests.data.length > 0 ? (
                                    showTests?.length > 0 ? (
                                      <TestsCardList
                                        tests={showTests}
                                        setShowTestDetails={setShowTestDetails}
                                        getTestResults={getTestResults}
                                      />
                                    ) : (
                                      <NoData title="noTest" />
                                    )
                                  ) : (
                                    <NoData title="noTest" />
                                  )}
                                </>
                              )}
                              <Col md={12}>
                                {(tests.pageState === AppState.LOADING_MORE ||
                                  tests.pageState === AppState.LOADING) && (
                                  <Loader noCard imageLoader />
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </Col>
                </>
              )
            ) : (
              <NoData
                title="classNotFound"
                link={RouteEndpoints.HOME}
                btnText="backHome"
              />
            )}
          </>
        )}
        <Col md={12}>
          {details.hasMore &&
            !(
              details.pageState === AppState.LOADING ||
              details.pageState === AppState.LOADING_MORE
            ) && (
              <div
                className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}
              >
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() => loadMore()}
                >
                  {getLanguage.loadMore}
                </button>
              </div>
            )}
        </Col>
        <Col md={12}>
          {(details.pageState === AppState.LOADING ||
            details.pageState === AppState.LOADING_MORE) && (
            <Loader noCard imageLoader />
          )}
        </Col>
        {details.pageState === AppState.ERROR_404 && (
          <NoData
            title="classNotFound"
            link={RouteEndpoints.HOME}
            btnText="backHome"
          />
        )}

        {showCourseDetails && 
        <CoursesOffCanvas
          hasSubscribed={hasSubscribed!}
          subscribeFunction={() => subscribe()}
          showCourseDetails={showCourseDetails!}
          setShowCourseDetails={setShowCourseDetails}
          hasPaid={details.obj?.has_paid}
          paymentUrl={details.obj?.payment_url}
        />}

        <TopicsOffCanvas
          topic={showTopicDetails!}
          setShowTopicDetails={setShowTopicDetails}
          subscriptionState={subscriptions.pageState!}
          hasSubscribed={hasSubscribed!}
          subscribeFunction={() => subscribe()}
          hasPaid={details.obj?.has_paid}
          paymentUrl={details.obj?.payment_url}
        />

        <TestOffCanvas
          results={testResults}
          subscriptionState={subscriptions.pageState!}
          hasSubscribed={hasSubscribed!}
          hasPaid={details.obj?.has_paid}
          paymentUrl={details.obj?.payment_url}
          subscribeFunction={() => subscribe()}
          showTestDetails={showTestDetails!}
          setShowTestDetails={setShowTestDetails}
        />
      </Row>
    </div>
  );
}

export default ClassCourses;
