import React, { useEffect, useState } from "react";
import { Card, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Button from "@restart/ui/esm/Button";
import { RouteEndpoints } from "../services/route.service";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../redux/actions/auth.action";
import { IRootState } from "../redux/states";
import {
  CheckAppLogoExist,
  CheckContentProviderIdExist,
  getAppLogo,
  getLanguageByDefault,
  LANGUAGES,
} from "../services/app.service";
import { auth } from "../services/firebase.service";
import { signOut } from "firebase/auth";
import Actions from "../redux/actions";
import Languages from "./Languages";

function Header() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state);

  const currentUser = state.setting.user!;
  const language = state.setting.language;

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");
  const checkUrl = location.pathname.includes(
    RouteEndpoints.SEARCHED_RESULT("")
  );
  const [search, setSearch] = useState<string>(checkUrl ? query ?? "" : "");
  const getLanguage = getLanguageByDefault();

  useEffect(() => {
    if (window.innerWidth > 2500) {
      miniSidebar();
    }
  }, []);

  const miniSidebar = () => {
    document.body.classList.toggle("sidebar-main");

    const cl = document.querySelector(".container");
    // // @ts-ignore
    // // cl.classList.toggle("me-3");
    // // @ts-ignore
    // cl.classList.toggle("ps-0");
  };

  const searchForData = (e: any) => {
    e.preventDefault();
    if (checkUrl) {
      return history.replace(RouteEndpoints.SEARCHED_RESULT(`?q=${search}`));
    }
    return history.push(RouteEndpoints.SEARCHED_RESULT(`?q=${search}`));
  };

  const handleLang = (lang: LANGUAGES) => {
    dispatch(Actions.setting.setLanguage(lang));
  };

  return (
    <>
      <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
          <Navbar expand="lg" variant="light" className="p-0">
            <div className="iq-navbar-logo d-flex justify-content-between">
              <Link to="/">
                <Image
                  src={CheckAppLogoExist() ? getAppLogo() : logo}
                  className="img-fluid"
                  alt="app logo"
                />
                {/*<span>{AppSettings().APP_SHORT_NAME} </span>*/}
              </Link>
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu" onClick={miniSidebar}>
                  <div className="main-circle">
                    <i className="ri-menu-line" />
                  </div>
                </div>
              </div>
            </div>
            {!CheckContentProviderIdExist() && (
              <div className="iq-search-bar device-search">
                <form className="searchbox" onSubmit={searchForData}>
                  <button className="search-link" type="submit">
                    <i className="ri-search-line text-primary" />
                  </button>

                  <input
                    type="text"
                    className="text search-input bg-white"
                    placeholder={getLanguage.searchHere}
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div>
            )}

            <Navbar.Toggle as="button">
              <i className="ri-menu-3-line" />
            </Navbar.Toggle>
            <Navbar.Collapse>
              <Nav
                as="ul"
                className="ms-auto navbar-list d-flex justify-content-center align-items-center gap-4"
              >
                {!CheckContentProviderIdExist() && (
                  <Link
                    to={RouteEndpoints.POINTS}
                    className="user-point position-relative rounded-circle d-flex flex-column align-items-center justify-content-center"
                    style={{
                      width: "56px",
                      height: "56px",
                      lineHeight: "normal",
                      cursor: "pointer",
                    }}
                  >
                    <b style={{ color: "#04a100" }}>{currentUser.points}</b>
                    <small className="text-dark">Points</small>
                  </Link>
                )}
                <div className="col-md-4">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="d-flex justify-content-center align-items-center gap-1 border border-0 text-dark"
                      style={{
                        background: "none",
                        color: "none !important",
                      }}
                    >
                      <i
                        className="las la-globe"
                        style={{ fontSize: "20px" }}
                      />
                      {language === LANGUAGES.FR ? "FR" : "ENG"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleLang(LANGUAGES.EN)}
                        className="lang-nav"
                        style={{ lineHeight: "20px" }}
                      >
                        {getLanguage.english}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleLang(LANGUAGES.FR)}
                        className="lang-nav"
                        style={{ lineHeight: "20px" }}
                      >
                        {getLanguage.french}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    href="#"
                    as={Button}
                    bsPrefix="d-flex align-items-center search-toggle"
                  >
                    <Image
                      src={currentUser.image_url}
                      className="img-fluid rounded-circle me-3"
                      alt="user"
                    />
                    <div className="caption">
                      <h6 className="mb-0 line-height">{currentUser.name}</h6>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="sub-drop dropdown-menu caption-menu"
                    aria-labelledby="drop-down-arrow"
                  >
                    <Card className="shadow-none m-0">
                      <Card.Header className="bg-primary">
                        <div className="header-title">
                          <h5 className="mb-0 text-white">
                            {currentUser.name}
                          </h5>
                          <span className="text-white font-size-12">
                            {currentUser.username}
                          </span>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-0 ">
                        <Link
                          to={RouteEndpoints.PROFILE}
                          className="iq-sub-card iq-bg-primary-hover d-flex align-items-center justify-content-between"
                        >
                          <div className="d-flex align-items-center">
                            <div className="rounded card-icon bg-soft-primary">
                              <i className="ri-file-user-line" />
                            </div>
                            <div className="ms-3">
                              <h6 className="mb-0 ">
                                <Languages langKey="myProfile" />
                              </h6>
                              <p className="mb-0 font-size-12">
                                <Languages langKey="profileDetails" />
                              </p>
                            </div>
                          </div>
                        </Link>

                        <div className="d-inline-block w-100 text-center p-3">
                          <Button
                            className="btn btn-primary iq-sign-btn"
                            onClick={() => {
                              dispatch(Auth.logout());
                              signOut(auth);
                            }}
                          >
                            <Languages langKey="signOut" />
                            <i className="ri-login-box-line ms-2" />
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default Header;
