import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { StateKeys } from "../../redux/states/main.state";
import { RouteComponentProps, useParams } from "react-router";
import {
  AppPageScrollDownLoadMore,
  AppState,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import { ClassResponse } from "../../services/api.response.service";
import ClassesCardVList from "../../components/ClassesCardVList";
import { RouteEndpoints } from "../../services/route.service";
import NoData from "./NoData";
import Languages from "../../components/Languages";

function CategoryClasses({ location }: RouteComponentProps) {
  // const id = location.pathname.replace("/ct/", "");
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const classes = state[StateKeys.CATEGORY_CLASSES];

  useEffect(() => {
    if (
      classes.pageState !== AppState.LOADED ||
      classes.data.length === 0 ||
      (classes.pageState === AppState.LOADED &&
        classes.data.length > 0 &&
        String((classes.data[0] as ClassResponse).category?.id ?? "") !== id)
    ) {
      dispatch(
        Main.postPaginatedPageDataSuccess({
          stateKey: StateKeys.CATEGORY_CLASSES,
          data: [],
          clear: true,
        })
      );
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORY_CLASSES,
          apiEndpoint: ApiEndpoint.CATEGORY_CLASSES(Number(id)),
          previousPage: 0,
        })
      );
    }
  }, [id]);

  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      classes.hasMore &&
      (classes.pageState !== AppState.LOADING || // @ts-ignore
        classes.pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORY_CLASSES,
          apiEndpoint: ApiEndpoint.CATEGORY_CLASSES(Number(id)),
          previousPage: classes.currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        {(classes.pageState === AppState.LOADED ||
          classes.pageState === AppState.LOADING_MORE) && (
          <>
            {classes.data.length ? (
              <ClassesCardVList
                data={classes.data as Array<ClassResponse>}
                state={classes.pageState}
                title={
                  (classes.data[0] as ClassResponse).category?.name ===
                  "Test Prep"
                    ? "testPrepCat"
                    : (classes.data[0] as ClassResponse).category?.name
                        .toLowerCase()
                        .concat("Cat")
                }
              />
            ) : (
              <NoData
                title="noClasses"
                link={RouteEndpoints.CATEGORIES}
                btnText="backToCat"
              />
            )}
          </>
        )}
        <Col md={12}>
          {classes.hasMore &&
            !(
              classes.pageState === AppState.LOADING ||
              classes.pageState === AppState.LOADING_MORE
            ) && (
              <div
                className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}
              >
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() => loadMore()}
                >
                  <Languages langKey="loadMore" />
                </button>
              </div>
            )}
        </Col>
        <Col md={12}>
          {(classes.pageState === AppState.LOADING_MORE ||
            classes.pageState === AppState.LOADING) && (
            <Loader noCard imageLoader />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CategoryClasses;
