import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states";
import {StateKeys} from "../../redux/states/main.state";
import {AppPageScrollDownLoadMore, AppState,} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import {CategoryResponse} from "../../services/api.response.service";
import {RouteEndpoints} from "../../services/route.service";
import {Link} from "react-router-dom";
import NoData from "./NoData";
import Languages from "../../components/Languages";

function Categories() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const { data, pageState, hasMore, currentPage } = state[StateKeys.CATEGORIES];

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORIES,
          apiEndpoint: ApiEndpoint.ALL_CATEGORIES,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const loadMore = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore &&
      // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORIES,
          apiEndpoint: ApiEndpoint.ALL_CATEGORIES,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        <Col sm={12} className="row m-0 p-0">
          {(pageState === AppState.LOADED ||
            pageState === AppState.LOADING_MORE) && (
            <>
              {data.length > 0 ? (
                (data as Array<CategoryResponse>).map(
                  ({ id, name, total_classes, level }, i) => (
                    <Col key={i} sm={3} className="mt-3">
                      <Link
                        to={RouteEndpoints.CATEGORY_CLASSES(String(id))}
                        className="text-decoration-none"
                      >
                        <Card>
                          <Card.Body>
                            <h5>{name}</h5>
                            <small
                              className="text-secondary d-flex align-items-center gap-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Total Available Classes"
                            >
                              <i
                                className="las la-university"
                                style={{ fontSize: 18 }}
                              />
                              <span className="text-primary">
                                {total_classes}
                              </span>
                            </small>
                            {/* <div className="mt-2">
                              <small>Levels</small>{" "}
                              <span className="text-primary d-block line-height mt-0">
                                {[...new Array(level)].map((item, i) => (
                                  <i key={i} className="fa fa-star" />
                                ))}
                              </span>
                            </div> */}
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="noCatAvailable" />
              )}
            </>
          )}
        </Col>
        <Col md={12}>
          {(hasMore &&
            !(pageState === AppState.LOADING ||
              pageState === AppState.LOADING_MORE)) && (
            <div className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}>
              <button
                className="text-decoration-none btn btn-primary"
                onClick={() => loadMore()}
              >
                <Languages langKey="loadMore" />
              </button>
            </div>
          )}
        </Col>
        <Col md={12}>
          {(pageState === AppState.LOADING_MORE ||
            pageState === AppState.LOADING) && <Loader noCard imageLoader />}
        </Col>
      </Row>
    </div>
  );
}

export default Categories;
