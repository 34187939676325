import React from "react";
import Card from "./Card";
import {EventResponse} from "../services/api.response.service";
import {ellipsisText} from "../services/app.service";
import moment from "moment";

interface Props{
    data: EventResponse
}

function EventCard({data}:Props) {
    return <Card className="rounded mb-0 card-height card-stretch card-block">
        <Card.Body>
            <div className="d-flex">
                <div className="date-of-event">
                    <span>{moment(data.date).format('MMM D')}</span>
                    <h6>{moment(data.date.concat(' ',data.time)).format('h:mm a')}</h6>
                </div>
                <div className="events-detail ms-3">
                    <h5 className="text-primary">{ellipsisText(data.title,40)}</h5>
                    <p>{ellipsisText(data.details,50)}</p>
                </div>
            </div>
        </Card.Body>
    </Card>
}

export default EventCard