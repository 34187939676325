import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { TestResponse } from "../services/api.response.service";

interface props {
  tests: Array<TestResponse>;
  setShowTestDetails: (test: TestResponse) => void;
  getTestResults: (test: TestResponse) => void;
}

const TestsCardList = ({ tests, setShowTestDetails,getTestResults }: props) => {
  return (
    <>
      <Row>
        {tests?.map((test: any) => (
          <Col key={test.id} sm={6} md={4} xl={4} className="mt-3">
            <Card
              className="test-card"
              onClick={() =>{
                setShowTestDetails(test)
                getTestResults(test)
              }}
            >
              <Card.Body>
                <h6>{test.name}</h6>
                <p className="text-truncate mt-2 mb-0 pb-2">
                  {test.description}
                </p>
                <p className="text-truncate mb-0 pb-0">{test.instructions}</p>

                <div className="mt-2 d-flex justify-content-between">
                  <small
                    className="d-flex gap-1 align-items-center"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Test Duration"
                  >
                    <i
                      className="las la-stopwatch"
                      style={{ fontSize: 18 }}
                    ></i>{" "}
                    <span className="text-primary">{test.duration}m</span>
                  </small>
                  {test.is_test_available && (
                    <small
                      className="text-success ms-auto"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Test Available"
                    >
                      <i
                        className="las la-check-square"
                        style={{ fontSize: 18 }}
                      ></i>{" "}
                    </small>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TestsCardList;
