import React from "react";
import { Offcanvas } from "react-bootstrap";
import {EventResponse} from "../services/api.response.service";
import moment from "moment";

interface props {
  details: EventResponse;
  setShowEventDetails: (event: undefined | EventResponse) => void;
}

const EventDetailsOffCanvas = ({details,setShowEventDetails}: props) => {
  return (
    <>
      {" "}
      <section id="EventDetailsOffCanvas">
        <Offcanvas
          show={details}
          onHide={() => setShowEventDetails(undefined)}
          placement="end"
          style={{ width: "40vw" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Event Details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {details.resource_url && <div className="image-block">
                <img src={details.resource_url} className="img-fluid rounded w-100" alt="blog-img"/>
            </div>}

            <div className="mt-2 d-flex">
             <span className="text-primary d-block line-height mt-0">
                  <i className="las la-calendar fs-4" />
              </span>
              <h6 className="mb-0 ms-2">{details.title}</h6>
            </div>
            <div className="mt-2 d-flex">
             <span className="text-primary d-block line-height mt-0">
                  <i className="las la-clock fs-4" />
              </span>
              <h6 className="mb-0 ms-2">{moment(details.date.concat(' ',details.time)).format('llll')}</h6>
            </div>
            <div className="mt-2 text-body d-flex">
              <span className="text-primary d-block line-height mt-0">
                  <i className="las la-map fs-4" />
              </span>
              <h6 className="mb-0 ms-2">{details.venue}</h6>
            </div>
            <div className="mt-2 text-body d-flex">
              <span className="text-primary d-block line-height mt-0">
                  <i className="las la-file fs-4" />
              </span>
              <div className="mb-0 ms-2" dangerouslySetInnerHTML={{__html: details.details}}/>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>{" "}
    </>
  );
};

export default EventDetailsOffCanvas;
