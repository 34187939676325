import React, { useEffect } from "react";
import { Row, Col, Nav, Tab, Card } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import {BaseState, StateKeys} from "../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState, getContentProviderId,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import { SubscriptionResponse } from "../../services/api.response.service";
import SubscriptionCard from "../../components/SubscriptionCard";
import NoData from "./NoData";
import Languages from "../../components/Languages";
import ClassCard1 from "../../components/ClassCard1";

function Subscriptions() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const { data, pageState, currentPage, hasMore }: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const contentProviderId = getContentProviderId();

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      // dispatch(
      //   Main.getPageArrayData({
      //     stateKey: StateKeys.USER_SUBSCRIPTION,
      //     apiEndpoint: ApiEndpoint.USER_SUBSCRIPTIONS,
      //     // previousPage: currentPage!,
      //   })
      // );

      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_SUBSCRIPTION,
          apiEndpoint: ApiEndpoint.USER_SUBSCRIPTIONS,
          previousPage: currentPage!,
          params:{
            'content_provider_id': contentProviderId
          }
        })
      );
    }

    // Sort subscriptions based on description length so cards done look soo weird
    data.sort(
      (
        a: { subscriber_class: { description: string } },
        b: { subscriber_class: { description: string } }
      ) => {
        return (
          a.subscriber_class.description.length -
          b.subscriber_class.description.length
        );
      }
    );
  }, []);

  // //group data based on names
  const subscribed = data.reduce((subscribed: any, item: any) => {
    const group = subscribed[item.subscriber_class.name] || [];
    group.push(item);
    subscribed[item.subscriber_class.name] = group;
    return subscribed;
  }, {});

  const loadMore = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore && // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_SUBSCRIPTION,
          apiEndpoint: ApiEndpoint.USER_SUBSCRIPTIONS,
          previousPage: currentPage!,
          params:{
            'content_provider_id': contentProviderId
          }
        })
      );

      // dispatch(
      //   Main.getPageArrayData({
      //     stateKey: StateKeys.USER_SUBSCRIPTION,
      //     apiEndpoint: ApiEndpoint.USER_SUBSCRIPTIONS,
      //     previousPage: currentPage!,
      //   })
      // );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        <Col sm={12} className="row m-0 p-0">
          {
          (pageState === AppState.LOADED ||
            pageState === AppState.LOADING_MORE) &&
              <>
                {data?.length > 0? (
                  <Col sm={12} className="row m-0 p-0">
                    {data.map((subscription: SubscriptionResponse,i) => (
                      <Col key={i} sm={6} md={3} lg={3} xl={2} className="mt-1">
                        <ClassCard1 data={subscription.subscriber_class} />
                      </Col>
                      /*<Col key={subscription.id} md={3} className="mt-1">
                        <SubscriptionCard subscription={subscription} />
                      </Col>*/
                    ))}
                  </Col>
                ) : (
                  <NoData title={"noSubscriptions"} />
                )}
              </>}
        </Col>
        <Col md={12}>
          {(hasMore &&
            !(pageState === AppState.LOADING || pageState === AppState.LOADING_MORE)) && (
            <div className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}>
              <button
                className="text-decoration-none btn btn-primary"
                onClick={() => loadMore()}
              >
                <Languages langKey="loadMore" />
              </button>
            </div>
          )}
        </Col>
        <Col md={12}>
          {(pageState === AppState.LOADING_MORE ||
            pageState === AppState.LOADING) && (
            <Loader noCard imageLoader />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Subscriptions;
