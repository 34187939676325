import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  AppPageScrollDownLoadMore,
  AppState,
  CheckContentProviderIdExist,
} from "../../services/app.service";
import Loader from "../../components/Loader";
import NoData from "./NoData";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { AnnouncementResponse } from "../../services/api.response.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import AnnouncementCard from "../../components/AnnouncementCard";
import AnnouncementDetailsOffCanvas from "../../components/AnnouncementDetailsOffCanvas";
import Languages from "../../components/Languages";

function Announcements() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const details: BaseState<Array<AnnouncementResponse>> =
    state[StateKeys.ANNOUNCEMENTS];
  const [showAnnouncementDetails, setShowAnnouncementDetails] =
    useState<AnnouncementResponse>();

  useEffect(() => {
    if (details.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.ANNOUNCEMENTS,
          apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
          previousPage: 0,
          storeKey: StateKeys.ANNOUNCEMENTS,
          params: CheckContentProviderIdExist()
            ? { content_provider_id: process.env.REACT_APP_CONTENT_PROVIDER_ID }
            : {},
        })
      );
    }
  }, []);
  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      // @ts-ignore
      document.scrollingElement.scrollHeight
    ) {
      // @ts-ignore
      if (details.hasMore && details.pageState !== AppState.LOADING_MORE) {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.ANNOUNCEMENTS,
            apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
            previousPage: details.currentPage!,
            storeKey: StateKeys.ANNOUNCEMENTS,
          })
        );
      }
    }
  };
  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Col lg={12} className="row m-0 p-0">
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title">
              {" "}
              <Languages langKey="announcement" />
            </h4>
          </div>
        </Col>
        <Col sm={12} className="row m-0 p-0">
          {details.data.length > 0 &&
            details.data.map((item) => (
              <Col
                key={item.id}
                sm={6}
                className="mt-1"
                onClick={() => setShowAnnouncementDetails(item)}
              >
                <AnnouncementCard data={item} />
              </Col>
            ))}
        </Col>
        <Col sm={12} className="row m-0 p-0">
          {(details.pageState === AppState.LOADING ||
            details.pageState === AppState.LOADING_MORE) && (
            <Loader noCard imageLoader />
          )}
        </Col>
        <Col sm={12} className="row m-0 p-0">
          {details.pageState === AppState.LOADED &&
            details.data.length === 0 && <NoData title="noEvents" />}
        </Col>
      </Col>
      <Col md={12}>
        {details.hasMore &&
          !(
            details.pageState === AppState.LOADING ||
            details.pageState === AppState.LOADING_MORE
          ) && (
            <div
              className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}
            >
              <button
                className="text-decoration-none btn btn-primary"
                onClick={() => loadMore()}
              >
                <Languages langKey="loadMore" />
              </button>
            </div>
          )}
      </Col>
      {showAnnouncementDetails && (
        <AnnouncementDetailsOffCanvas
          details={showAnnouncementDetails!}
          setShowAnnouncementDetails={setShowAnnouncementDetails}
        />
      )}
    </div>
  );
}

export default Announcements;
