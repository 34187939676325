import React from "react";
import { Col } from "react-bootstrap";
import { ContentProviderResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import NoData from "../pages/main/NoData";
import ContentProviderCard from "./ContentProviderCard";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";

interface Props {
  title?: string;
  data: Array<ContentProviderResponse>;
  state: AppState;
}

function ContentProvidersCardList({ data, state }: Props) {

  return (
    <Col sm={12} className="row m-0 p-0">
      {state === AppState.LOADING ? (
        <Loader noCard imageLoader />
      ) : data?.length > 0 &&
        (state === AppState.LOADED || state === AppState.LOADING_MORE) ? (
        (data as Array<ContentProviderResponse>).map((item, i) => (
          <Col key={i} sm={3} className="mt-5">
            <ContentProviderCard data={item} />
          </Col>
        ))
      ) : (
        <NoData title={"noContentProviders"} />
      )}
      {state === AppState.LOADING_MORE && <Loader noCard imageLoader />}
    </Col>
  );
}

export default ContentProvidersCardList;
