import React from "react";
import { Col, Row } from "react-bootstrap";
import { CourseResponse } from "../services/api.response.service";
import CourseCardItem from "./CourseCardItem";

interface props {
  courses: Array<CourseResponse>;
  setShowCourseDetails: (course: CourseResponse) => void;
  getCourseTopic: (course: CourseResponse) => void;
}

const CourseCardItems = ({
  courses,
  setShowCourseDetails,
  getCourseTopic,
}: props) => {
  return (
    <>
      <Row>
        {courses?.map((course: CourseResponse, index) => (
          <Col
            key={index}
            sm={6}
            className="mt-3"
            onClick={() => {
              getCourseTopic(course);
              setShowCourseDetails(course);
            }}
          >
            <CourseCardItem item={course} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default CourseCardItems;
