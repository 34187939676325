import React from "react";
import { RouteProps } from "react-router";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
import {
  AppSettings, CheckAppLogoExist,
  CheckAuthSliderTextsExist,
  CheckContentProviderIdExist,
  getAppLogo,
  getAuthSliderTexts
} from "../services/app.service";
import AuthLanguageSwitch from "../components/AuthLanguageSwitch";
import Languages from "../components/Languages";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const signInPageStyle = () => {
  let style = {};
  if (AppSettings().AUTH_BACKGROUND) {
    style = {
      background: AppSettings().AUTH_BACKGROUND,
      backgroundSize: "cover",
    };
  }

  return style;
};

function AuthLayout({ children }: RouteProps) {
  return (
    <div className="wrapper">
      <section className="sign-in-page" style={signInPageStyle()}>
        <Container className="p-0 m-0">
          <Row className="no-gutters vw-100">
            <Col lg="8" md="6" className="text-center pt-5">
              <div className="sign-in-detail text-white">
                <Link className="sign-in-logo mb-5" to="#">
                  <Image src={CheckAppLogoExist() ? getAppLogo() : logo} className="img-fluid" alt="logo" />
                </Link>
                <div className="sign-slider overflow-hidden ">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0 "
                  >
                    {CheckAuthSliderTextsExist() ? <>
                      {getAuthSliderTexts().map((item,i)=><SwiperSlide key={i}>
                        {/*<Image src={login1} className="img-fluid mb-4" alt="logo"/>*/}
                        <h4 className="mb-1 text-white">
                          {" "}
                          {item.title}
                        </h4>
                        <p>
                          {item.desc}
                        </p>
                      </SwiperSlide>)}
                    </>:<>
                      <SwiperSlide>
                        {/*<Image src={login1} className="img-fluid mb-4" alt="logo"/>*/}
                        <h4 className="mb-1 text-white">
                          {" "}
                          <Languages langKey="eStudents" />
                        </h4>
                        <p>
                          <Languages langKey="eStudentsP" />
                        </p>
                      </SwiperSlide>
                      <SwiperSlide>
                        {/*<Image src={login2} className="img-fluid mb-4" alt="logo"/>*/}
                        <h4 className="mb-1 text-white">
                          {" "}
                          <Languages langKey="eTeacher" />
                        </h4>
                        <p>
                          <Languages langKey="eTeacherP" />
                        </p>
                      </SwiperSlide>
                      <SwiperSlide>
                        {/*<Image src={login3} className="img-fluid mb-4" alt="logo"/>*/}
                        <h4 className="mb-1 text-white">
                          {" "}
                          <Languages langKey="eBusiness" />
                        </h4>
                        <p>
                          <Languages langKey="eBusinessP" />
                        </p>
                      </SwiperSlide>
                    </>}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 position-relative"
            >
              <AuthLanguageSwitch />

              {children}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default AuthLayout;
