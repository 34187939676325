import React, { FormEvent, useEffect, useRef, useState } from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import { RouteEndpoints } from "../../services/route.service";
import { remoteConfig } from "../../services/firebase.service";
import {
  AppState,
  CheckContentProviderIdExist,
  CheckInstitutionsExist, CheckSignUpReferralExist, getContentProviderId, getInstitutions,
  getKey, getLanguage, getLanguageByDefault, GetUserToken,
  InfoLogger
} from "../../services/app.service";
import constants, {Countries} from "../../utils/constants";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import NotifyAlert from "../../components/NotifyAlert";

import OAuth from "./OAuth";
import Languages from "../../components/Languages";
import Main from "../../redux/actions/main.action";
import {BaseState, StateKeys} from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import {AuthResponse, UserResponse} from "../../services/api.response.service";

const disableCaptcha =
  process.env.REACT_APP_ALLOW_CAPTCHA &&
  process.env.REACT_APP_ALLOW_CAPTCHA === "false";
function TeleSignIn() {
  const [account_id, setAccount_id] = useState("");
  const [code, setCode] = useState("");
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState(disableCaptcha ? "no" : "");
  const [retries, setRetries] = useState(3);
  const dispatch = useDispatch();
  const { state, message, showCode, newUser } = useSelector(
    (state: IRootState) => state.auth.telecel
  );
  const {pageState}: BaseState<UserResponse> = useSelector((state: IRootState) => state.main[StateKeys.PROFILE]);
  const user = useSelector(
    (state: IRootState) => state.setting.user
  );
  const { recaptchaSiteKey } = useSelector(
    (state: IRootState) => state.setting.keys
  );

  const [loadCaptcha, setLoadCaptcha] = useState(-3);

  const recaptchaSiteKey1 =
    remoteConfig.getValue(getKey(constants.RECAPTCHA_SITE_KEY)).asString() ??
    "";
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [institute, setInstitute] = useState("");
  const [about, setAbout] = useState("");

  const getLanguage = getLanguageByDefault()

  useEffect(() => {
    if (!recaptchaSiteKey && recaptchaSiteKey1) {
      dispatch(Actions.setting.setRecaptchaSiteKey(recaptchaSiteKey1));
    }
  }, [recaptchaSiteKey1]);

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  useEffect(() => {
    InfoLogger(state)
    if(state === AppState.RETRY && retries > 0){
      InfoLogger(state,retries)
      setRetries(prevState => prevState-1)
      // dispatch(Actions.auth.signIn({ username, password, token }));
      InfoLogger(state)
    }
  }, [state]);

  useEffect(() => {
    InfoLogger(pageState)
    if(pageState === AppState.LOADED){
      skipProfileUpdate()
    }
  }, [pageState]);

  useEffect(() => {
    if(user?.id){
      setFirst_name(user.first_name)
      setLast_name(user.last_name)
      setCountry(user.last_name)
      setUsername(user.username)
      setEmail(user.email)
      setCity(user.city)
      setInstitute(user.institute ?? '')
    }
  }, [newUser]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0 && !disableCaptcha) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        InfoLogger("captcha ready");
        setLoadCaptcha(1);
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    dispatch(Actions.auth.telecelSignIn({ account_id, code, token: token ?? 'yes' }));
  };

  const handleSubmitEditProfile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    InfoLogger((user as AuthResponse).access_token)
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.PROFILE,
        apiEndpoint: ApiEndpoint.UPDATE_USER,
        req: {
          first_name,
          last_name,
          country,
          username,
          city,
          institute,
          about,
          email,
          // profilePicture,
        },
        config:{
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (user as AuthResponse).access_token,
          }
        }
      })
    );
  };

  const skipProfileUpdate = () => {
    const path = localStorage.getItem("to.path");
    localStorage.removeItem("to.path");
    window.location.replace(path ?? RouteEndpoints.HOME);
  }

  return (
    <>
      <div className="sign-in-from">
        {user?.id && user?.first_name ? <>
          <p><Languages langKey="signInTele" /></p>
          <h1 className="mb-0">
            Hi, {user?.first_name}
          </h1>
        </> : <h1 className="mb-0">
          <Languages langKey="signInTele" />
        </h1>}
        {/*<h1 className="mb-0">
          <Languages langKey="signInTele" />
        </h1>*/}
        {newUser && <h6>Update your details by providing the right or appropriate information</h6>}
        {/*<Loader message={"Loading"}/>*/}
        {(state === AppState.ERROR || state === AppState.ERROR_INPUT) && (
          <NotifyAlert message={message} state={AppState.ERROR} timer={10000} />
        )}
        {!newUser ?
        <Form className="mt-4" onSubmit={handleSubmit}>
          <Form.Group className="form-group">
            <Form.Label>
              {" "}
              <Languages langKey="accountNumber" />
            </Form.Label>
            {showCode && <span className="float-end text-primary" onClick={()=> dispatch(Actions.auth.setTelecelSignInShowCode(false))}>
              <Languages langKey="changeAccountNumber" />
            </span>}
            <Form.Group className="input-group">
              <span className="input-group-text" id="basic-addon1">
                <i className="las la-phone" />
              </span>
              <Form.Control
                type="tel"
                className={`mb-0 ${
                  state === AppState.ERROR_INPUT && "is-invalid"
                }`}
                required
                disabled={showCode}
                onChange={(event) => setAccount_id(event.target.value)}
              />
            </Form.Group>
          </Form.Group>
          {showCode && <Form.Group className="form-group">
              <Form.Label>
                  <Languages langKey="code" />
              </Form.Label>
              <Form.Group className="input-group">
              <span className="input-group-text">
                <i className="las la-lock" />
              </span>
                  <Form.Control
                      type={"text"}
                      className="mb-0"
                      required
                      onChange={(event) => setCode(event.target.value)}
                  />
                {/*<span
                className="input-group-text bg-transparent"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className={`las la-${showPassword ? "eye-slash" : "eye"}`} />
              </span>*/}
              </Form.Group>
            {user?.id && <p className="text-secondary">Complete sign in by providing OTP sent to your number</p>}
          </Form.Group>}
          <div className="d-inline-block w-100">
            <Button variant="primary" type="submit" className="float-end">
              {state === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              {showCode ?
                <Languages langKey="signIn" /> : <Languages langKey="sendCode" />
              }{" "}
            </Button>
          </div>
          <div className="sign-info d-flex flex-column gap-4">
            <span className="dark-color d-inline-block line-height-2">
              <Languages langKey="noAccount" />?
              <Link to={RouteEndpoints.SIGN_UP}>
                {" "}
                <Languages langKey="signUp" />
              </Link>
            </span>

            {!CheckContentProviderIdExist() && <OAuth />}
          </div>
        </Form>
        :
        <Form className="mt-2 pb-5" onSubmit={handleSubmitEditProfile}>
          <Row className="w-100">
            <Col md="6" className="mb-1">
              <Form.Label className="mb-0">
                {getLanguage.firstName}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={user?.first_name}
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('first_name')) && "is-invalid"}`}
                onChange={(event) => setFirst_name(event.target.value)}
              />
            </Col>
            <Col md="6" className="mb-1">
              <Form.Label className="mb-0">
                <Languages langKey="lastName" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={user?.last_name}
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('last_name')) && "is-invalid"}`}
                onChange={(event) => setLast_name(event.target.value)}
              />
            </Col>
            <Col md="12" className="mb-1">
              <Form.Label className="mb-0">
                <Languages langKey="username" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={user?.username}
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('username')) && "is-invalid"}`}
                onChange={(event) => setUsername(event.target.value)}
              />
            </Col>
            <Col md="12" className="mb-1">
              <Form.Label className="mb-0">
                <Languages langKey="email" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                defaultValue={user?.email}
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('email')) && "is-invalid"}`}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Col>
            <Col md="6" className="mb-1">
              <Form.Label className="mb-0">
                <Languages langKey="country" />
                <span className="text-danger">*</span>
              </Form.Label>
              {/* <Form.Control
                type="text"
                required
                className={`${state === AppState.ERROR_INPUT && "is-invalid"}`}
                onChange={(event) => setCountry(event.target.value)}
              /> */}
              <Form.Select
                defaultValue={user?.country}
                className={`form-control ${
                  (state === AppState.ERROR_INPUT && message.includes('country')) && "is-invalid"
                }`}
                onChange={({ target: { value } }) => setCountry(value)}
                required
              >
                <option disabled value={''}>
                  --Select Country--
                </option>
                {Countries.map((country: string,i) => (
                  <option value={country} key={i}> {country}</option>
                ))}
              </Form.Select>
            </Col>
            <Col md="6" className="mb-1">
              <Form.Label className="mb-0">
                <Languages langKey="city" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={user?.city}
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('city')) && "is-invalid"}`}
                onChange={(event) => setCity(event.target.value)}
              />
            </Col>
            <Col md="12" className="mb-1">
              <Form.Label className="mb-0">
                {" "}
                <Languages langKey="institute" />
              </Form.Label>
              {!CheckInstitutionsExist() ? (
                <Form.Control
                  type="text"
                  onChange={(event) => setInstitute(event.target.value)}
                />
              ) : (
                <Form.Select
                  onChange={(event) => setInstitute(event.target.value)}
                  defaultValue={institute}
                >
                  <option disabled value={''}>
                    --Select Institute--
                  </option>
                  {getInstitutions().map((item: string, i: number) => (
                    <option key={i}>{item}</option>
                  ))}
                </Form.Select>
              )}
            </Col>
            {CheckContentProviderIdExist() &&
            getContentProviderId() === "74" ? (
              <Col md="12" className="mb-1">
                <Form.Label className="mb-0">Position</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => setAbout(event.target.value)}
                />
              </Col>
            ) : (
              <Col md="12" className="mb-1">
                <Form.Label className="mb-0">
                  {" "}
                  <Languages langKey="about" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={
                    getLanguage.tellUsAboutYourself
                  }
                  onChange={(event) => setAbout(event.target.value)}
                />
              </Col>
            )}
          </Row>
          <div className="d-flex justify-content-end w-100">
            <Button
              variant="outline-primary"
              type="reset"
              onClick={skipProfileUpdate}
            >
              <Languages langKey="skip" />
            </Button>
            <Button
              type="submit"
              className="btn btn-primary ms-2"
              disabled={pageState === AppState.LOADING}
            >
              {pageState === AppState.LOADING && (
                <Spinner
                  animation={"border"}
                  size={"sm"}
                  className="me-1"
                />
              )}
              <Languages langKey="update" />
            </Button>
          </div>
        </Form>
        }
      </div>
      {loadCaptcha && !disableCaptcha && (
        <ReCaptcha
          ref={recaptchaRef}
          action="submit"
          sitekey={recaptchaSiteKey}
          verifyCallback={(e) => setToken(e)}
        />
      )}
    </>
  );
}

export default TeleSignIn;
