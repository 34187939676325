import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { AppSidebarMenu, HomeName } from "../services/app.service";
import { IRootState } from "../redux/states";
import { useSelector } from "react-redux";

interface props {
  eventKey: string;
  handleClick: KeyFunction;
  children: React.ReactNode;
}

interface KeyFunction {
  (key: any): void;
}

function CustomToggle({ children, eventKey, handleClick }: props) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    handleClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={() => {
        // @ts-ignore
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}
const Sidebar = () => {
  useEffect(() => {
    // @ts-ignore
    Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
  });

  let location = useLocation();

  return (
    <>
      <div className="iq-sidebar">
        <div id="sidebar-scrollbar">
          <nav className="iq-sidebar-menu">
            <Accordion as="ul" id="iq-sidebar-toggle" className="iq-menu">
              {AppSidebarMenu().map((item) => (
                <li
                  key={item.link}
                  className={`${
                    location.pathname === item.link ? "active" : ""
                  } `}
                >
                  <Link to={item.link} onClick={() => window.scroll(0, 0)}>
                    <i className={item.icon} />
                    <span>
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </Accordion>
          </nav>
          {/* <div className="p-5"/> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
