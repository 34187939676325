import React, { FormEvent, useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { RouteEndpoints } from "../../services/route.service";
import Actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState, DEFAULT_TIMER } from "../../services/app.service";
import { IRootState } from "../../redux/states";
import NotifyAlert from "../../components/NotifyAlert";
import Languages from "../../components/Languages";

function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { state, message } = useSelector(
    (state: IRootState) => state.auth.forgotPassword
  );

  useEffect(() => {
    if (state === AppState.SUCCESS) {
      history.push(RouteEndpoints.CONFIRM_MAIL);
    }
  }, [state]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(Actions.auth.forgotPassword({ email }));
  };
  return (
    <div className="sign-in-from">
      <h1 className="mb-0">
        <Languages langKey="resetPassword" />
      </h1>
      <p>
        <Languages langKey="resetPasswordP" />
      </p>
      {state === AppState.ERROR && (
        <NotifyAlert
          message={message}
          state={AppState.ERROR}
          timer={DEFAULT_TIMER}
        />
      )}
      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>
            <Languages langKey="emailAddress" />
          </Form.Label>
          <Form.Control
            type="email"
            className="mb-0"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Group>
        <div className="d-inline-block w-100">
          <Button variant="primary" type="submit" className="float-right mt-3">
            {state === AppState.LOADING && (
              <Spinner animation={"border"} size={"sm"} className="me-1" />
            )}
            <Languages langKey="resetPassword" />
          </Button>
        </div>
      </Form>
      <div className="sign-info">
        <span className="dark-color d-inline-block line-height-2">
          <Languages langKey="gotAnAccount" />?{" "}
          <Link to={RouteEndpoints.SIGN_IN}>
            {" "}
            <Languages langKey="signIn" />?{" "}
          </Link>
        </span>
        {/*<ul className="iq-social-media">
                                 <li><Link to="#"><i className="ri-facebook-box-line"/></Link></li>
                                 <li><Link to="#"><i className="ri-twitter-line"/></Link></li>
                                 <li><Link to="#"><i className="ri-instagram-line"/></Link></li>
                              </ul>*/}
      </div>
    </div>
  );
}

export default ForgotPassword;
