import React from "react";
import Card from "./Card";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CourseResponse } from "../services/api.response.service";
import Languages from "./Languages";

interface Props {
  item: CourseResponse;
}

function CourseCardItem({ item }: Props) {
  return (
    <Card className="course-card">
      <Card.Body>
        <h6>{item.title}</h6>
        <div className="mt-2 text-body">
          <p className="mb-0">
            {item.description.length > 60
              ? item.description.slice(0, 60).concat("...")
              : item.description}
          </p>
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <span className="text-primary d-block line-height mt-0">
            {item.has_read && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {" "}
                    <Languages langKey="read" />{" "}
                  </Tooltip>
                }
              >
                <i className="las la-book-reader fs-4" />
              </OverlayTrigger>
            )}
            {item.has_practice && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {" "}
                    <Languages langKey="practice" />{" "}
                  </Tooltip>
                }
              >
                <i className="las la-dumbbell fs-4" />
              </OverlayTrigger>
            )}
          </span>
          {item.total_topics > 0 && (
            <small
              className="text-secondary d-flex align-items-center gap-1 "
              data-toggle="tooltip"
              data-placement="top"
              title="Total Topics"
            >
              <i className="las la-school" style={{ fontSize: 18 }}/>
              <span className="text-primary">{item.total_topics}</span>
            </small>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default CourseCardItem;
