import React from "react";
import { Redirect, Route, Switch, RouteProps } from "react-router";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container } from "react-bootstrap";
import AudioPlayer from "../components/AudioPlayer";
import { RouteEndpoints, Routes } from "../services/route.service";
import { CheckContentProviderIdExist } from "../services/app.service";

function DashboardLayout() {
  return (
    <>
      <div className="position-relative">
        <div className="wrapper">
          <Sidebar />
          <Header />
          {/*<RightSidebar />*/}
          {/*<div id="content-page" className="content-page scroller-hide">*/}
          <div id="content-page" style={{ height: "100vh" }}>
            <TransitionGroup>
              <CSSTransition classNames="fadeUp" timeout={300}>
                <Container className="dashboard-layout">
                  <Switch>
                    {
                      // eslint-disable-next-line array-callback-return
                      Routes.map(
                        (route, i) =>
                          route.private && (
                            <Route
                              key={i}
                              exact={route.exact}
                              path={route.path}
                              render={(props) => <route.component {...props} />}
                            />
                          )
                        //   (route, i) => route.private && console.log(route.path)
                      )
                    }
                    <Redirect to={{ pathname: RouteEndpoints.HOME }} />
                  </Switch>
                  {/*<AudioPlayer />*/}
                </Container>
              </CSSTransition>
            </TransitionGroup>
            <Footer />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default DashboardLayout;
