import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClassCard2 from "./ClassCard2";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import Card from "./Card";
import Languages from "./Languages";

interface Props {
  viewAllLink?: string;
  data: Array<ClassResponse>;
  state: AppState;
  language?: string;
}

function ClassCardRList({ viewAllLink, data, state, language }: Props) {
  return (
    <>
      <Col lg={12} className="row m-0 p-0">
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title">
              <Languages langKey={"recent"} />
            </h4>
          </div>
          {viewAllLink && state === AppState.LOADED && (
            <div className="card-header-toolbar d-flex align-items-center">
              <Link to={viewAllLink}>
                <Languages langKey={"viewAll"} />
              </Link>
            </div>
          )}
        </Col>
        <Col sm={12} className="row m-0 p-0">
          {state === AppState.LOADING ? (
            <Loader noCard imageLoader />
          ) : data.length > 0 ? (
            data.map((item) => (
              <Col key={item.id} sm={6} md={3} lg={3} xl={2} className="mt-1">
                <ClassCard2 recent={item} />
              </Col>
            ))
          ) : (
            <Card>
              <Card.Body className="text-center">
                <p>
                  <Languages langKey={"noData"} />
                </p>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Col>
    </>
  );
}

export default ClassCardRList;
