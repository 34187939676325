import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import Main from "../../redux/actions/main.action";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import { AppState } from "../../services/app.service";
import {
  Point,
  PointClassDetailsResponse,
  ClassResponse,
} from "../../services/api.response.service";
import NoData from "./NoData";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../../services/route.service";

const ClassPoints = () => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const state = useSelector((state: IRootState) => state.main);

  const user_points_class_details: BaseState<Array<PointClassDetailsResponse>> =
    state[StateKeys.USER_POINTS_CLASS_DETAILS];
  const activeClass: BaseState<ClassResponse> = state[StateKeys.ACTIVE_CLASS];

  useEffect(() => {
    if (
      activeClass.pageState !== AppState.LOADED ||
      (activeClass.pageState === AppState.LOADED &&
        String((activeClass.data as ClassResponse).id) !== id)
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.ACTIVE_CLASS,
          apiEndpoint: ApiEndpoint.ACTIVE_CLASS(Number(id)),
        })
      );
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.USER_POINTS_CLASS_DETAILS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS_DETAILS(Number(id)),
          clear: true,
        })
      );
    }

    // if (
    //   user_points_class_details.pageState !== AppState.LOADED &&
    //   user_points_class_details.pageState !== AppState.LOADING
    // ) {
    //   dispatch(
    //     Main.getPageArrayData({
    //       stateKey: StateKeys.USER_POINTS_CLASS_DETAILS,
    //       apiEndpoint: ApiEndpoint.USER_POINTS_CLASS_DETAILS(Number(id)),
    //     })
    //   );
    // }
  }, [id]);

  const calculatePoint = (points: Array<Point>) => {
    let sum = { read: 0, practice: 0, watch: 0, listen: 0, discuss: 0 };
    points.forEach((e, i) => {
      // @ts-ignore
      sum = {
        ...sum,
        [e.where.toLowerCase()]:
          // @ts-ignore
          Number(sum[e.where.toLowerCase()] ?? 0) + e.marks,
      };
    });
    let total = 0;
    Object.keys(sum).forEach((e: string) => {
      // @ts-ignore
      total += sum[e];
    });
    // console.log(sum)
    // setTotalpoints(totalPoints + total)
    return { ...sum, total };
  };
  return (
    <>
      {" "}
      <section id="ClassPoints" className="content-page scroller-hide">
        <div className="header-title mt-1">
          {user_points_class_details.pageState === AppState.LOADED ? (
            <h4 className="card-title"> {activeClass.data?.name}</h4>
          ) : (
            <Loader noCard imageLoader />
          )}{" "}
        </div>{" "}
        <Row>
          {user_points_class_details.pageState === AppState.LOADED ||
          user_points_class_details.pageState === AppState.LOADING_MORE ? (
            <>
              {user_points_class_details.data.length > 0 ? (
                user_points_class_details.data.map(
                  (
                    {
                      content_points,
                      title,
                      points,
                      class_id,
                    }: PointClassDetailsResponse,
                    i: number
                  ) => (
                    <Col key={i} sm={6} lg={4} className="mt-3">
                      <Link
                        to={RouteEndpoints.CLASS_COURSES(String(class_id))}
                        className="text-decoration-none text-dark"
                      >
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center gap-2 border-bottom pb-2">
                              <h6>{title} </h6>
                              <div
                                className="rounded-circle d-flex align-items-center justify-content-center"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  background:
                                    "rgba(4,161,0,0.09145665101978295)",
                                  color: "#04a100",
                                }}
                              >
                                <b>{content_points}</b>
                              </div>
                            </div>
                            <div>
                              <div
                                className="mt-2 d-flex justify-content-start align-items-center"
                                key={i}
                              >
                                <span className="d-flex justify-content-center align-items-center gap-1 line-height mt-0 me-2">
                                  <i className="las la-book-reader fs-4 text-primary" />
                                  {calculatePoint(points).read}
                                </span>
                                <span className="d-flex justify-content-center align-items-center gap-1 line-height mt-0 me-2">
                                  <i className="las la-eye fs-4 text-primary" />
                                  {calculatePoint(points).listen}
                                </span>
                                <span className="d-flex justify-content-center align-items-center gap-1 line-height mt-0 me-2">
                                  <i className="las la-eye fs-4 text-primary" />
                                  {calculatePoint(points).watch}
                                </span>
                                <span className="d-flex justify-content-center align-items-center gap-1 line-height mt-0 me-2">
                                  <i className="las la-dumbbell fs-4 text-primary" />
                                  {calculatePoint(points).practice}
                                </span>
                                <span className="d-flex justify-content-center align-items-center gap-1 line-height mt-0 me-2">
                                  <i className="las la-eye fs-4 text-primary" />
                                  {calculatePoint(points).discuss}
                                </span>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>{" "}
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="noPoints" body="getMorePoints" />
              )}
            </>
          ) : (
            <Loader noCard imageLoader />
          )}
        </Row>
      </section>{" "}
    </>
  );
};

export default ClassPoints;
