import React from "react";
import Card from "./Card";
import {ContentProviderResponse} from "../services/api.response.service";
import { Link } from "react-router-dom";
import {RouteEndpoints} from "../services/route.service";

interface Props {
    data: ContentProviderResponse
}

function ContentProviderCard({data:{id,name, logo_url, description, address, country}}:Props) {
    return <Card>
        <Link to={RouteEndpoints.CONTENT_PROVIDER_DETAILS(String(id))} className="text-decoration-none">
            <Card.Body>
                <div className="iq-badges text-left">
                    <div className="badges-icon">
                        <img className="avatar-80 rounded" src={logo_url} alt=""/>
                    </div>
                    <h6 className="mb-2">{name.length > 21 ?
                        name.slice(0,21).concat('...'): name}</h6>
                    <p className="text-secondary">
                        {description.length > 42 ?
                            description.slice(0,42).concat('...'): description}</p>
                    <span className="text-uppercase text-secondary">{country}</span>
                </div>
            </Card.Body>
        </Link>
    </Card>
}

export default ContentProviderCard