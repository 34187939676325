import { put, all, takeLatest, call, delay } from "redux-saga/effects";
import {
  ForgotPasswordRequest,
  RegisterRequest,
  SignInRequest,
  SignUpRequest, TelecelSignInRequest,
} from "../../services/api.request.service";
import Actions from "../actions";
import { types } from "../actions/auth.action";
import Api from "../../services/api.service";
import constants from "../../utils/constants";
import {
  AuthResponse,
  BaseDataResponse,
} from "../../services/api.response.service";
import {AppState, ErrorLogger, InfoLogger, SetToken} from "../../services/app.service";
import { RouteEndpoints } from "../../services/route.service";
import ApiEndpoint from "../../services/api.endpoint.service";
import Setting from "../actions/setting.action";
import moment from "moment";

function* signIn({ payload }: any) {
  yield put(Actions.auth.setSignInMessage(""));
  yield put(Actions.auth.setSignInState(AppState.LOADING));
  // console.log(payload)
  try {
    if (payload.token) {
      const {
        data: { data, message },
      }: BaseDataResponse<BaseDataResponse<AuthResponse>> = yield call(
        Api.signIn,
        payload as SignInRequest
      );
      yield call(SetToken, data);
      const path = localStorage.getItem("to.path");
      localStorage.removeItem("to.path");
      window.location.replace(path ?? RouteEndpoints.HOME);

      yield put(Actions.auth.setSignInState(AppState.SUCCESS));
      yield put(Actions.auth.setSignInMessage(message));
    } else {
      yield put(Actions.auth.setSignInState(AppState.RETRY));
      yield put(Actions.auth.setSignInMessage("Please refresh the page"));
    }
  } catch (e: any) {
    // console.log(e);
    yield put(
      Actions.auth.setSignInState(
        e.response?.data ? AppState.ERROR_INPUT : AppState.ERROR
      )
    );
    yield put(
      Actions.auth.setSignInMessage(
        e.response?.data?.message ?? "Network Error"
      )
    );
  }
}

function* signUp({ payload }: any) {
  yield put(Actions.auth.setRegisterState(AppState.LOADING));
  yield put(Actions.auth.setRegisterMessage(""));
  // console.log(payload)
  try {
    if (
      payload.first_name.length > 0 &&
      payload.last_name.length > 0 &&
      payload.email.length > 0 &&
      payload.username.length > 0 &&
      payload.city.length > 0 &&
      payload.country.length > 0 &&
      payload.password.length > 0 &&
      payload.confirmPassword.length > 0 &&
      payload.password === payload.confirmPassword
    ) {
      if (payload.token.length > 1) {
        yield put(Actions.auth.setRegisterMessage("Creating account"));
        const { data }: BaseDataResponse<AuthResponse> = yield call(
          Api.signUp,
          payload as SignUpRequest
        );
        yield put(Actions.auth.setRegisterMessage("Logging in"));
        yield call(SetToken, data);
        yield new Promise<void>((resolve) =>
          setTimeout(() => {
            window.location.replace(RouteEndpoints.HOME);
            resolve();
          }, 1000)
        );
        yield put(Actions.auth.setRegisterState(AppState.SUCCESS));
      } else {
        yield put(Actions.auth.setRegisterState(AppState.ERROR));
        yield put(Actions.auth.setRegisterMessage("Please refresh the page"));
      }
    } else {
      yield put(
        Actions.auth.setRegisterMessage("Fields are required and must be valid")
      );
      yield put(Actions.auth.setRegisterState(AppState.ERROR_INPUT));
    }
  } catch (e: any) {
    // console.log(e)
    yield put(
      Actions.auth.setRegisterState(
        e.response?.data ? AppState.ERROR_INPUT : AppState.ERROR
      )
    );
    yield put(
      Actions.auth.setRegisterMessage(
        e.response?.data?.message ?? "Network Error"
      )
    );
  }
}

function* register({ payload }: any) {
  yield put(Actions.auth.setRegisterMessage(""));
  yield put(Actions.auth.setRegisterState(AppState.LOADING));
  try {
    if (payload) {
      const {
        data: { data, message },
      }: BaseDataResponse<BaseDataResponse<AuthResponse>> = yield call(
        Api.register,
        payload as RegisterRequest
      );
      yield call(SetToken, data);
      const path = localStorage.getItem("to.path");
      localStorage.removeItem("to.path");
      window.location.replace(path ?? RouteEndpoints.HOME);
      yield put(Actions.auth.setRegisterState(AppState.SUCCESS));
      yield put(Actions.auth.setRegisterMessage(message));
    } else {
      yield put(Actions.auth.setRegisterState(AppState.ERROR));
      yield put(Actions.auth.setRegisterMessage("Please refresh the page"));
    }
  } catch (e: any) {
    ErrorLogger(e);
    yield put(
      Actions.auth.setRegisterState(
        e.response?.data ? AppState.ERROR_INPUT : AppState.ERROR
      )
    );
    yield put(
      Actions.auth.setOAuthMessage(e.response?.data?.message ?? "Network Error")
    );
  }
}

function* logout() {
  localStorage.removeItem(constants.TOKEN);
  yield delay(200);
  window.location.replace(RouteEndpoints.SIGN_IN);
}

function* forgotPassword({ payload }: any) {
  yield put(Actions.auth.setForgotPasswordMessage(""));
  yield put(Actions.auth.setForgotPasswordState(AppState.LOADING));
  // console.log(payload)
  try {
    const { message }: BaseDataResponse<AuthResponse> = yield call(
      Api.passwordForgot,
      payload as ForgotPasswordRequest
    );
    yield put(Actions.auth.setForgotPasswordMessage(message));
    yield put(Actions.auth.setForgotPasswordState(AppState.SUCCESS));
  } catch (e: any) {
    yield put(Actions.auth.setForgotPasswordState(AppState.ERROR));
    yield put(
      Actions.auth.setForgotPasswordMessage(
        e.response?.data?.message ?? "Network Error"
      )
    );
  }
}

function* telecel({ payload }: any) {
  yield put(Actions.auth.setTelecelSignInMessage(""));
  yield put(Actions.auth.setTelecelSignInState(AppState.LOADING));
  // console.log(payload)
  try {
    if (payload.token) {
      const req = payload as TelecelSignInRequest
      const {
        data: { data, message },
      }: BaseDataResponse<BaseDataResponse<AuthResponse>> = yield call(
        Api.postPageData,
        ApiEndpoint.AUTH_TELECEL_SIGN_IN,
        req,{}
      );
      if(!req.code){
        yield put(Setting.setUser(data));
        yield put(Actions.auth.setTelecelSignInState(AppState.SUCCESS));
        yield put(Actions.auth.setTelecelSignInMessage(message));
        yield put(Actions.auth.setTelecelSignInShowCode(true));
      }
      else {
        yield put(Setting.setUser(data));
        yield call(SetToken, data);
        if(moment().diff(moment(data.created_at),'minutes') >= 10 && (data.first_name)){
          const path = localStorage.getItem("to.path");
          localStorage.removeItem("to.path");
          window.location.replace(path ?? RouteEndpoints.HOME);
        }
        else {
          yield put(Actions.auth.setTelecelSignInNewUser(true));
        }

        yield put(Actions.auth.setTelecelSignInState(AppState.SUCCESS));
        yield put(Actions.auth.setTelecelSignInMessage(message));
      }
    } else {
      yield put(Actions.auth.setTelecelSignInState(AppState.RETRY));
      yield put(Actions.auth.setTelecelSignInMessage("Please refresh the page"));
    }
  } catch (e: any) {
    // console.log(e);
    yield put(
      Actions.auth.setTelecelSignInState(
        e.response?.data ? AppState.ERROR_INPUT : AppState.ERROR
      )
    );
    yield put(
      Actions.auth.setTelecelSignInMessage(
        e.response?.data?.message ?? "Network Error"
      )
    );
    yield put(Actions.auth.setTelecelSignInShowCode(false));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    takeLatest(types.SIGN_IN, signIn),
    takeLatest(types.SIGN_UP, signUp),
    takeLatest(types.OAUTH, register),
    takeLatest(types.LOGOUT, logout),
    takeLatest(types.FORGOT_PASSWORD, forgotPassword),
    takeLatest(types.TELECEL_SIGN_IN, telecel),
  ]);
}
