import { all, call, put, takeEvery } from "redux-saga/effects";
import Actions from "../actions";
import {
  PageDataPayload,
  PaginatedPageDataPayload,
  PostPageDataPayload,
  PostPaginatedPageDataPayload,
  types,
} from "../actions/main.action";
import Api from "../../services/api.service";
import {AppState, InfoLogger} from "../../services/app.service";
import {
  BaseDataResponse,
  PaginatedDataResponse,
  PaginatedDataWithObjectResponse,
} from "../../services/api.response.service";

function* getPageArrayData({ payload }: any) {
  const { stateKey, apiEndpoint, storeKey, clear }: PageDataPayload = payload;
  yield put(Actions.main.togglePageState({ stateKey, data: AppState.LOADING }));
  try {
    const {
      data: { data },
    }: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.getPageData,
      apiEndpoint,{}
    );
    // yield put(Actions.main.postPageDataPersist({stateKey, data}))
    yield put(
      Actions.main.postPageArrayDataSuccess({ stateKey, data, clear: !!clear })
    );
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.main.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.main.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.main.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* getPageObjectData({ payload }: any) {
  const { stateKey, apiEndpoint, storeKey }: PageDataPayload = payload;
  yield put(Actions.main.togglePageState({ stateKey, data: AppState.LOADING }));
  try {
    // const {data:{data}}:BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(Api.getPageData, apiEndpoint)
    let data;
    const response: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.getPageData,
      apiEndpoint,{}
    );

    if (response.data.data) {
      data = response.data.data;
    } else {
      data = response.data;
    }

    InfoLogger("main saga", data);

    // yield put(Actions.main.postPageDataPersist({stateKey, data}))
    yield put(Actions.main.postPageObjectDataSuccess({ stateKey, data }));
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.main.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.main.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.main.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* getPaginatedPageData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    previousPage,
    obj,
    refresh,
    storeKey,
    clear,
    params
  }: PaginatedPageDataPayload = payload;

  if (refresh) {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.DATA_REFRESH })
    );
  } else if (previousPage === 0) {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADING })
    );
  } else {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADING_MORE })
    );
  }
  const page = Number(previousPage + 1);
  try {
    const { data: baseData }: BaseDataResponse<any> = yield call(
      Api.getPaginatedPageData,
      apiEndpoint, {page,...params??{}},
    );

    if (obj) {
      const {
        content_class,
        test_class,
      }: PaginatedDataWithObjectResponse<Array<any>> = baseData;
      yield put(
        Actions.main.postPaginatedPageObjectSuccess({
          stateKey,
          data: content_class ?? test_class,
        })
      );
    }
    const { data, meta, links }: PaginatedDataResponse<Array<any>> = baseData;
    // yield put(Actions.main.postPageDataPersist({stateKey, data: baseData}))
    yield put(
      Actions.main.postPaginatedPageDataSuccess({
        stateKey,
        data,
        clear: !!clear,
      })
    );
    yield put(
      Actions.main.setPaginatedPageCurrentPageNumber({
        stateKey,
        data: meta.current_page,
      })
    );
    yield put(
      Actions.main.togglePaginatedPageHasMore({ stateKey, data: !!links.next })
    );
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.main.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.main.togglePageState({
        stateKey,
        data: e?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.main.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* postPageObjectData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    storeKey,
    req,
    clear,
    append,
    config
  }: PostPageDataPayload = payload;

  // console.log(payload);

  yield put(Actions.main.togglePageState({ stateKey, data: AppState.LOADING }));
  try {
    const {
      data: { data },
    }: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.postPageData,
      apiEndpoint,
      req, config
    );

    // console.log("from saga", data);
    // yield put(Actions.main.postPageDataPersist({stateKey, data}))
    yield put(
      Actions.main.postPageObjectDataSuccess({
        stateKey,
        data,
        clear: !!clear,
        append,
      })
    );
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.main.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.main.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.main.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* postPaginatedPageData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    previousPage,
    obj,
    refresh,
    storeKey,
    clear,
    req,
  }: PostPaginatedPageDataPayload = payload;

  if (refresh) {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.DATA_REFRESH })
    );
  } else if (previousPage === 0) {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADING })
    );
  } else {
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADING_MORE })
    );
  }

  const page = Number(previousPage + 1);
  try {
    const { data: baseData }: BaseDataResponse<any> = yield call(
      Api.postPaginatedPageData,
      apiEndpoint,
      req,{page}
    );
    if (obj) {
      const {
        content_class,
        test_class,
      }: PaginatedDataWithObjectResponse<Array<any>> = baseData;
      yield put(
        Actions.main.postPaginatedPageObjectSuccess({
          stateKey,
          data: content_class ?? test_class,
        })
      );
    }
    const { data, meta, links }: PaginatedDataResponse<Array<any>> = baseData;
    // yield put(Actions.main.postPageDataPersist({stateKey, data: baseData}))
    yield put(
      Actions.main.postPaginatedPageDataSuccess({
        stateKey,
        data,
        clear: !!clear,
      })
    );
    yield put(
      Actions.main.setPaginatedPageCurrentPageNumber({
        stateKey,
        data: meta.current_page,
      })
    );
    yield put(
      Actions.main.togglePaginatedPageHasMore({ stateKey, data: !!links.next })
    );
    yield put(
      Actions.main.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.main.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.main.togglePageState({
        stateKey,
        data: e?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.main.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    takeEvery(types.PAGE_ARRAY_DATA, getPageArrayData),
    takeEvery(types.PAGE_OBJECT_DATA, getPageObjectData),
    takeEvery(types.POST_PAGE_DATA, postPageObjectData),
    takeEvery(types.PAGINATED_PAGE_DATA, getPaginatedPageData),
    takeEvery(types.POST_PAGINATED_PAGE_DATA, postPaginatedPageData),
  ]);
}
