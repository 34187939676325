import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import LockScreen from "../pages/auth/LockScreen";
import ConfirmMail from "../pages/auth/ConfirmMail";
import DashboardLayout from "../layouts/DashboardLayout";
import Home from "../pages/main/Home";
import AuthLayout from "../layouts/AuthLayout";
import ContentProviders from "../pages/main/ContentProviders";
import Subscriptions from "../pages/main/Subscriptions";
import CourseContent from "../pages/main/CourseContent";
import ClassCourses from "../pages/main/ClassCourses";
import Profile from "../pages/main/Profile";
import EditProfile from "../pages/main/EditProfile";
import HomeViewMore from "../pages/main/HomeViewMore";
import HomeViewMore2 from "../pages/main/HomeViewMore2";
import ContentProviderDetails from "../pages/main/ContentProviderDetails";
import Categories from "../pages/main/Categories";
import CategoryClasses from "../pages/main/CategoryClasses";
import Interests from "../pages/main/Interests";
import InterestClasses from "../pages/main/InterestClasses";
import MainSearchResult from "../pages/main/MainSearchResult";
import Forum from "../pages/main/Forum";
import { CheckContentProviderIdExist } from "./app.service";
import ContentProviderClasses from "../pages/main/whiteLabel/ContentProviderClasses";
import WhiteLabelHome from "../pages/main/whiteLabel/Home";
import Events from "../pages/main/Events";
import Announcements from "../pages/main/Announcements";
import Points from "../pages/main/ClassPoints";
import ClassPoints from "../pages/main/ClassPointsDetails";
import TeleSignIn from "../pages/auth/TeleSignIn";

export interface IRoute {
  layout: Function;
  component: Function;
  exact: boolean;
  path: string;
  redirect: string;
  private: boolean;
}

const routePrefix = (endpoint: string) => {
  const prefix = "/";
  return `${prefix}${endpoint}`;
};

export const RouteEndpoints = {
  REDIRECT_HOME: routePrefix(CheckContentProviderIdExist() ? `classes` : "/"),
  REDIRECT_AUTH: routePrefix("sign-in"),

  //Auth
  SIGN_IN: routePrefix("sign-in"),
  SIGN_UP: routePrefix("sign-up"),
  FORGOT_PASSWORD: routePrefix("reset-password"),
  LOCK_SCREEN: routePrefix("lock-screen"),
  CONFIRM_MAIL: routePrefix("confirm-email"),
  TELE_SIGN_IN: routePrefix("tele-sign-in"),

  //Main
  HOME_DEFAULT: routePrefix(""),
  HOME: routePrefix("home"),
  PROFILE: routePrefix("profile"),
  CONTENT_PROVIDERS: routePrefix("content-providers"),
  SUBSCRIPTIONS: routePrefix("subscriptions"),
  EVENTS: routePrefix("events"),
  FORUM: routePrefix("forum"),
  ANNOUNCEMENTS: routePrefix("announcements"),
  TESTS: routePrefix("tests"),
  RECENT: routePrefix("recent"),
  CLASS_COURSES: (id: string) => routePrefix(`classes/${id}/courses`),
  COURSE_CONTENT: (id: string) => routePrefix(`content/${id}`),
  EDIT_PROFILE: routePrefix("edit/profile"),
  HOME_VIEW_MORE: (id: string) => routePrefix(`h/${id}`),
  HOME_VIEW_MORE2: (id: string) => routePrefix(`hh/${id}`),
  CONTENT_PROVIDER_DETAILS: (id: string) =>
    routePrefix(`content-provider/${id}`),
  CATEGORIES: routePrefix(`categories`),
  CATEGORY_CLASSES: (id: string) => routePrefix(`category/${id}/classes`),
  INTERESTS: routePrefix(`interests`),
  INTEREST_CLASSES: (id: string) => routePrefix(`interest/${id}/classes`),
  SEARCHED_RESULT: (query: string) => routePrefix(`search${query}`),
  CONTENT_PROVIDER_CLASSES: routePrefix(`courses`),
  POINTS: routePrefix("points/class"),
  CLASS_POINTS: (id: string) => routePrefix(`points/class/${id}`),
};

const AuthRoutes: Array<IRoute> = [
  {
    layout: AuthLayout,
    component: SignIn,
    exact: false,
    path: RouteEndpoints.SIGN_IN,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
  {
    layout: AuthLayout,
    component: SignUp,
    exact: false,
    path: RouteEndpoints.SIGN_UP,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
  {
    layout: AuthLayout,
    component: ForgotPassword,
    exact: false,
    path: RouteEndpoints.FORGOT_PASSWORD,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
  {
    layout: AuthLayout,
    component: LockScreen,
    exact: false,
    path: RouteEndpoints.LOCK_SCREEN,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
  {
    layout: AuthLayout,
    component: ConfirmMail,
    exact: false,
    path: RouteEndpoints.CONFIRM_MAIL,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
  {
    layout: AuthLayout,
    component: TeleSignIn,
    exact: false,
    path: RouteEndpoints.TELE_SIGN_IN,
    redirect: RouteEndpoints.REDIRECT_HOME,
    private: false,
  },
];
const MainRoutes: Array<IRoute> = [
  {
    layout: DashboardLayout,
    component: CourseContent,
    exact: false,
    path: RouteEndpoints.COURSE_CONTENT(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: ClassCourses,
    exact: false,
    path: RouteEndpoints.CLASS_COURSES(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Profile,
    exact: false,
    path: RouteEndpoints.PROFILE,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: EditProfile,
    exact: false,
    path: RouteEndpoints.EDIT_PROFILE,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Events,
    exact: false,
    path: RouteEndpoints.EVENTS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Announcements,
    exact: false,
    path: RouteEndpoints.ANNOUNCEMENTS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Subscriptions,
    exact: false,
    path: RouteEndpoints.SUBSCRIPTIONS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: ClassPoints,
    exact: false,
    path: RouteEndpoints.CLASS_POINTS(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Points,
    exact: false,
    path: RouteEndpoints.POINTS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
];
const ECampusRoutes: Array<IRoute> = [
  {
    layout: DashboardLayout,
    component: Home,
    exact: false,
    path: RouteEndpoints.HOME,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: ContentProviders,
    exact: false,
    path: RouteEndpoints.CONTENT_PROVIDERS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: HomeViewMore,
    exact: false,
    path: RouteEndpoints.HOME_VIEW_MORE(":key"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: HomeViewMore2,
    exact: false,
    path: RouteEndpoints.HOME_VIEW_MORE2(":key"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: ContentProviderDetails,
    exact: false,
    path: RouteEndpoints.CONTENT_PROVIDER_DETAILS(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Categories,
    exact: false,
    path: RouteEndpoints.CATEGORIES,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: CategoryClasses,
    exact: false,
    path: RouteEndpoints.CATEGORY_CLASSES(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Interests,
    exact: false,
    path: RouteEndpoints.INTERESTS,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: InterestClasses,
    exact: false,
    path: RouteEndpoints.INTEREST_CLASSES(":id"),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: MainSearchResult,
    exact: false,
    path: RouteEndpoints.SEARCHED_RESULT(""),
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
];
const ContentProvidesRoutes: Array<IRoute> = [
  {
    layout: DashboardLayout,
    component: WhiteLabelHome,
    exact: false,
    path: RouteEndpoints.HOME,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: ContentProviderClasses,
    exact: false,
    path: RouteEndpoints.CONTENT_PROVIDER_CLASSES,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
  {
    layout: DashboardLayout,
    component: Forum,
    exact: false,
    path: RouteEndpoints.FORUM,
    redirect: RouteEndpoints.SIGN_IN,
    private: true,
  },
];
export const Routes: Array<IRoute> = [
  ...AuthRoutes,
  ...MainRoutes,
  ...(CheckContentProviderIdExist() ? ContentProvidesRoutes : ECampusRoutes),
];
