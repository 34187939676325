import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Languages from "../../components/Languages";
import Loader from "../../components/Loader";
import Main from "../../redux/actions/main.action";
import { IRootState } from "../../redux/states";
import { StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import { Point, PointClassResponse } from "../../services/api.response.service";
import {AppPageScrollDownLoadMore, AppState} from "../../services/app.service";
import { RouteEndpoints } from "../../services/route.service";
import NoData from "./NoData";
const Points = () => {
  const dispatch = useDispatch();
  const { user_points_class } = useSelector((state: IRootState) => state.main);

  const { pageState, data, currentPage, hasMore } = user_points_class;

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS_CLASS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const calculatePoint = (points: Array<Point>) => {
    let sum = { read: 0, practice: 0, watch: 0 };
    points.forEach((e, i) => {
      // @ts-ignore
      sum = {
        ...sum,
        [e.where.toLowerCase()]:
          // @ts-ignore
          Number(sum[e.where.toLowerCase()] ?? 0) + e.marks,
      };
    });
    let total = 0;
    Object.keys(sum).forEach((e: string) => {
      // @ts-ignore
      total += sum[e];
    });
    // console.log(sum)
    // setTotalpoints(totalPoints + total)
    return { ...sum, total };
  };

  const loadMore = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      window.innerHeight + document.documentElement.scrollTop ===
      // @ts-ignore
      document.scrollingElement.scrollHeight &&
      hasMore && // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS_CLASS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div id="Points" className="content-page scroller-hide" onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}>
        <div className="header-title mt-1">
          <h4 className="card-title"> Points </h4>
        </div>{" "}
        <Row>
          {pageState === AppState.LOADED ||
          pageState === AppState.LOADING_MORE ? (
            <>
              {data.length > 0 ? (
                data.map(
                  (
                    { class_points, name, id, logo_url }: PointClassResponse,
                    i: number
                  ) => (
                    <Col key={i} sm={4} className="mt-3">
                      <Link
                        to={RouteEndpoints.CLASS_POINTS(String(id))}
                        className="text-decoration-none text-dark"
                      >
                        <Card>
                          <Card.Body className="d-flex justify-content-between align-items-center gap-2">
                            <div
                              className="class-images"
                              style={{ width: "70px", height: "70px" }}
                            >
                              <img
                                style={{ width: "70px" }}
                                src={logo_url}
                                alt={name}
                              />
                            </div>
                            <h6>{name} </h6>
                            <div
                              className="rounded-circle d-flex align-items-center justify-content-center"
                              style={{
                                width: "35px",
                                height: "35px",
                                background: "rgba(4,161,0,0.09145665101978295)",
                                color: "#04a100",
                              }}
                            >
                              <b>{class_points}</b>
                            </div>
                          </Card.Body>
                        </Card>{" "}
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="noPoints" body="getMorePoints" />
              )}
            </>
          ) : (
            <Loader noCard imageLoader />
          )}
          {pageState === AppState.LOADING_MORE && <Loader noCard imageLoader />}
          <Col md={12}>
            {(hasMore &&
              !(pageState === AppState.LOADING ||
                pageState === AppState.LOADING_MORE)) && (
              <div className={`w-100 text-center shadow-xss rounded-xxl border-0 pt-3`}>
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() => loadMore()}
                >
                  <Languages langKey="loadMore" />
                </button>
              </div>
            )}
          </Col>
        </Row>
      </div>
  );
};

export default Points;
