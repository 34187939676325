import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Offcanvas,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import img1 from "../../assets/images/page-img/profile-bg1.jpg";
import CustomToggle from "../../components/Dropdowns";
import { RouteEndpoints } from "../../services/route.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  InterestResponse,
  PaidSubscriptionResponse,
  Point,
  PointResponse,
  UserResponse,
  ClassResponse,
} from "../../services/api.response.service";
import {
  AppState,
  CheckContentProviderIdExist,
  CheckInstitutionsExist,
  getInstitutions,
  getLanguageByDefault,
  SetToken,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import NoData from "./NoData";
import Loader from "../../components/Loader";
import Setting from "../../redux/actions/setting.action";
import NotifyAlert from "../../components/NotifyAlert";
import InterestOffCanvas from "../../components/InterestOffCanvas";
import InterestClassesOffCanvas from "../../components/InterestClassesOffcanvas";
import Languages from "../../components/Languages";
import { Countries } from "../../utils/constants";
import { url } from "inspector";

function Profile() {
  // const tab = location.hash.replace('#','')
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const details: BaseState<UserResponse> = state[StateKeys.PROFILE];
  const userInterests: BaseState<Array<InterestResponse>> =
    state[StateKeys.USER_INTERESTS];
  const userPoints: BaseState<Array<PointResponse>> =
    state[StateKeys.USER_POINTS];
  const userPayments: BaseState<Array<PaidSubscriptionResponse>> =
    state[StateKeys.USER_PAYMENTS];
  const interests: BaseState<Array<InterestResponse>> =
    state[StateKeys.INTERESTS];
  const activeClasses: BaseState<Array<ClassResponse>> =
    state[StateKeys.ACTIVE_CLASSES];

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [first_name, setFirst_name] = useState(details.data.first_name);
  const [last_name, setLast_name] = useState(details.data.last_name);
  const [city, setCity] = useState(details.data.city);
  const [country, setCountry] = useState(details.data.country);
  const [about, setAbout] = useState("");
  const [institute, setInstitute] = useState(details.data.institute ?? "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [interestCanvas, setInterestCanvas] = useState<boolean>(false);
  const [InterestClassesCanvas, setInterestClassesCanvas] = useState<string>();
  const [email, setEmail] = useState(details.data.email ?? "");
  const [profilePicture, setProfilePicture] = useState<any>(
    details.data.image_url ?? ""
  );

  const getLanguage = getLanguageByDefault();

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      SetToken(details.data);
      // dispatch(Main.togglePageState({stateKey: StateKeys.PROFILE, data: AppState.IDLE}))
      dispatch(Setting.setUser(details.data));
      setFirst_name(details.data.first_name);
      setLast_name(details.data.last_name);
      setCity(details.data.city);
      setCountry(details.data.country);
      setInstitute(details.data.institute ?? "");
      setEmail(details.data.email);
      // setAbout(details.data.first_name)
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.PROFILE,
          data: AppState.IDLE,
        })
      );
      handleCloseEditProfile();
    }
  }, [details.pageState]);

  useEffect(() => {
    if (
      details.pageState !== AppState.LOADED &&
      userInterests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.PROFILE,
          apiEndpoint: ApiEndpoint.USER,
        })
      );
    }
    if (
      userInterests.pageState !== AppState.LOADED &&
      userInterests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_INTERESTS,
          apiEndpoint: ApiEndpoint.USER_INTERESTS,
          previousPage: userInterests.currentPage!,
        })
      );
    }
    if (
      userPoints.pageState !== AppState.LOADED &&
      userPoints.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.USER_POINTS,
          previousPage: userPoints.currentPage!,
        })
      );
    }
    if (
      userPayments.pageState !== AppState.LOADED &&
      userPayments.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_PAYMENTS,
          apiEndpoint: ApiEndpoint.USER_PAID_SUBSCRIPTIONS,
          previousPage: userPayments.currentPage!,
        })
      );
    }
    if (
      interests.pageState !== AppState.LOADED &&
      interests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTERESTS,
          apiEndpoint: ApiEndpoint.ALL_INTERESTS,
          previousPage: interests.currentPage!,
        })
      );
    }
  }, []);

  useEffect(() => {
    setInterestCanvas(false);
  }, [userInterests.data]);

  useEffect(() => {
    if (InterestClassesCanvas) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.ACTIVE_CLASSES,
          apiEndpoint: ApiEndpoint.ACTIVE_CLASSES(InterestClassesCanvas),
          clear: true,
        })
      );
    }
  }, [InterestClassesCanvas]);

  const calculatePoint = (points: Array<Point>) => {
    let sum = { read: 0, practice: 0, watch: 0 };
    points?.forEach((e, i) => {
      // @ts-ignore
      sum = {
        ...sum,
        [e.where.toLowerCase()]:
          // @ts-ignore
          Number(sum[e.where.toLowerCase()] ?? 0) + e.marks,
      };
    });
    let total = 0;
    Object.keys(sum).forEach((e: string) => {
      // @ts-ignore
      total += sum[e];
    });

    return { ...sum, total };
  };

  const handleCloseEditProfile = () => setShowEditProfile(false);
  const handleShowEditProfile = () => setShowEditProfile(true);
  const handleCloseChangePassword = () => setShowChangePassword(false);
  const handleShowChangePassword = () => setShowChangePassword(true);

  const handleSubmitEditProfile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.PROFILE,
        apiEndpoint: ApiEndpoint.UPDATE_USER,
        req: {
          first_name,
          last_name,
          country,
          city,
          institute,
          about,
          email,
          // profilePicture,
        },
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     Main.postPageData({
  //       stateKey: StateKeys.PROFILE,
  //       apiEndpoint: ApiEndpoint.UPDATE_USER,
  //       req: {
  //         profilePicture,
  //       },
  //     })
  //   );
  // }, [profilePicture]);

  const handleSubmitChangePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div className="content-page scroller-hide">
      <Row>
        <Col sm={12}>
          <Offcanvas
            show={showEditProfile}
            onHide={handleCloseEditProfile}
            placement="end"
            style={{ width: "45vw" }}
            scroll={true}
            backdrop={true}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Languages langKey="editProfile" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {(details.pageState === AppState.ERROR ||
                details.pageState === AppState.ERROR_INPUT) && (
                <NotifyAlert
                  message={details.message}
                  state={AppState.ERROR}
                  timer={10000}
                />
              )}
              <Form onSubmit={handleSubmitEditProfile}>
                <div className="d-flex justify-content-center mx-auto align-items-center flex-column">
                  <label
                    className="profile-picture mx-auto bg-danger"
                    htmlFor="profileImage"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",

                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                      src={
                        typeof profilePicture === "string"
                          ? profilePicture
                          : URL.createObjectURL(profilePicture)
                      }
                      alt=""
                      className="profile-picture mx-auto bg-danger"
                    />
                  </label>
                  <div className="text-center">
                    <label
                      className="text-danger"
                      htmlFor="profileImage"
                      style={{ cursor: "pointer" }}
                    >
                      Choose an image
                    </label>
                    <input
                      onChange={({ target: { files } }) =>
                        setProfilePicture(files![0])
                      }
                      type="file"
                      className="form-control d-none"
                      id="profileImage"
                    />
                  </div>
                </div>
                <Row className="align-items-center pt-4">
                  <Form.Group className="form-group col-sm-12 col-md-6">
                    <Form.Label htmlFor="first_name" className="form-label">
                      <Languages langKey="firstName" />:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="first_name"
                      defaultValue={first_name}
                      onChange={({ target: { value } }) => setFirst_name(value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12 col-md-6">
                    <Form.Label htmlFor="last_name" className="form-label">
                      <Languages langKey="lastName" />:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="last_name"
                      defaultValue={last_name}
                      onChange={({ target: { value } }) => setLast_name(value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12 col-md-6">
                    <Form.Label htmlFor="country" className="form-label">
                      <Languages langKey="country" />:
                    </Form.Label>
                    <Form.Select
                      className="form-control"
                      defaultValue={country}
                      onChange={({ target: { value } }) => setCountry(value)}
                      required
                    >
                      {Countries.map((country: string, i) => (
                        <option value={country} key={i}>
                          {" "}
                          {country}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12 col-md-6">
                    <Form.Label htmlFor="city" className="form-label">
                      <Languages langKey="city" />:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="city"
                      defaultValue={city}
                      onChange={({ target: { value } }) => setCity(value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label htmlFor="email" className="form-label">
                      <Languages langKey="email" />:
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="form-control"
                      id="email"
                      defaultValue={email}
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label htmlFor="institute" className="form-label">
                      <Languages langKey="institute" />:
                    </Form.Label>
                    {!CheckInstitutionsExist() ? (
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="institute"
                        defaultValue={institute}
                        onChange={({ target: { value } }) =>
                          setInstitute(value)
                        }
                      />
                    ) : (
                      <Form.Select
                        onChange={(event) => setInstitute(event.target.value)}
                        defaultValue={institute}
                      >
                        <option disabled value={""}>
                          --Select Institute--
                        </option>
                        {getInstitutions().map((item: string, i: number) => (
                          <option key={i}>{item}</option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>

                  <Form.Group className="form-group col-sm-12">
                    <Form.Label htmlFor="about" className="form-label">
                      <Languages langKey="about" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={getLanguage.tellUsAboutYourself}
                      onChange={(event) => setAbout(event.target.value)}
                      defaultValue={about}
                    />
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="reset"
                    onClick={handleCloseEditProfile}
                  >
                    <Languages langKey="cancel" />
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary ms-2"
                    disabled={details.pageState === AppState.LOADING}
                  >
                    {details.pageState === AppState.LOADING && (
                      <Spinner
                        animation={"border"}
                        size={"sm"}
                        className="me-1"
                      />
                    )}
                    <Languages langKey="update" />
                  </Button>
                </div>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas
            show={showChangePassword}
            onHide={handleCloseChangePassword}
            placement="end"
            style={{ width: "45vw" }}
            scroll={true}
            backdrop={true}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Languages langKey="changePass" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form onSubmit={handleSubmitChangePassword}>
                <Row className="align-items-center">
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label htmlFor="oldPassword" className="form-label">
                      <Languages langKey="changePass" />:
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control"
                      id="oldPassword"
                      defaultValue={oldPassword}
                      onChange={({ target: { value } }) =>
                        setOldPassword(value)
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label htmlFor="newPassword" className="form-label">
                      <Languages langKey="newPass" />:
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control"
                      id="newPassword"
                      defaultValue={newPassword}
                      onChange={({ target: { value } }) =>
                        setNewPassword(value)
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label
                      htmlFor="confirmPassword"
                      className="form-label"
                    >
                      <Languages langKey="confirmPass" />:
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      defaultValue={confirmPassword}
                      onChange={({ target: { value } }) =>
                        setConfirmPassword(value)
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="reset"
                    onClick={handleCloseEditProfile}
                  >
                    <Languages langKey="cancel" />:
                  </Button>
                  <Button type="submit" className="btn btn-primary ms-2">
                    <Languages langKey="save" />:
                  </Button>
                </div>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Card className="shadow-none">
            <Card.Body className=" profile-page p-0">
              <div className="profile-header">
                <div
                  className="position-relative "
                  style={{ height: 120, background: "#f8f9fa" }}
                >
                  <img
                    src={img1}
                    alt="profile-bg"
                    className="rounded img-fluid w-100"
                    style={{ opacity: 0 }}
                  />
                  {/*<ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                                    <li><Link to="#"><i className="ri-pencil-line"/></Link></li>
                                    <li><Link to="#"><i className="ri-settings-4-line"/></Link></li>
                                </ul>*/}
                </div>
                <div className="user-detail text-center mb-3">
                  <div className="profile-img">
                    <img
                      src={details.data.image_url}
                      alt="profile-img1"
                      className="avatar-130 img-fluid"
                    />
                  </div>
                  <div className="profile-detail">
                    <h3>{details.data.name}</h3>
                  </div>
                </div>
                <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative shadow">
                  <div className="social-info">
                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                      <li className="text-center ps-3">
                        <h6>Points</h6>
                        <p className="mb-0 text-primary">
                          {details.data.points}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="social-info">
                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                      <li className="text-center ps-3">
                        <Button
                          variant="outline-primary"
                          title="Edit Profile"
                          onClick={handleShowEditProfile}
                        >
                          <Languages langKey="editProfile" />
                        </Button>
                      </li>
                      <li className="bg-primary rounded p-2 pointer text-center ms-3">
                        <div className="card-header-toolbar d-flex align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-fill h4 text-white" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className=" dropdown-menu-right"
                              aria-labelledby="post-option"
                            >
                              <Dropdown.Item onClick={handleShowChangePassword}>
                                <Languages langKey="changePass" />
                              </Dropdown.Item>
                              {/*<hr/>*/}
                              <Dropdown.Item className="bg-danger">
                                <Languages langKey="deleteAccount" />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Tab.Container id="left-tabs-example" defaultActiveKey="about">
          <div className="card p-0">
            <div className="card-body p-0">
              <div className="user-tabing">
                <Nav
                  as="ul"
                  variant="pills"
                  className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                >
                  <Nav.Item as="li" className=" col-12 col-sm-3 p-0 ">
                    <Nav.Link
                      eventKey="about"
                      role="button"
                      className=" text-center p-3"
                    >
                      <Languages langKey="about" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                    <Nav.Link
                      eventKey="point"
                      role="button"
                      className="text-center p-3"
                    >
                      Points
                    </Nav.Link>
                  </Nav.Item>
                  {!CheckContentProviderIdExist() && (
                    <Nav.Item as="li" className=" col-12 col-sm-3 p-0">
                      <Nav.Link
                        eventKey="payment"
                        role="button"
                        className="text-center p-3"
                      >
                        <Languages langKey="payment" />
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </div>
            </div>
          </div>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="about">
                <Card.Body className=" p-0">
                  <Row>
                    <Col lg={4}>
                      {/*<div className="card">
                                            <div className="card-body">
                                                <Link to="#"><span className="badge badge-pill bg-primary font-weight-normal ms-auto me-1"><i className="ri-star-line"></i></span> 27 Items for yoou</Link>
                                            </div>
                                        </div>*/}
                      <Card>
                        <div className="card-header d-flex justify-content-between">
                          <div className="header-title">
                            <h4 className="card-title">
                              {" "}
                              <Languages langKey="interest" />
                            </h4>
                          </div>
                          <div className="card-header-toolbar d-flex align-items-center">
                            <p
                              className="m-0 text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={() => setInterestCanvas(true)}
                            >
                              <Languages langKey="update" />
                            </p>
                          </div>
                        </div>
                        <Card.Body>
                          <Row>
                            <Col sm={12} className="row m-0 p-0">
                              {(userInterests.pageState === AppState.LOADED ||
                                userInterests.pageState ===
                                  AppState.LOADING_MORE) && (
                                <>
                                  {userInterests.data.length > 0 ? (
                                    <ul>
                                      {userInterests.data.map(({ name }, i) => (
                                        <li
                                          className="badge bg-primary rounded-3 me-1 mb-1"
                                          key={i}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            setInterestClassesCanvas(name)
                                          }
                                        >
                                          {name}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <NoData title="noInterest" />
                                  )}
                                </>
                              )}
                            </Col>
                            <Col md={12}>
                              {(userInterests.pageState ===
                                AppState.LOADING_MORE ||
                                userInterests.pageState ===
                                  AppState.LOADING) && (
                                <Loader noCard imageLoader />
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Card>
                        <div className="card-header d-flex justify-content-between">
                          <div className="header-title">
                            <h4 className="card-title">
                              {" "}
                              <Languages langKey="profile" />
                            </h4>
                          </div>
                        </div>
                        <Card.Body>
                          <Row>
                            <Col md={3}>
                              <h6>
                                {" "}
                                <Languages langKey="username" />
                              </h6>
                            </Col>
                            <Col md={9} className="text-body">
                              <p className="mb-0 text-primary">
                                {details.data.username}
                              </p>
                            </Col>
                            <Col md={3}>
                              <h6>
                                {" "}
                                <Languages langKey="ecampusID" />
                              </h6>{" "}
                            </Col>
                            <Col md={9}>
                              <p className="mb-0 text-body">
                                {details.data.ecampus_id}
                              </p>
                            </Col>
                            <Col md={3}>
                              <h6>
                                <Languages langKey="city" />
                              </h6>
                            </Col>
                            <Col md={9}>
                              <p className="mb-0">{details.data.city ?? "-"}</p>
                            </Col>
                            <Col md={3}>
                              <h6>
                                <Languages langKey="country" />
                              </h6>{" "}
                            </Col>
                            <Col md={9}>
                              <p className="mb-0">
                                {details.data.country ?? "-"}
                              </p>
                            </Col>
                            <Col md={3}>
                              <h6>
                                <Languages langKey="institute" />
                              </h6>
                            </Col>
                            <Col md={9}>
                              <p className="mb-0">
                                {details.data.institute ?? "-"}
                              </p>
                            </Col>
                            <Col md={3}>
                              <h6>
                                <Languages langKey="referralID" />
                              </h6>
                            </Col>
                            <Col md={9}>
                              <p className="mb-0">
                                {details.data.referral_id ?? "-"}
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Tab.Pane>
              <Tab.Pane eventKey="point">
                <Row>
                  {userPoints.pageState === AppState.LOADED ||
                  userPoints.pageState === AppState.LOADING_MORE ? (
                    <>
                      {userPoints.data.length > 0 ? (
                        userPoints.data.map(({ point_class, points }, i) => (
                          <Col key={i} sm={4} className="mt-3">
                            <Card>
                              <Card.Body>
                                <h6>{point_class?.name}</h6>
                                <Link
                                  to={RouteEndpoints.CONTENT_PROVIDER_DETAILS(
                                    String(point_class?.content_provider.id)
                                  )}
                                  className="text-decoration-none"
                                >
                                  <small className="text-primary">
                                    {point_class?.content_provider.name}
                                  </small>
                                </Link>
                                {/*<small className="text-primary">Content Provider <span className="text-primary">{point_class.content_provider.name}</span></small>*/}
                                <div className="mt-2 text-body">
                                  <p className="mb-0">
                                    <Languages langKey="totalPoints" />
                                    <span className="text-primary">
                                      {" "}
                                      {calculatePoint(points).total}
                                    </span>
                                  </p>
                                </div>
                                <div className="mt-2 d-flex justify-content-start align-items-center">
                                  {/*<p className="mb-0">Total Points </p>*/}
                                  <span className="d-block line-height mt-0 me-2">
                                    <i className="las la-book-reader fs-4 text-primary" />{" "}
                                    {calculatePoint(points).read}
                                  </span>
                                  <span className="d-block line-height mt-0 me-2">
                                    <i className="las la-dumbbell fs-4 text-primary" />{" "}
                                    {calculatePoint(points).practice}
                                  </span>
                                  <span className="d-block line-height mt-0 me-2">
                                    <i className="las la-eye fs-4 text-primary" />{" "}
                                    {calculatePoint(points).watch}
                                  </span>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <NoData title="noPoints" body="getMorePoints" />
                      )}
                    </>
                  ) : (
                    <Loader noCard imageLoader />
                  )}
                  <Col md={12}>
                    {(userPoints.pageState === AppState.LOADING_MORE ||
                      userInterests.pageState === AppState.LOADING) && (
                      <Loader noCard imageLoader />
                    )}
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="payment">
                <Row>
                  {(userPayments.pageState === AppState.LOADED ||
                    userPayments.pageState === AppState.LOADING_MORE) && (
                    <>
                      {userPayments.data.length > 0 ? (
                        userPayments.data.map(({ subscriber }, i) => (
                          <Col key={i} sm={4} className="mt-3">
                            <Card>
                              <Card.Body>
                                <h6>{subscriber.subscriber_class.name}</h6>
                                <Link
                                  to={RouteEndpoints.CONTENT_PROVIDER_DETAILS(
                                    String(
                                      subscriber.subscriber_class
                                        .content_provider.id
                                    )
                                  )}
                                  className="text-decoration-none"
                                >
                                  <small className="text-primary">
                                    {
                                      subscriber.subscriber_class
                                        .content_provider.name
                                    }
                                  </small>
                                </Link>
                                <div className="mt-2 text-body">
                                  <p className="mb-0">
                                    {subscriber.subscriber_class.description}
                                  </p>
                                </div>
                                <div className="mt-2 d-flex justify-content-between">
                                  <p
                                    className={`me-2 ${
                                      !subscriber.is_subscribed
                                        ? "text-primary"
                                        : "text-success"
                                    }`}
                                  >
                                    {!subscriber.is_subscribed && "Not"}{" "}
                                    <Languages langKey="subscribed" />
                                  </p>
                                  <p
                                    className={`me-2 ${
                                      !subscriber.has_paid
                                        ? "text-primary"
                                        : "text-success"
                                    }`}
                                  >
                                    {!subscriber.has_paid && "Not"}{" "}
                                    <Languages langKey="paid" />
                                  </p>
                                  <p
                                    className={`me-2 ${
                                      subscriber.has_expired
                                        ? "text-primary"
                                        : "text-success"
                                    }`}
                                  >
                                    {!subscriber.has_expired && "Not"}{" "}
                                    <Languages langKey="expired" />
                                  </p>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <NoData title="noPaymentAvailable" />
                      )}
                    </>
                  )}
                  <Col md={12}>
                    {(userPoints.pageState === AppState.LOADING_MORE ||
                      userInterests.pageState === AppState.LOADING) && (
                      <Loader noCard imageLoader />
                    )}
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>

      <InterestOffCanvas
        userInterest={userInterests}
        // setUserInterest={setUserInterest}
        interests={interests}
        show={interestCanvas}
        setInterestCanvas={setInterestCanvas}
      />

      <InterestClassesOffCanvas
        show={InterestClassesCanvas}
        setInterestClassesCanvas={setInterestClassesCanvas}
        classes={activeClasses}
      />
    </div>
  );
}

export default Profile;
