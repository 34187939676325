import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../redux/states";
import Actions from "../redux/actions";
import { AppSettings } from "../services/app.service";

function Footer() {
  const { scheme } = useSelector((state: IRootState) => state.setting);
  const dispatch = useDispatch();
  return (
    <>
      <footer className="iq-footer* bg-white position-absolute w-100 bottom-0 py-2">
        <Container fluid>
          <Row>
            <Col sm={12}></Col>
            <Col lg="6">
              {/*<ul className="list-inline mb-0">
                                    <li className="list-inline-item"><Link to="#">Privacy Policy</Link></li>{' '}
                                    <li className="list-inline-item"><Link to="#">Terms of Use</Link></li>
                                </ul>*/}
            </Col>
            <Col lg="6" className="d-flex justify-content-end">
              <span>
                Copyright 2017 - {new Date().getFullYear()}{" "}
                <Link to="#">{AppSettings().APP_NAME}</Link> All Rights
                Reserved.
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
      {/*<div className="iq-float-menu">
                    <input type="checkbox" className="iq-float-menu-open" name="menu-open" id="menu-open" />
                    <label className="iq-float-menu-open-button" htmlFor="menu-open">
                        <span className="lines line-1"/>
                        <span className="lines line-2"/>
                        <span className="lines line-3"/>
                    </label>
                        <Button bsPrefix="iq-float-menu-item bg-info" onClick={() => {props.DirAction(!props.rtlMode)}}><i className={`${props.rtlMode ? 'ri-text-direction-r' : 'ri-text-direction-l'}`}></i></Button>
                        <Button bsPrefix="iq-float-menu-item bg-danger" onClick={() => dispatch(Actions.setting.setDarkMode(!scheme))}>
                            <i className={`${scheme ? 'ri-moon-clear-line':'ri-sun-line'}` } data-active={scheme ? 'true' : 'false'} /></Button>
                        <Button bsPrefix="iq-float-menu-item bg-warning" title="Comming Soon"><i className="ri-palette-line"></i></Button>
                </div>*/}
    </>
  );
}

export default Footer;
