// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Home Page
  recent: "Recent",
  trending: "Trending",
  active: "Active",
  fellows: "Fellows",
  prime: "Prime",
  business: "Business",
  book: "Book",
  lifestyle: "Lifestyle",
  testPrep: "Test Prep",
  viewAll: "View All",

  english: "English",
  french: "Français",

  en: "EN",
  fr: "FR",

  //Categories
  fellowCat: "Fellow's Category Classes",
  otherCat: "Other's Category Classes",
  businessCat: "Business's Category Classes",
  bookCat: "Book's Category Classes",
  testPrepCat: "Test Prep's Category Classes",
  lifestyleCat: "Lifestyle's Category Classes",

  //No data
  noData: "No Data",
  noCatAvailable: "No Categories available",
  noClasses: "No Classes for this Category",
  backToCat: "Back to Categories",
  noItem: "No item found",
  noCourses: "No Courses available",
  noTest: "No Test available",
  classNotFound: "Class is not found",
  DiscussionNotFound: "Discussions not found",
  backHome: "Back to Home",
  noContentProviderFound: "Content Provider is not found",
  backToContent: "Back to Content Providers",
  noClassInterest: "No Classes for this Interest",
  backToInterest: "Back to Interests",
  noInterest: "No Interests available",
  noPoints: "No Points available",
  getMorePoints: "Get more points by takes more Courses",
  noPaymentAvailable: "No Payment available",
  selectedAllInterest: "Selected All Interest",
  noClassAvailable: "No Class available",
  noComments: "No comments",
  noTopic: "No topics available",
  noTopicRP: "No Read or Practice topics available",
  noCourseFound: "Course is not found",
  noAnnouncement: "No Announcements available",
  noEvents: "No Events available",

  //Content ---- Providers
  noContentProviders: "No Content Providers available",
  contentProvider: "Content Provider",
  details: "Details",
  country: "Country",
  classes: "Classes",

  //Subscriptions
  allSubscription: "All Subscriptions",
  subscribe: "Subscribe",
  unSubscribe: "Unsubscribe",
  subscribed: "Subscribed",
  paid: "Paid",
  expired: "Expired",
  subscribeToView: "Subscribe to view course content",
  noSubscriptions: "No Subscriptions",

  //category --- class
  category: "Category",
  description: "Description",
  totalCourses: "Total Courses",
  courses: "Courses",
  test: "Tests",

  //Profile
  myProfile: "My Profile",
  profileDetails: "View personal profile details.",
  editProfile: "Edit Profile",
  firstName: "First Name",
  lastName: "Last Name",
  city: "City",
  institute: "Institute",
  changePass: "Change Password",
  oldPass: "Old Password",
  newPass: "New Password",
  confirmPass: "Confirm Password",
  deleteAccount: "Delete Account",
  about: "About Me",
  payment: "Payments",
  interest: "Interests",
  profile: "Profile ",
  username: "Username",
  ecampusID: "eCampus ID",
  referralID: "Referral ID",
  totalPoints: "Total Points",
  updateProfile: "Update Your Profile",
  updateInterest: "Update Interests",
  allInterest: "All Interests",

  //Buttons
  cancel: "Cancel",
  update: "Update",
  save: "Save",
  submit: "Submit",
  post: "Post",
  resetPassword: "Reset Password",
  resetPasswordP:
    "Enter your email address and we'll send you an email with instructions to reset your password.",

  //Sub components
  startLearning: "Start Learning",
  duration: "Duration",
  startTest: "Start Test",
  contentAdminTest: "Contact admin to subscribe you to this test",
  contentAdminCourse: "Contact admin to subscribe you to this course",
  subToStartLearn: "Subscribe to start learning",
  subToStartTest: "Subscribe to start test",
  totalTopics: "Total Topics",
  recentTopics: "Recent Topics",
  courseOutline: "Course Outline",

  //Discussions
  comments: "Comments",
  addDiscuss: "Add Discussion",

  //ToolTips
  read: "Read",
  listen: "Listen",
  practice: "Practice",
  watch: "Watch",
  discuss: "Discuss",

  //Aduios
  loading: "Loading",
  paused: "Paused",
  playing: "Playing",

  //auth Pages
  signIn: "Sign in",
  signUp: "Sign up",
  signOut: "Sign out",
  email: "E-mail",
  password: "Password",
  forgotPassword: "Forgot Password",
  noAccount: "Don't have an account",
  or: "or",
  signInGoogle: "Sign in with Google",
  signUpGoogle: "Sign up with Google",
  confirmPassword: "Confirm Password",
  passwordError: "Password must be 8 characters long",
  gotAnAccount: "Already Have Account",
  emailAddress: "Email address",

  //Main Page
  eStudents: "eCampus for Students",
  eStudentsP:
    "Learning just got easier. Set targets, take prep tests anytime and improve. No restrictions. Level up!",
  eTeacher: "Become a Teacher",
  eBusiness: "eCampus for Business",
  eBusinessP:
    "Coach and train your employees without the extra hustle. Understand their strength areas in your business.",
  eTeacherP:
    "Impart knowledge while earning extra income. Help refine knowledge for students all over Africa.",

  //WhiteLabel
  announcement: "Announcement",
  announcementDetails: "Announcement Details",
  events: "Events",
  loadMore: "Load more",
  cpNotFound: "Content Provider is not found",

  searchHere: "Search here...",
  tellUsAboutYourself: "Tell us about yourself",
  home: "Home",
  contentProviders: "Content Providers",
  subscriptions: "My Classes",
  categories: "Categories",
  interests: "Interests",
  tests: "Tests",
  announcements: "Announcements",
  forum: "Forum",
  success: "Success",
  aMailHasBeenSend:
    "A mail has been send to your email. Please check for an email from company and click on the included link to reset your password.",
  interestClasses: "Interest Classes",
  questions: "Questions",
  payToAccessContent: "Pay to access content",
  payNow: "Pay now",
  interestBased: "Based on your interests",
  sorryYouCannot: "Sorry you cannot access this content here",
  clickHereToAccessContent: "Click here to access content",
  signInTele: "Sign in with Telecel",
  sendCode: "Send code",
  code: "Code",
  phoneNumber: "Phone number",
  accountNumber: "Account number",
  changePhoneNumber: "Change phone number",
  changeAccountNumber: "Change account number",
  signInUsernameEmail: "Sign in with username/email",
  dateTimeLanguage: "en-US",
  skip: "Skip",
};
