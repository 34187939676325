import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import ClassesCardHList from "../../components/ClassesCardHList";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { RouteEndpoints } from "../../services/route.service";
import { AppState } from "../../services/app.service";
import { ClassResponse } from "../../services/api.response.service";
import ClassCardRList from "../../components/ClassCardRList";

const sliceLength = 6;
function Home() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const recent: BaseState<Array<ClassResponse>> = state[StateKeys.RECENT];
  // const trending:BaseState<Array<ClassResponse>> = state[StateKeys.TRENDING];
  // const active:BaseState<Array<ClassResponse>> = state[StateKeys.ACTIVE];
  // const fellows:BaseState<Array<ClassResponse>> = state[StateKeys.FELLOWS];
  // const prime:BaseState<Array<ClassResponse>> = state[StateKeys.PRIME];
  const business: BaseState<Array<ClassResponse>> = state[StateKeys.BUSINESS];
  const book: BaseState<Array<ClassResponse>> = state[StateKeys.BOOK];
  const lifestyle: BaseState<Array<ClassResponse>> = state[StateKeys.LIFESTYLE];
  const testPrep: BaseState<Array<ClassResponse>> =
    state[StateKeys["TEST-PREP"]];
  const interestBased: BaseState<Array<ClassResponse>> =
    state[StateKeys["INTEREST BASED"]];

  console.log(state[StateKeys.LIFESTYLE]);

  useEffect(() => {
    if (recent.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.RECENT,
          apiEndpoint: ApiEndpoint.USER_RECENT_TOPICS,
        })
      );
    }

    /*if (trending.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.TRENDING,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys.TRENDING
          ),
        })
      );
    }*/
    /*if (active.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.ACTIVE,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(StateKeys.ACTIVE),
        })
      );
    }*/
    /*if (fellows.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.FELLOWS,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys.FELLOWS
          ),
        })
      );
    }*/
    /*if (prime.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.PRIME,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(StateKeys.PRIME),
        })
      );
    }*/
    if (business.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.BUSINESS,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys.BUSINESS
          ),
        })
      );
    }
    if (book.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.BOOK,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(StateKeys.BOOK),
        })
      );
    }
    if (testPrep.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys["TEST-PREP"],
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys["TEST-PREP"]
          ),
        })
      );
    }
    if (interestBased.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys["INTEREST BASED"],
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys["INTEREST BASED"]
          ),
        })
      );
    }

    if (lifestyle.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys["LIFESTYLE"],
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(
            StateKeys["LIFESTYLE"]
          ),
        })
      );
    }
  }, []);

  return (
    <>
      <div className="content-page scroller-hide">
        <Row>
          {((recent.data.length > 0 && recent.pageState === AppState.LOADED) ||
            recent.pageState === AppState.LOADING) && (
            <ClassCardRList
              data={recent.data.slice(0, sliceLength)}
              state={recent.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.RECENT)}
            />
          )}
          {((interestBased.data.length > 0 &&
            interestBased.pageState === AppState.LOADED) ||
            interestBased.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"interestBased"}
              data={[...interestBased.data].slice(0, sliceLength)}
              state={interestBased.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(
                StateKeys["INTEREST BASED"]
              )}
            />
          )}

          {/*{((trending.data.length > 0 &&
            trending.pageState === AppState.LOADED) ||
            trending.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"trending"}
              data={(trending.data).slice(
                0,
                sliceLength
              )}
              state={trending.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.TRENDING)}
            />
          )}*/}

          {/*{((active.data.length > 0 && active.pageState === AppState.LOADED) ||
            active.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"active"}
              data={[...active.data.filter(e=> ![...(trending.data).slice(0, sliceLength)].find(ee=> e.id === ee.id))].slice(0, sliceLength)}
              state={active.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE2(StateKeys.ACTIVE)}
            />
          )}*/}

          {((testPrep.data.length > 0 &&
            testPrep.pageState === AppState.LOADED) ||
            testPrep.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"testPrep"}
              /*data={testPrep.data.slice(
                0,
                sliceLength
              )}*/
              data={[...testPrep.data].slice(0, sliceLength)}
              state={testPrep.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(
                StateKeys["TEST-PREP"]
              )}
            />
          )}

          {/*{((fellows.data.length > 0 &&
            fellows.pageState === AppState.LOADED) ||
            fellows.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"fellows"}
              data={[...fellows.data.filter(e=> ![...(trending.data).slice(0, sliceLength)].find(ee=> e.id === ee.id))].slice(
                0,
                sliceLength
              )}
              state={fellows.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.FELLOWS)}
            />
          )}*/}

          {/*{((prime.data.length > 0 && prime.pageState === AppState.LOADED) ||
            prime.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"prime"}
              data={[...prime.data.filter(e=> ![...(trending.data).slice(0, sliceLength)].find(ee=> e.id === ee.id))].slice(0, sliceLength)}
              state={prime.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.PRIME)}
            />
          )}*/}

          {((business.data.length > 0 &&
            business.pageState === AppState.LOADED) ||
            business.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"business"}
              data={[...business.data].slice(0, sliceLength)}
              state={business.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.BUSINESS)}
            />
          )}

          {((book.data.length > 0 && book.pageState === AppState.LOADED) ||
            book.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"book"}
              data={[...book.data].slice(0, sliceLength)}
              state={book.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.BOOK)}
            />
          )}

          {((lifestyle.data.length > 0 &&
            lifestyle.pageState === AppState.LOADED) ||
            lifestyle.pageState === AppState.LOADING) && (
            <ClassesCardHList
              title={"lifestyle"}
              data={[...lifestyle.data].slice(0, sliceLength)}
              state={lifestyle.pageState}
              viewAllLink={RouteEndpoints.HOME_VIEW_MORE(StateKeys.LIFESTYLE)}
            />
          )}
        </Row>
      </div>
    </>
  );
}

export default Home;
