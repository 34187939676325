import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router";

import TestOffCanvas from "../../components/TestOffCanvas";
import CourseCardItems from "../../components/CourseCardItems";
import CoursesOffCanvas from "../../components/CourseOffCanvas";
import TopicsOffCanvas from "../../components/TopicsOffCanvas";
import TestsCardList from "../../c../../components/TestsCardList";
import Loader from "../../components/Loader";

import ApiEndpoint from "../../services/api.endpoint.service";
import {
  CourseResponse,
  SearchResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import { IRootState } from "../../redux/states";
import Main from "../../redux/actions/main.action";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { AppState } from "../../services/app.service";
import { TestResponse } from "../../services/api.response.service";
import ClassesCardVList2 from "../../components/ClassesCardVList2";
import ContentProvidersCardList from "../../components/ContentProvidersCardList";

const MainSearchResult = () => {
  // @ts-ignore

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const [showTestDetails, setShowTestDetails] = useState<TestResponse>();
  const [showCourseDetails, setShowCourseDetails] = useState<CourseResponse>();
  const [showTopicDetails, setShowTopicDetails] = useState<CourseResponse>();
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];

  const generalSearch: BaseState<SearchResponse> =
    state[StateKeys.GENERAL_SEARCH];
  const topics = state[StateKeys.CLASS_COURSE_TOPICS];
  const dataPersist = state[StateKeys.DATA_PERSIST];
  // const courses: BaseState<Array<CourseResponse>, ClassResponse> = state[
  //   StateKeys.CLASS_COURSES
  // ];
  const collapseTitles = ["Contents", "Classes", "Content_Providers", "Tests"];

  useEffect(() => {
    if (
      generalSearch.pageState !== AppState.LOADED ||
      query !== data.search_text
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.GENERAL_SEARCH,
          apiEndpoint: ApiEndpoint.GENERAL_SEARCH(query ?? ""),
        })
      );
    }
  }, [query]);

  const getCourseTopic = (course: CourseResponse) => {
    dispatch(
      Main.postPaginatedPageObjectSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPaginatedPageDataSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: [],
        clear: true,
      })
    );

    if (course.total_topics > 0) {
      if (
        dataPersist.data &&
        dataPersist.data[
          StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
        ]
      ) {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].pageState,
          })
        );
      } else {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(course.id)),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.CLASS_COURSE_TOPICS.concat(
              "-",
              String(course.id)
            ),
          })
        );
      }
    }
  };

  const getTestResults = (test: TestResponse) => {
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: [],
        clear: true,
      })
    );
    if (
      dataPersist.data &&
      dataPersist.data[
        StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
      ]
    ) {
      dispatch(
        Main.postPageArrayDataSuccess({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].data,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].pageState,
        })
      );
    } else {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(test.id)),
        })
      );
    }
  };

  const { data, pageState } = generalSearch;

  return (
    <>
      <div className="content-page scroller-hide">
        <h5 className="pt-3 pb-3">
          Search results on <span className="text-capitalize"> "{query}"</span>
        </h5>
        {pageState !== AppState.LOADING && (
          <>
            <div className="pb-5 mb-5">
              {collapseTitles.map((title: string) => (
                <Accordion key={title}>
                  <Accordion.Item eventKey={title}>
                    <Accordion.Header>
                      <div className="d-flex flex-column">
                        {title.replace("_", " ")}{" "}
                        <h6>
                          Found: {data[`${title.toLocaleLowerCase()}_count`]}{" "}
                          item(s)
                        </h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {title === "Content_Providers" && (
                        <ContentProvidersCardList
                          data={data.content_providers}
                          state={pageState}
                        />
                      )}
                      {title === "Tests" && (
                        <TestsCardList
                          tests={data.tests}
                          setShowTestDetails={setShowTestDetails}
                          getTestResults={getTestResults}
                        />
                      )}
                      {title === "Classes" && (
                        <ClassesCardVList2
                          data={data.classes}
                          state={pageState}
                        />
                      )}
                      {title === "Contents" && (
                        <CourseCardItems
                          courses={data.contents}
                          setShowCourseDetails={setShowCourseDetails}
                          getCourseTopic={getCourseTopic}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
            <TestOffCanvas
              results={testResults}
              showTestDetails={showTestDetails!}
              setShowTestDetails={setShowTestDetails}
            />

            {showCourseDetails && 
            <CoursesOffCanvas
              showCourseDetails={showCourseDetails!}
              setShowCourseDetails={setShowCourseDetails}
            />}

            <TopicsOffCanvas
              topic={showTopicDetails!}
              setShowTopicDetails={setShowTopicDetails}
            />
          </>
        )}
        {pageState !== AppState.LOADING ||
          (query && <Loader noCard imageLoader />)}{" "}
      </div>
    </>
  );
};

export default MainSearchResult;
