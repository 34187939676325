import React from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import NoData from "../pages/main/NoData";
import { BaseState } from "../redux/states/main.state";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import ClassCard1 from "./ClassCard1";
import Languages from "./Languages";
import Loader from "./Loader";

interface props {
  show: string | undefined;
  setInterestClassesCanvas: (show: undefined) => void;
  classes: BaseState<Array<ClassResponse>>;
}

const InterestClassesOffCanvas = ({
  show,
  setInterestClassesCanvas,
  classes,
}: props) => {
  return (
    <>
      {" "}
      <section id="InterestClassesOffCanvas">
        <Offcanvas
          show={show}
          onHide={() => setInterestClassesCanvas(undefined)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Languages langKey="classes" /> {show}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {classes.pageState === AppState.LOADED ? (
              <Row>
                {classes.data.length > 0 ? (
                  classes.data.map((item, i) => (
                    <Col key={i} sm={6} className="mt-1">
                      <ClassCard1 data={item} />
                    </Col>
                  ))
                ) : (
                  <NoData title="noClassAvailable" />
                )}
              </Row>
            ) : (
              <>
                <Loader noCard imageLoader />
              </>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </section>{" "}
    </>
  );
};

export default InterestClassesOffCanvas;
