import { RouteEndpoints } from "./route.service";
import constants from "../utils/constants";
import { AuthResponse, UserResponse } from "./api.response.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import en from "../languages/en";
import fr from "../languages/fr";
import ReactGA from "react-ga";

const postFix = process.env.REACT_APP_ENV === "production" ? "" : "_dev";

export const getKey = (key: string) => `${key}${postFix}`;

export const DEFAULT_TIMER = 10000;

export const AppSettings = () => {
  return {
    APP_NAME: "eCampus",
    APP_ICON: "logo.png",
    APP_SHORT_NAME: "eC",
    AUTH_BACKGROUND: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${
      CheckAuthImageExist()
        ? getAuthImage()
        : "https://app.ecampus.camp/v3/img/login-bg/bg-7.jpg"
    }) center no-repeat`,
  };
};

export const runAppSettingsSetup = () => {
  document.title = AppSettings().APP_NAME;

  /*const signInPage = document.querySelector('.sign-in-page')
    if(signInPage && AppSettings().AUTH_BACKGROUND_IMAGE){
        // @ts-ignore
        signInPage.style.backgroundImage = `url(${AppSettings().AUTH_BACKGROUND_IMAGE})`
    }*/
};

export interface HomeName {
  fr: string;
  eng: string;
}

interface SidebarMenuItem {
  name: string;
  icon: string;
  link: string;
}

export const CheckContentProviderIdExist = () =>
  !!process.env.REACT_APP_CONTENT_PROVIDER_ID;
export const CheckInstitutionsExist = () =>
  !!process.env.REACT_APP_INSTITUTIONS;
export const CheckSignUpReferralExist = () =>
  !!process.env.REACT_APP_SIGN_UP_REFERRAL;
export var CheckAuthImageExist = () => !!process.env.REACT_APP_AUTH_IMAGE;
export var CheckAuthSliderTextsExist = () =>
  !!process.env.REACT_APP_AUTH_SLIDER_TEXT;
export var CheckAppNameExist = () => !!process.env.REACT_APP_APP_NAME;
export var CheckAppShortNameExist = () =>
  !!process.env.REACT_APP_APP_SHORT_NAME;
export var CheckAppLogoExist = () => !!process.env.REACT_APP_APP_LOGO;
export var CheckAppFaviconExist = () => !!process.env.REACT_APP_APP_FAVICON;

export const AppSidebarMenu = (): Array<SidebarMenuItem> => {
  let menu = [
    {
      name: getLanguageByDefault().home,
      icon: "las la-home",
      link: RouteEndpoints.HOME,
    },
  ];
  if (CheckContentProviderIdExist()) {
    menu = [
      ...menu,
      {
        name: getLanguageByDefault().subscriptions,
        icon: "las la-folder",
        link: RouteEndpoints.SUBSCRIPTIONS,
      },
      {
        name: getLanguageByDefault().classes,
        icon: "las la-anchor",
        link: RouteEndpoints.CONTENT_PROVIDER_CLASSES,
      },
      /*{
        name: getLanguageByDefault().forum,
        icon: "las la-comments",
        link: RouteEndpoints.FORUM,
      },*/
    ];
  } else {
    menu = [
      ...menu,
      {
        name: getLanguageByDefault().contentProviders,
        icon: "las la-users",
        link: RouteEndpoints.CONTENT_PROVIDERS,
      },
      {
        name: getLanguageByDefault().subscriptions,
        icon: "las la-folder",
        link: RouteEndpoints.SUBSCRIPTIONS,
      },
      /*{
              name: getLanguageByDefault().tests,
              icon: 'las la-home',
              link: RouteEndpoints.TESTS
          },*/
      {
        name: getLanguageByDefault().events,
        icon: "las la-calendar-alt",
        link: RouteEndpoints.EVENTS,
      },
      {
        name: getLanguageByDefault().announcements,
        icon: "las la-bullhorn",
        link: RouteEndpoints.ANNOUNCEMENTS,
      },
      {
        name: getLanguageByDefault().categories,
        icon: "ri-focus-2-line",
        link: RouteEndpoints.CATEGORIES,
      },
      {
        name: getLanguageByDefault().interests,
        icon: "las la-anchor",
        link: RouteEndpoints.INTERESTS,
      },
    ];
  }

  return menu;
};

export const AppPageScrollDownLoadMore = (e: any, loadMore: VoidFunction) => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight * 1.01;
  if (bottom) {
    loadMore();
  }
};

export const CheckTokenExist = () => !!localStorage.getItem(constants.TOKEN);

export const SetToken = (value: UserResponse | AuthResponse) => {
  let newData;
  if (CheckTokenExist()) {
    // @ts-ignore
    const data = JSON.parse(
      // @ts-ignore
      atob(localStorage.getItem(constants.TOKEN))
    ) as AuthResponse;
    newData = { ...data, ...value };
  } else {
    newData = { ...value };
  }
  localStorage.setItem(constants.TOKEN, btoa(JSON.stringify(newData)));
};

export const GetToken = () => {
  if (CheckTokenExist()) {
    try {
      // @ts-ignore
      return JSON.parse(
        // @ts-ignore
        atob(localStorage.getItem(constants.TOKEN))
      ) as AuthResponse;
    } catch (e) {
      localStorage.removeItem(constants.TOKEN);
      window.location.replace("/login");
    }
  }
  return {};
};

export const GetUserToken = () => {
  if (CheckTokenExist()) {
    return (GetToken() as AuthResponse)!.access_token;
  }
  return "";
};

export const GetUserData = () => {
  if (CheckTokenExist()) {
    // @ts-ignore
    return (GetToken() as AuthResponse)!;
  }
  return null;
};

export const GetFullURL = (value: string) => {
  return (process.env.REACT_APP_API_ENDPOINT ?? "")
    .replace("/api/v4", "")
    .concat(value);
};

export const SearchLocal = (value: string, queries: Array<string> | string) => {
  let res = false;
  if (value.length < 1) {
    return true;
  } else if (Array.isArray(queries)) {
    if (queries.includes(value)) {
      res = true;
    } else {
      queries.forEach((e) => {
        if (e.includes(value)) {
          res = true;
          return;
        }
      });
    }
  } else {
    return queries.includes(value);
  }

  return res;
};

//Initialize GA
ReactGA.initialize("G-D2ZF7BEWY5");

export const SendAnalytics = () => {
  // @ts-ignore
  window.gtag("event", "page_view", {
    page_title: window.location.pathname,
    page_path: window.location.pathname + window.location.search,
    page_location: window.location.href,
  });

  return ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

export enum AppState {
  IDLE,
  SUCCESS,
  ERROR,
  ERROR_404,
  LOADING,
  LOADED,
  ERROR_INPUT,
  LOADING_MORE,
  INFO,
  WARNING,
  DATA_REFRESH,
  PLAYING,
  PLAY,
  PAUSING,
  PAUSED,
  RETRY,
}

export enum ResourceTypes {
  IDLE,
  SUCCESS,
  AUDIO,
  VIDEO,
}

export const getContentProviderId = () =>
  process.env.REACT_APP_CONTENT_PROVIDER_ID;
export const getAppName = () => process.env.REACT_APP_APP_NAME ?? "eCampus";
export const getAppShortName = () =>
  process.env.REACT_APP_APP_SHORT_NAME ?? "eCampus";
export const getAppLogo = () => process.env.REACT_APP_APP_LOGO;
export const getAppFavicon = () => process.env.REACT_APP_APP_FAVICON;
export const getInstitutions = () =>
  process.env.REACT_APP_INSTITUTIONS
    ? [...process.env.REACT_APP_INSTITUTIONS.split(",")]
    : [];
export const ellipsisText = (text: string, value = 20) =>
  text.length > value ? text.slice(0, value).concat("..") : text;
export const getSignUpReferral = () => process.env.REACT_APP_SIGN_UP_REFERRAL;
export var getAuthImage = () => process.env.REACT_APP_AUTH_IMAGE;
export var getAuthSliderTexts = () => {
  let sliders: { title: string; desc: string }[] = [];
  // @ts-ignore
  const rawSliders = [...process.env.REACT_APP_AUTH_SLIDER_TEXT.split(",")];
  rawSliders.forEach((e) => {
    const slider = [...e.split("|")];
    if (slider.length === 1) {
      sliders.push({ title: "", desc: slider[0] });
    } else if (slider.length === 2) {
      sliders.push({ title: slider[0], desc: slider[1] });
    }
  });
  return sliders;
};

export enum LANGUAGES {
  EN,
  FR,
}

export const getLanguage = (key: string, lang: LANGUAGES) => {
  switch (lang) {
    case LANGUAGES.FR:
      return (fr as unknown as any)[key];
    default:
      return (en as unknown as any)[key];
  }
};

export var getLanguageByDefault = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { language } = useSelector((state: IRootState) => state.setting);
  switch (language) {
    case LANGUAGES.FR:
      return fr;
    default:
      return en;
  }
};

export const logger = (type: AppState, ...params: any) => {
  switch (type) {
    case AppState.ERROR:
      process.env.NODE_ENV !== "production" && console.error(...params);
      break;
    default:
      process.env.NODE_ENV !== "production" && console.log(...params);
  }
};

export const InfoLogger = (...params: any) => logger(AppState.INFO, params);
export const ErrorLogger = (...params: any) => logger(AppState.ERROR, params);
