import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClassCard1 from "./ClassCard1";
import { ClassResponse } from "../services/api.response.service";
import {AppState, LANGUAGES} from "../services/app.service";
import Loader from "./Loader";
import Card from "./Card";
import Languages from "./Languages";
import NoData from "../pages/main/NoData";

interface Props {
  title: string;
  viewAllLink?: string;
  data: Array<ClassResponse>;
  state: AppState;
}

function ClassesCardHList({
  title,
  viewAllLink,
  data,
  state,
}: Props) {
  return (
    <Col lg={12} className="row m-0 p-0">
      <Col sm={12} className="d-flex justify-content-between mt-1">
        <div className="header-title">
          <h4 className="card-title">
            <Languages langKey={title} />
          </h4>
        </div>
        {viewAllLink && state === AppState.LOADED && (
          <div className="card-header-toolbar d-flex align-items-center">
            <Link to={viewAllLink}>
              <Languages langKey={"viewAll"} />
            </Link>
          </div>
        )}
      </Col>
      <Col sm={12} className="row m-0 p-0">
        {state === AppState.LOADING ? (
          <Loader noCard imageLoader />
        ) : data.length > 0 ? (
          data.map((item) => (
            <Col key={item.id} sm={6} md={3} lg={3} xl={2} className="mt-1">
              <ClassCard1 data={item} />
            </Col>
          ))
        ) : (
          <NoData title={"noData"}/>
        )}
      </Col>
    </Col>
  );
}

export default ClassesCardHList;
