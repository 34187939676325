import { AppState } from "../../services/app.service";
import {AxiosRequestConfig} from "axios";

export const types = {
  PAGE_STATE: "PAGE_STATE",
  PAGE_MESSAGE: "PAGE_MESSAGE",

  PAGE_ARRAY_DATA: "PAGE_ARRAY_DATA",
  PAGE_ARRAY_DATA_SUCCESS: "PAGE_ARRAY_DATA_SUCCESS",

  PAGE_OBJECT_DATA: "PAGE_OBJECT_DATA",
  POST_PAGE_DATA: "POST_PAGE_DATA",
  PAGE_OBJECT_DATA_SUCCESS: "PAGE_OBJECT_DATA_SUCCESS",

  PAGINATED_PAGE_DATA: "PAGINATED_PAGE_DATA",
  PAGINATED_PAGE_DATA_SUCCESS: "PAGINATED_PAGE_DATA_SUCCESS",
  PAGINATED_PAGE_OBJECT_SUCCESS: "PAGINATED_PAGE_OBJECT_SUCCESS",
  PAGINATED_PAGE_HAS_MORE: "PAGINATED_PAGE_HAS_MORE",
  PAGINATED_PAGE_CURRENT_PAGE_NUMBER: "PAGINATED_PAGE_CURRENT_PAGE_NUMBER",

  DATA_PERSIST: "DATA_PERSIST",
  DATA_PERSIST_WITH_KEY: "DATA_PERSIST_WITH_KEY",
  POST_PAGINATED_PAGE_DATA: "POST_PAGINATED_PAGE_DATA",
};

interface BasePageStatePayload {
  stateKey: string;
}

export interface PageDataPayload extends BasePageStatePayload {
  apiEndpoint: string;
  refresh?: boolean;
  storeKey?: string;
  clear?: boolean;
  config?: AxiosRequestConfig;
}

export interface PaginatedPageDataPayload extends PageDataPayload {
  previousPage: number;
  obj?: boolean;
  params?: Object;
}

export interface PostPageDataPayload<T = any> extends PageDataPayload {
  req: T;
  append?: "start" | "end";
  removeAt?: number;
}

export interface PostPaginatedPageDataPayload<T = any>
  extends PaginatedPageDataPayload {
  req: T;
}

interface PageSuccessPayload<T = any> extends BasePageStatePayload {
  data: T;
  clear?: boolean;
  id?: string;
  append?: "start" | "end";
  removeAt?: number;
}

const Main = {
  togglePageState: (payload: PageSuccessPayload<AppState>) => ({
    type: types.PAGE_STATE,
    payload,
  }),
  setPageMessage: (payload: PageSuccessPayload<string>) => ({
    type: types.PAGE_MESSAGE,
    payload,
  }),

  getPageArrayData: (payload: PageDataPayload) => ({
    type: types.PAGE_ARRAY_DATA,
    payload,
  }),
  postPageArrayDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGE_ARRAY_DATA_SUCCESS,
    payload,
  }),

  getPageObjectData: (payload: PageDataPayload) => ({
    type: types.PAGE_OBJECT_DATA,
    payload,
  }),
  postPageData: (payload: PostPageDataPayload) => ({
    type: types.POST_PAGE_DATA,
    payload,
  }),
  postPageObjectDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGE_OBJECT_DATA_SUCCESS,
    payload,
  }),

  getPaginatedPageData: (payload: PaginatedPageDataPayload) => ({
    type: types.PAGINATED_PAGE_DATA,
    payload,
  }),
  postPaginatedPageDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGINATED_PAGE_DATA_SUCCESS,
    payload,
  }),
  postPaginatedPageObjectSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGINATED_PAGE_OBJECT_SUCCESS,
    payload,
  }),
  togglePaginatedPageHasMore: (payload: PageSuccessPayload<boolean>) => ({
    type: types.PAGINATED_PAGE_HAS_MORE,
    payload,
  }),
  setPaginatedPageCurrentPageNumber: (payload: PageSuccessPayload<number>) => ({
    type: types.PAGINATED_PAGE_CURRENT_PAGE_NUMBER,
    payload,
  }),

  postPageDataPersist: (payload: PageSuccessPayload) => ({
    type: types.DATA_PERSIST,
    payload,
  }),
  postPageDataPersistWithKey: (payload: PageSuccessPayload) => ({
    type: types.DATA_PERSIST_WITH_KEY,
    payload,
  }),
  postPaginatedPageData: (payload: PostPaginatedPageDataPayload) => ({
    type: types.POST_PAGINATED_PAGE_DATA,
    payload,
  }),
};

export default Main;
