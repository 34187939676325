import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states";
import {StateKeys} from "../../redux/states/main.state";
import {AppPageScrollDownLoadMore, AppState,} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import {InterestResponse} from "../../services/api.response.service";
import {RouteEndpoints} from "../../services/route.service";
import {Link} from "react-router-dom";
import NoData from "./NoData";

function Interests() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const { data, pageState, hasMore, currentPage } = state[StateKeys.INTERESTS];

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTERESTS,
          apiEndpoint: ApiEndpoint.ALL_INTERESTS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const loadMore = () => {
    if (
      // @ts-ignore
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      // @ts-ignore
      hasMore &&
      // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTERESTS,
          apiEndpoint: ApiEndpoint.ALL_INTERESTS,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        <Col sm={12} className="row m-0 p-0">
          {(pageState === AppState.LOADED ||
            pageState === AppState.LOADING_MORE) && (
            <>
              {data.length > 0 ? (
                (data as Array<InterestResponse>).map(
                  ({ id, name, total_classes }, i) => (
                    <Col key={i} sm={3} className="mt-3">
                      <Link
                        to={RouteEndpoints.INTEREST_CLASSES(`${id}`)}
                        className="text-decoration-none"
                      >
                        <Card>
                          <Card.Body>
                            <h5>{name}</h5>
                            <small
                              className="text-secondary d-flex align-items-center gap-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Total Available Classes"
                            >
                              <i
                                className="las la-university"
                                style={{ fontSize: 18 }}
                              />
                              <span className="text-primary">
                                {total_classes}
                              </span>
                            </small>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="No Interests available" />
              )}
            </>
          )}
        </Col>
        <Col md={12}>
          {(pageState === AppState.LOADING_MORE ||
            pageState === AppState.LOADING) && <Loader noCard imageLoader />}
        </Col>
      </Row>
    </div>
  );
}

export default Interests;
