import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../services/route.service";
import Languages from "./Languages";

const ClassCard2 = ({ recent }: any) => {
  return (
    <>
      <Card className="card-block card-stretch card-height product">
        <Card.Body className="p-1 d-flex justify-content-between flex-column">
          <Link
            to={RouteEndpoints.COURSE_CONTENT(String(recent.id))}
            className="d-flex justify-content-between flex-column text-decoration-none"
          >
            <div className="p-1 product-description mt-3 ">
              <h6 className="mb-1 fs-7">
                {recent.title?.length > 21
                  ? recent.title?.slice(0, 21).concat("..")
                  : recent.title}
              </h6>

              <small className="mb-0 text-body fs-7">
                {recent.description.length > 38
                  ? recent.description.slice(0, 38).concat("...")
                  : recent.description}
              </small>
            </div>
          </Link>
          <div className="d-flex justify-content-between flex-column">
            <hr className="hr-horizontal" />
            <div className="d-flex justify-content-between">
              <div>
                <small>
                  <i className="text-warning fa fa-star" />
                  <small className="ms-1">4.3</small>
                </small>
              </div>
              <small>
                {recent.category && (
                  <Link
                    to={RouteEndpoints.COURSE_CONTENT(
                      String(recent.category.id)
                    )}
                    className="text-decoration-none"
                  >
                    <Languages langKey="recentTopics" />
                  </Link>
                )}
              </small>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClassCard2;
